import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { apiGuard } from './common/guards/api.guard';
import { MainLayoutComponent } from './core/layouts/main-layout/main-layout.component';
import { HeaderLayoutComponent } from './core/layouts/header-layout/header-layout.component';
import { LoginComponent } from '@components/login/login.component';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { TickerComponent } from '@views/ticker/ticker.component';
import { HelpTicketCenterComponent } from '@views/help-ticket-center/help-ticket-center.component';
import { InternalTicketCenterComponent } from '@views/internal-ticket-center/internal-ticket-center.component';
import { PartnersTicketCenterComponent } from '@views/partners-ticket-center/partners-ticket-center.component';
import { PersonalWorklogComponent } from '@views/personal-worklog/personal-worklog.component';
import { ServiceDeskLogComponent } from '@views/service-desk-log/service-desk-log.component';
import { ShortcutCenterComponent } from '@views/shortcut-center/shortcut-center.component';
import { SubjectsComponent } from '@views/subjects/subjects.component';
import { RelevanceLevelComponent } from '@views/relevance-level/relevance-level.component';
import { StatusListComponent } from '@views/status-list/status-list.component';
import { DepartmentComponent } from '@views/department/department.component';
import { WebsiteSetupComponent } from '@views/website-setup/website-setup.component';
import { UserComponent } from '@views/user/user.component';
import { ProfileComponent } from '@views/profile/profile.component';
import { ConfigurationComponent } from '@components/configuration/configuration.component';
import { TwoFactorComponent } from '@components/two-factor/two-factor.component';
import { DirectTicketCenterComponent } from '@views/direct-ticket-center/direct-ticket-center.component';
import { BrandSetUpComponent } from '@views/brand-set-up/brand-set-up.component';
import { NotificationComponent } from '@views/notification/notification.component';
import { LogsComponent } from '@views/logs/logs.component';


const routes: Routes = [
  {
    path: '',
    component: HeaderLayoutComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
    ]
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [apiGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    //   { path: 'dashboard', component: DashboardComponent },
      { path: 'dashboard/tickets', component: TickerComponent },
      { path: 'dashboard/ticketCenter', component: HelpTicketCenterComponent },
      { path: 'dashboard/inTicketCenter', component: InternalTicketCenterComponent },
      { path: 'dashboard/pTicketCenter', component: PartnersTicketCenterComponent },
      { path: 'dashboard/personalWorkLog', component: PersonalWorklogComponent },
      { path: 'dashboard/serviceDeskLog', component: ServiceDeskLogComponent },
      { path: 'dashboard/shortcutCenter', component: ShortcutCenterComponent },
      { path: 'dashboard/subjects', component: SubjectsComponent },
      { path: 'dashboard/relevanceLevels', component: RelevanceLevelComponent },
      { path: 'dashboard/statusList', component: StatusListComponent },
      { path: 'dashboard/departments', component: DepartmentComponent },
      { path: 'dashboard/websiteSetup', component: WebsiteSetupComponent },
      { path: 'dashboard/brandSetup', component: BrandSetUpComponent },
      { path: 'dashboard/users', component: UserComponent },
      { path: 'dashboard/profiles', component: ProfileComponent },
      { path: 'dashboard/notificationCenter', component: NotificationComponent },
      { path: 'dashboard/DirectTicketCenter', component: DirectTicketCenterComponent },
      { path: 'dashboard/logs', component: LogsComponent },
      { path: 'dashboard/twofactor', component: TwoFactorComponent },
      { path: 'configuration', component: ConfigurationComponent }
    ]
  },
  { path: '404', component: NotFoundComponent },
  { path: 'auth/signin', redirectTo: 'login', pathMatch: 'full'},
  { path: '**', redirectTo: '/404', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
