<div class="row g-0 mb-4">
    <!-- WEBSITES TAB CARD AND ADD AGENT SITE PROFILE CARD -->
    <div *ngIf="viewConfigProfile != false" class="col-md-6 offset-md-3 mr-5 edit-sublevel">
      <p-card header="{{'WebsiteSetup.Add sites to profile' | translate}} - {{_model.profile}}" class="card card-custom">
        <button ngbTooltip="{{'WebsiteSetup.CloseAndSave' | translate}}" class="btn btn-icon custom-card-buttom" (click)="ConfigProfile(0);">
          <i class="feather icon-x "></i>
        </button>
        <p-dropdown
            styleClass="d-flex w-100"
            placeholder="{{'WebsiteSetup.Select a profile' | translate}}:"
            [options]="listAllWebsiteProfiles"
            [filter]="true"
            (onChange)="SelectProfile()"
            filterBy="name"
            [autoDisplayFirst]="false"
            optionLabel="name"
            optionValue="idSiteProfile"
            [(ngModel)]="UseProfile.idSiteProfile">
            <ng-template let-option pTemplate="item">
                <span [class]="'customer-badge status-' + option.idSiteProfile">{{option.name}}</span>
            </ng-template>
        </p-dropdown>
        <p-dropdown
            styleClass="d-flex w-100 mt-2"
            placeholder="{{'WebsiteSetup.Select sites' | translate}}: "
            [options]="_listWebSites"
            [filter]="true"
            (onChange)="SelectProfile()"
            [(ngModel)]="_model.site"
            (onChange)="GetIdSiteToProfile()"
            filterBy="description"
            [autoDisplayFirst]="false"
            optionLabel="description"
            optionValue="">
            <ng-template let-option pTemplate="item">
                <span [class]="'customer-badge status-' + option.site">{{option.description}}</span>
            </ng-template>
        </p-dropdown>

        <ul class="list-group text-center">

          <li *ngFor="let item of UseProfile.siteList; index as i" class="list-group-item"
            style="padding: 0px; margin-top: 7px; border:0px;">
            {{item.description}}

            <button ngbTooltip="{{'Delete' | translate}}" class="btn btn-icon" (click)="DeleteSiteToProfile(item);">
              <i class="feather icon-x "></i>
            </button>
          </li>
        </ul>
        <div class="form-group text-center">
          <button type="button" class="btn btn-primary mt-3" (click)="SaveProfileConfiguration()">{{'Save' | translate}}</button>
        </div>
      </p-card>
    </div>

    <!-- WEBSITES MODAL SORT LIST AND AGENT SITES MODAL SORT LIST -->
    <div class="modal fade" id="websiteTabModal" tabindex="-1" aria-labelledby="websiteTabModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content" style="background-color: transparent;">
            <div *ngIf="!_listSubjectFields || !_listSubjectFields.length">
                <p>{{'WebsiteSetup.PLayerListEmpty' | translate}}</p>
            </div>
            <div *ngIf="_listSubjectFields && _listSubjectFields.length">
                <p-card header="{{'WebsiteSetup.WebsiteFields' | translate}} - {{SubSelected}}" class="card card-custom mat-form-field">
                    <button ngbTooltip="{{'WebsiteSetup.CloseAndSave' | translate}}" class="btn btn-icon custom-card-buttom" (click)="CloseTableFieldsAnsSaveOrder();">
                      <i class="feather icon-x "></i>
                    </button>
                    <div class="row d-flex align-items-center p-3">
                      <div class="col-4 col-sm-3">
                        <label class="container-custom">
                            <input type="checkbox" (change)="UpdateAllItemWebsiteFieldSetup($event.target)">
                            <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="col-8 col-sm-9">
                        <span class="sub-desc">{{'SelectALL' | translate}}</span>
                      </div>
                    </div>
                    <div cdkDropList class="mt-0 example-list" (cdkDropListDropped)="dropFields($event)">
                      <div class="row g-0 d-flex align-items-center p-3 example-box" *ngFor="let item of _listSubjectFields" cdkDrag>
                        <div class="example-custom-placeholder" *cdkDragPlaceholder>
                          <div class="row g-0 d-flex align-items-center p-3">
                            <div class="col-4 col-sm-3">
                              <label class="container-custom">
                                  <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemWebsiteFieldSetup($event.target,item)">
                                  <span class="checkmark"></span>
                              </label>
                            </div>
                            <div class="col-8 col-sm-9">
                              <span class="sub-desc">{{item.name | translate }}</span>
                            </div>
                          </div>
                        </div>

                        <div class="col-2 col-sm-1">
                          <label class="container-custom">
                              <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemWebsiteFieldSetup($event.target,item)">
                              <span class="checkmark"></span>
                          </label>
                        </div>
                        <div class="col-8 col-sm-9">
                          <span class="sub-desc">{{item.name | translate }}</span>
                        </div>
                      </div>
                    </div>
                  </p-card>
            </div>

        </div>
      </div>
    </div>

    <!-- CTS ADMIN MODAL SORT LIST -->
    <div class="modal fade" id="ctsAdminModal" tabindex="-1" aria-labelledby="ctsAdminModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content" style="background-color: transparent;">
          <p-card header="{{'WebsiteSetup.CTS Admin fields' | translate}} - {{SubSelected}}" class="card card-custom">
            <div class="latest-update-box">
              <button ngbTooltip="{{'WebsiteSetup.CloseAndSave' | translate}}" class="btn btn-icon custom-card-buttom" (click)="CloseCTSAdminFieldsAnsSaveOrder();">
                <i class="feather icon-x "></i>
              </button>

              <div class="row d-flex align-items-center p-3">
                <div class="col-4 col-sm-3">
                  <label class="container-custom">
                      <input type="checkbox" (change)="UpdateAllItemCtsAdminFieldSetup($event.target)">
                      <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-8 col-sm-9">
                  <span class="sub-desc">{{'SelectALL' | translate}}</span>
                </div>
              </div>
              <div cdkDropList class="mt-0 example-list" (cdkDropListDropped)="dropFields($event)">
                <div class="row g-0 d-flex align-items-center p-3 example-box" *ngFor="let item of _listSubjectFields" cdkDrag>
                  <div class="example-custom-placeholder" *cdkDragPlaceholder>
                    <div class="row g-0 d-flex align-items-center p-3">
                      <div class="col-4 col-sm-3">
                        <label class="container-custom">
                            <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemCTSAdminWebsiteFieldSetup($event.target,item)">
                            <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="col-8 col-sm-9">
                        <span class="sub-desc">{{item.name | translate }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 col-sm-1">
                    <label class="container-custom">
                        <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemWebsiteFieldSetup($event.target,item)">
                        <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-8 col-sm-9">
                    <span class="sub-desc">{{item.name | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
        </div>
      </div>
    </div>

    <!-- EPOS MODAL SORT LIST -->
    <div class="modal fade" id="eposModal" tabindex="-1" aria-labelledby="eposModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content" style="background-color: transparent;">
          <p-card header="{{'WebsiteSetup.EPOS fields' | translate}} - {{SubSelected}}" class="card card-custom">
            <div class="latest-update-box">
              <button ngbTooltip="{{'WebsiteSetup.CloseAndSave' | translate}}" class="btn btn-icon custom-card-buttom" (click)="CloseEposFieldsAnsSaveOrder();">
                <i class="feather icon-x "></i>
              </button>

              <div class="row d-flex align-items-center p-3">
                <div class="col-4 col-sm-3">
                  <label class="container-custom">
                      <input type="checkbox" (change)="UpdateAllItemEposFieldSetup($event.target)">
                      <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-8 col-sm-9">
                  <span class="sub-desc">{{'SelectALL' | translate}}</span>
                </div>
              </div>
              <div cdkDropList class="mt-0 example-list" (cdkDropListDropped)="dropFields($event)">
                <div class="row g-0 d-flex align-items-center p-3 example-box" *ngFor="let item of _listSubjectFields" cdkDrag>
                  <div class="example-custom-placeholder" *cdkDragPlaceholder>
                    <div class="row g-0 d-flex align-items-center p-3">
                      <div class="col-4 col-sm-3">
                        <label class="container-custom">
                            <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemEposWebsiteFieldSetup($event.target,item)">
                            <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="col-8 col-sm-9">
                        <span class="sub-desc">{{item.name | translate }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 col-sm-1">
                    <label class="container-custom">
                        <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemEposWebsiteFieldSetup($event.target,item)">
                        <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-8 col-sm-9">
                    <span class="sub-desc">{{item.name | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
        </div>
      </div>
    </div>

    <!-- EPOS ADMIN MODAL SORT LIST -->
    <div class="modal fade" id="eposAdminModal" tabindex="-1" aria-labelledby="eposAdminModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content" style="background-color: transparent;">
          <p-card header="{{'WebsiteSetup.EPOS Admin fields' | translate}} - {{SubSelected}}" class="card card-custom">
            <div class="latest-update-box">
              <button ngbTooltip="{{'WebsiteSetup.CloseAndSave' | translate}}" class="btn btn-icon custom-card-buttom" (click)="CloseEposAdminFieldsAnsSaveOrder();">
                <i class="feather icon-x "></i>
              </button>

              <div class="row d-flex align-items-center p-3">
                <div class="col-4 col-sm-3">
                  <label class="container-custom">
                      <input type="checkbox" (change)="UpdateAllItemEposAdminFieldSetup($event.target)">
                      <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-8 col-sm-9">
                  <span class="sub-desc">{{'SelectALL' | translate}}</span>
                </div>
              </div>
              <div cdkDropList class="mt-0 example-list" (cdkDropListDropped)="dropFields($event)">
                <div class="row g-0 d-flex align-items-center p-3 example-box" *ngFor="let item of _listSubjectFields" cdkDrag>
                  <div class="example-custom-placeholder" *cdkDragPlaceholder>
                    <div class="row g-0 d-flex align-items-center p-3">
                      <div class="col-4 col-sm-3">
                        <label class="container-custom">
                            <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemEposAdminWebsiteFieldSetup($event.target,item)">
                            <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="col-8 col-sm-9">
                        <span class="sub-desc">{{item.name | translate }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 col-sm-1">
                    <label class="container-custom">
                        <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemEposAdminWebsiteFieldSetup($event.target,item)">
                        <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-8 col-sm-9">
                    <span class="sub-desc">{{item.name | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
        </div>
      </div>
    </div>

    <!-- ALL SITES MODAL SORT LIST -->
    <div class="modal fade" id="allSitesModal" tabindex="-1" aria-labelledby="allSitesModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content" style="background-color: transparent;">
          <p-card header="{{'WebsiteSetup.WebsiteFields' | translate}} - {{SubSelected}}" class="card card-custom">
            <div class="latest-update-box">
              <button ngbTooltip="{{'Close' | translate}}" class="btn btn-icon custom-card-buttom" (click)="closeWindow();">
                <i class="feather icon-x "></i>
              </button>
              <button (click)="SaveChangeFieldsToAllSites()" style="position: absolute; top: 14px; right: 50px;" ngbTooltip="{{'WebsiteSetup.CloseAndSave' | translate}}" class="btn btn-icon ">
                <i class="feather icon-save "></i>
              </button>

              <div class="row d-flex align-items-center p-3">
                <div class="col-4 col-sm-3">
                  <label class="container-custom">
                      <input type="checkbox" (change)="SelectAllItemFieldsForAllSites($event.target)">
                      <span class="checkmark"></span>
                  </label>
                </div>
                <div class="col-8 col-sm-9">
                  <span class="sub-desc">{{'SelectALL' | translate}}</span>
                </div>
              </div>
              <div cdkDropList class="mt-0 example-list" (cdkDropListDropped)="dropFieldAllSite($event)">
                <div class="row g-0 d-flex align-items-center p-3 example-box" *ngFor="let item of listFields" cdkDrag>
                  <div class="example-custom-placeholder" *cdkDragPlaceholder>
                    <div class="row g-0 d-flex align-items-center p-3">
                      <div class="col-4 col-sm-3">
                        <label class="container-custom">
                            <input type="checkbox" [checked]="item.isActive" (change)="SelectItemFieldsForAllSites($event.target,item.idSubjectField)">
                            <span class="checkmark"></span>
                        </label>
                      </div>
                      <div class="col-8 col-sm-9">
                        <span class="sub-desc">{{item.name | translate }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-2 col-sm-1">
                    <label class="container-custom">
                        <input type="checkbox" [checked]="item.isActive" (change)="SelectItemFieldsForAllSites($event.target,item.idSubjectField)">
                        <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-8 col-sm-9">
                    <span class="sub-desc">{{item.name | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </p-card>
        </div>
      </div>
    </div>
  </div>


<p-card class="card" header="" subheader="" [style]="{ width: 'auto', padding: '0' }">
      <p-tabView [scrollable]="true" *ngIf="clicks == 0" (onChange)="tabSelected($event.index)" id="website-setup">
        <!-- WEBSITES TAB PANEL-->
        <p-tabPanel header="{{'WebsiteSetup.WEBSITES' | translate}}">
          <div class="card card-custom" [ngStyle]="{'width': !Media.IsMobile ? '33rem' : '100%', 'margin-bottom': '2em', 'margin': 'auto'}">
              <div class="row d-flex align-items-center p-3">
                <ng-container *ngIf="_userInfoCTS.accessAllSites">
                    <div class="col-8">
                        <p-dropdown
                            styleClass="d-flex w-100"
                            [options]="_listWebSites"
                            (onChange)="onChange($event, 0)"
                            optionLabel="description"
                            [filter]="true" filterBy="description" [showClear]="true"
                            optionValue="idSiteTag">
                        </p-dropdown>
                    </div>

                    <div class="col-4">
                        <button ngbTooltip="{{ 'Sort the subjects' | translate }}" type="button" (click)="changeViewSites();" class="btn btn-icon w-50">
                            <i class="feather icon-shuffle "></i>
                        </button>
                        <button ngbTooltip="{{ 'Config. sites by profile' | translate }}" type="button" (click)="ConfigProfile(1);" class="btn btn-icon w-50">
                            <i class="feather icon-settings "></i>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="!_userInfoCTS.accessAllSites">
                    <p-dropdown
                        styleClass="d-flex w-100"
                        [options]="_listsUserSites"
                        (onChange)="onChange($event, 0)"
                        optionLabel="name"
                        [filter]="true" filterBy="name" [showClear]="true"
                        optionValue="name">
                    </p-dropdown>
                </ng-container>
                <p class="text-primary my-2 text-center text-small text-muted">* {{'WebsiteSetup.Changes are saved automatically' | translate}}</p>
                <ng-container class="mt-2" *ngIf="!viewSortPlayers">
                    <div class="col-2 col-sm-1">
                        <label class="container-custom">
                            <input type="checkbox" (change)="UpdateAllItemWebsiteSetup($event.target)" [checked]="_checked">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-10 col-sm-11">
                        <span class="sub-desc">{{'SelectALL' | translate}}</span>
                    </div>
                </ng-container>
              </div>

              <div class="row g-0 border-top d-flex align-items-center" [ngClass]="{'p-3': !viewSortPlayers, 'p-0': viewSortPlayers}" *ngFor="let item of _listWebSitesTEMP; index as i">
                  <ng-container *ngIf="!viewSortPlayers" >
                      <div class="col-2 col-sm-1">
                          <label class="container-custom">
                              <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemWebsiteSetup($event.target,item.idSiteTag,item.idSubject)">
                              <span class="checkmark"></span>
                          </label>
                      </div>
                      <div class="col-8 col-sm-9">
                          <span class="sub-desc">{{item.subjectName | translate }} <br> <span>{{item.descriptionSubject | translate}}
                          </span> </span>
                      </div>
                      <div class="col-2 col-sm-2">
                          <button class="btn btn-icon" (click)="GetFieldsBySite(item, true);" type="button">
                              <i class="feather icon-plus "></i>
                          </button>
                      </div>
                  </ng-container>
                  <ng-container *ngIf="viewSortPlayers">
                      <div cdkDropList class="movie-list row g-0 d-flex align-items-center example-list" (cdkDropListDropped)="dropWebsite($event)">
                          <div class="col-12 p-3 example-box" *ngFor="let item of _listWebSitesTEMP" cdkDrag>
                            {{item.subjectName | translate }}
                          </div>
                      </div>
                  </ng-container>
              </div>
          </div>
        </p-tabPanel>

        <!-- AGENT SITES TAB PANEL  -->
        <p-tabPanel header="{{'WebsiteSetup.AGENT SITES' | translate}}">
          <div class="card card-custom" [ngStyle]="{'width': !Media.IsMobile ? '33rem' : '100%', 'margin-bottom': '2em', 'margin': 'auto'}">
            <div class="row d-flex align-items-center p-3">
              <ng-container *ngIf="_userInfoCTS.accessAllSites">
                  <div class="col-8">
                      <p-dropdown
                          styleClass="d-flex w-100"
                          [options]="_listAgentWebSites"
                          (onChange)="onChangeAgentWebsite($event, 0)"
                          optionLabel="description"
                          [filter]="true" filterBy="description" [showClear]="true"
                          optionValue="idSiteTag">
                      </p-dropdown>
                  </div>

                  <div class="col-4">
                      <button ngbTooltip="{{ 'Sort the subjects' | translate }}" type="button" (click)="changeViewAgents();" class="btn btn-icon w-50">
                          <i class="feather icon-shuffle "></i>
                      </button>
                      <button ngbTooltip="{{ 'Config. sites by profile' | translate }}" type="button" (click)="ConfigProfile(2);" class="btn btn-icon w-50">
                          <i class="feather icon-settings "></i>
                      </button>
                  </div>
              </ng-container>
              <ng-container *ngIf="!_userInfoCTS.accessAllSites">
                  <p-dropdown
                      styleClass="d-flex w-100"
                      [options]="_listsUserAgentSites"
                      (onChange)="onChangeAgentWebsite($event, 0)"
                      optionLabel="name"
                      [filter]="true" filterBy="name" [showClear]="true"
                      optionValue="name">
                  </p-dropdown>
              </ng-container>
              <p class="text-primary my-2 text-center text-small text-muted">* {{'WebsiteSetup.Changes are saved automatically' | translate}}</p>
              <ng-container class="mt-2" *ngIf="!viewSortAgents">
                  <div class="col-2 col-sm-1">
                      <label class="container-custom">
                          <input type="checkbox" (change)="UpdateAllItemWebsiteSetup($event.target)" [checked]="_checked">
                          <span class="checkmark"></span>
                      </label>
                  </div>
                  <div class="col-10 col-sm-11">
                      <span class="sub-desc">{{'SelectALL' | translate}}</span>
                  </div>
              </ng-container>
            </div>

            <div class="row g-0 border-top d-flex align-items-center" [ngClass]="{'p-3': !viewSortAgents, 'p-0': viewSortAgents}" *ngFor="let item of _listAgentWebSitesTEMP; index as i">
                <ng-container *ngIf="!viewSortAgents" >
                    <div class="col-2 col-sm-1">
                        <label class="container-custom">
                            <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemAgentWebsiteSetup($event.target,item.idSiteTag,item.idSubject)">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-8 col-sm-9">
                        <span class="sub-desc">{{item.subjectName | translate }} <br> <span>{{item.descriptionSubject | translate}}
                        </span> </span>
                    </div>
                    <div class="col-2 col-sm-2">
                        <button class="btn btn-icon" (click)="GetFieldsBySite(item, false);" type="button">
                            <i class="feather icon-plus "></i>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="viewSortAgents">
                    <div cdkDropList class="movie-list row g-0 d-flex align-items-center example-list" (cdkDropListDropped)="drop($event)">
                        <div class="col-12 p-3 example-box" *ngFor="let item of _listAgentWebSitesTEMP" cdkDrag>
                          {{item.subjectName | translate }}
                        </div>
                    </div>
                </ng-container>
            </div>
          </div>
        </p-tabPanel>

        <!-- CTS ADMIN TAB PANEL  -->
        <p-tabPanel header="CTS ADMIN">
          <div class="card card-custom" [ngStyle]="{'width': !Media.IsMobile ? '33rem' : '100%', 'margin-bottom': '2em', 'margin': 'auto'}">
            <div class="row d-flex align-items-center p-3">
              <ng-container *ngIf="_userInfoCTS.accessAllSites">
                  <div class="col-8">
                      <p-dropdown
                          styleClass="d-flex w-100"
                          [options]="_listCTSAdminWebSites"
                          (onChange)="onChangeCTSAdmin($event, 0)"
                          optionLabel="description"
                          [filter]="true" filterBy="description" [showClear]="true"
                          optionValue="idSiteTag">
                      </p-dropdown>
                  </div>

                  <div class="col-4">
                      <button ngbTooltip="{{ 'Sort the subjects' | translate }}" type="button" (click)="OrderCTSAdmin();" class="btn btn-icon w-50">
                          <i class="feather icon-shuffle "></i>
                      </button>
                      <button ngbTooltip="{{ 'Config. sites by profile' | translate }}" type="button" (click)="ConfigProfile(3);" class="btn btn-icon w-50">
                          <i class="feather icon-settings "></i>
                      </button>
                  </div>
              </ng-container>
              <ng-container *ngIf="!_userInfoCTS.accessAllSites">
                  <p-dropdown
                      styleClass="d-flex w-100"
                      [options]="_listsUserCTSAdminSites"
                      (onChange)="onChangeCTSAdmin($event, 0)"
                      optionLabel="name"
                      [filter]="true" filterBy="name" [showClear]="true"
                      optionValue="name">
                  </p-dropdown>
              </ng-container>
              <p class="text-primary my-2 text-center text-small text-muted">* {{'WebsiteSetup.Changes are saved automatically' | translate}}</p>
              <ng-container class="mt-2" *ngIf="!viewSortCTSAdmin">
                  <div class="col-2 col-sm-1">
                      <label class="container-custom">
                          <input type="checkbox" (change)="UpdateAllItemCtsAdminWebsiteSetup($event.target)" [checked]="_checked">
                          <span class="checkmark"></span>
                      </label>
                  </div>
                  <div class="col-10 col-sm-11">
                      <span class="sub-desc">{{'SelectALL' | translate}}</span>
                  </div>
              </ng-container>
            </div>

            <div class="row g-0 border-top d-flex align-items-center" [ngClass]="{'p-3': !viewSortCTSAdmin, 'p-0': viewSortCTSAdmin}" *ngFor="let item of _listCTSAdminWebSitesTEMP; index as i">
                <ng-container *ngIf="!viewSortCTSAdmin" >
                    <div class="col-2 col-sm-1">
                        <label class="container-custom">
                            <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemCtsAdminWebsiteSetup($event.target,item.idSiteTag,item.idSubject)">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-8 col-sm-9">
                        <span class="sub-desc">{{item.subjectName | translate }} <br> <span>{{item.descriptionSubject | translate}}
                        </span> </span>
                    </div>
                    <div class="col-2 col-sm-2">
                        <button class="btn btn-icon" (click)="GetFieldsByCTSAdmin(item);" type="button">
                            <i class="feather icon-plus "></i>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="viewSortCTSAdmin">
                    <div cdkDropList class="movie-list row g-0 d-flex align-items-center example-list" (cdkDropListDropped)="dropWebsiteCTSAdmin($event)">
                        <div class="col-12 p-3 example-box" *ngFor="let item of _listCTSAdminWebSitesTEMP" cdkDrag>
                          {{item.subjectName | translate }}
                        </div>
                    </div>
                </ng-container>
            </div>
          </div>
        </p-tabPanel>

        <!-- EPOS TAB PANEL  -->
        <p-tabPanel header="EPOS">
          <div class="card card-custom" [ngStyle]="{'width': !Media.IsMobile ? '33rem' : '100%', 'margin-bottom': '2em', 'margin': 'auto'}">
            <div class="row d-flex align-items-center p-3">
              <ng-container *ngIf="_userInfoCTS.accessAllSites">
                  <div class="col-8">
                      <p-dropdown
                          styleClass="d-flex w-100"
                          [options]="_listEposWebSites"
                          (onChange)="onChangeEpos($event, 0)"
                          optionLabel="description"
                          [filter]="true" filterBy="description" [showClear]="true"
                          optionValue="idSiteTag">
                      </p-dropdown>
                  </div>
                  <div class="col-4">
                      <button ngbTooltip="{{ 'Sort the subjects' | translate }}" type="button" (click)="OrderEpos();" class="btn btn-icon w-50">
                          <i class="feather icon-shuffle "></i>
                      </button>
                      <button ngbTooltip="{{ 'Config. sites by profile' | translate }}" type="button" (click)="ConfigProfile(4);" class="btn btn-icon w-50">
                          <i class="feather icon-settings "></i>
                      </button>
                  </div>
              </ng-container>
              <ng-container *ngIf="!_userInfoCTS.accessAllSites">
                  <p-dropdown
                      styleClass="d-flex w-100"
                      [options]="_listsUserEposSites"
                      (onChange)="onChangeEpos($event, 0)"
                      optionLabel="name"
                      [filter]="true" filterBy="name" [showClear]="true"
                      optionValue="name">
                  </p-dropdown>
              </ng-container>
              <p class="text-primary my-2 text-center text-small text-muted">* {{'WebsiteSetup.Changes are saved automatically' | translate}}</p>
              <ng-container class="mt-2" *ngIf="!viewSortEpos">
                  <div class="col-2 col-sm-1">
                      <label class="container-custom">
                          <input type="checkbox" (change)="UpdateAllItemEposWebsiteSetup($event.target)" [checked]="_checkedEpos">
                          <span class="checkmark"></span>
                      </label>
                  </div>
                  <div class="col-10 col-sm-11">
                      <span class="sub-desc">{{'SelectALL' | translate}}</span>
                  </div>
              </ng-container>
            </div>

            <div class="row g-0 border-top d-flex align-items-center" [ngClass]="{'p-3': !viewSortEpos, 'p-0': viewSortEpos}" *ngFor="let item of _listEposWebSitesTEMP; index as i">
                <ng-container *ngIf="!viewSortEpos" >
                    <div class="col-2 col-sm-1">
                        <label class="container-custom">
                            <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemEposWebsiteSetup($event.target,item.idSiteTag,item.idSubject)">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-8 col-sm-9">
                        <span class="sub-desc">{{item.subjectName | translate }} <br> <span>{{item.descriptionSubject | translate}}
                        </span> </span>
                    </div>
                    <div class="col-2 col-sm-2">
                        <button class="btn btn-icon" (click)="GetFieldsByEpos(item);" type="button">
                            <i class="feather icon-plus "></i>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="viewSortEpos">
                    <div cdkDropList class="movie-list row g-0 d-flex align-items-center example-list" (cdkDropListDropped)="dropWebsiteEpos($event)">
                        <div class="col-12 p-3 example-box" *ngFor="let item of _listEposWebSitesTEMP" cdkDrag>
                          {{item.subjectName | translate }}
                        </div>
                    </div>
                </ng-container>
            </div>
          </div>
        </p-tabPanel>

        <!-- EPOS ADMIN TAB PANEL  -->
        <p-tabPanel header="EPOS ADMIN">
          <div class="card card-custom" [ngStyle]="{'width': !Media.IsMobile ? '33rem' : '100%', 'margin-bottom': '2em', 'margin': 'auto'}">
            <div class="row d-flex align-items-center p-3">
              <ng-container *ngIf="_userInfoCTS.accessAllSites">
                  <div class="col-8">
                      <p-dropdown
                          styleClass="d-flex w-100"
                          [options]="_listEposAdminWebSites"
                          (onChange)="onChangeEposAdmin($event, 0)"
                          optionLabel="description"
                          [filter]="true" filterBy="description" [showClear]="true"
                          optionValue="idSiteTag">
                      </p-dropdown>
                  </div>
                  <div class="col-4">
                      <button ngbTooltip="{{ 'Sort the subjects' | translate }}" type="button" (click)="OrderEposAdmin();" class="btn btn-icon w-50">
                          <i class="feather icon-shuffle "></i>
                      </button>
                      <button ngbTooltip="{{ 'Config. sites by profile' | translate }}" type="button" (click)="ConfigProfile(5);" class="btn btn-icon w-50">
                          <i class="feather icon-settings "></i>
                      </button>
                  </div>
              </ng-container>
              <ng-container *ngIf="!_userInfoCTS.accessAllSites">
                  <p-dropdown
                      styleClass="d-flex w-100"
                      [options]="_listsUserEposSites"
                      (onChange)="onChangeEposAdmin($event, 0)"
                      optionLabel="name"
                      [filter]="true" filterBy="name" [showClear]="true"
                      optionValue="name">
                  </p-dropdown>
              </ng-container>
              <p class="text-primary my-2 text-center text-small text-muted">* {{'WebsiteSetup.Changes are saved automatically' | translate}}</p>
              <ng-container class="mt-2" *ngIf="!viewSortEposAdmin">
                  <div class="col-2 col-sm-1">
                      <label class="container-custom">
                          <input type="checkbox" (change)="UpdateAllItemEposAdminWebsiteSetup($event.target)" [checked]="_checkedEpos">
                          <span class="checkmark"></span>
                      </label>
                  </div>
                  <div class="col-10 col-sm-11">
                      <span class="sub-desc">{{'SelectALL' | translate}}</span>
                  </div>
              </ng-container>
            </div>

            <div class="row g-0 border-top d-flex align-items-center" [ngClass]="{'p-3': !viewSortEposAdmin, 'p-0': viewSortEposAdmin}" *ngFor="let item of _listEposAdminWebSitesTEMP; index as i">
                <ng-container *ngIf="!viewSortEposAdmin" >
                    <div class="col-2 col-sm-1">
                        <label class="container-custom">
                            <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemEposAdminWebsiteSetup($event.target,item.idSiteTag,item.idSubject)">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-8 col-sm-9">
                        <span class="sub-desc">{{item.subjectName | translate }} <br> <span>{{item.descriptionSubject | translate}}
                        </span> </span>
                    </div>
                    <div class="col-2 col-sm-2">
                        <button class="btn btn-icon" (click)="GetFieldsByEposAdmin(item);" type="button">
                            <i class="feather icon-plus "></i>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="viewSortEposAdmin">
                    <div cdkDropList class="movie-list row g-0 d-flex align-items-center example-list" (cdkDropListDropped)="dropWebsiteEposAdmin($event)">
                        <div class="col-12 p-3 example-box" *ngFor="let item of _listEposAdminWebSitesTEMP" cdkDrag>
                          {{item.subjectName | translate }}
                        </div>
                    </div>
                </ng-container>
            </div>
          </div>
        </p-tabPanel>

        <!-- ALL SITES TAB PANEL -->
        <!-- <p-tabPanel header="{{'WebsiteSetup.ALL SITES' | translate}}">
          <div class="card card-custom" [ngStyle]="{'width': !Media.IsMobile ? '33rem' : '100%', 'margin-bottom': '2em', 'margin': 'auto'}">
            <div class="row d-flex align-items-center p-3">
              <div class="col-8">
                <p-dropdown
                  styleClass="d-flex w-100"
                  [options]="AllSiteList"
                  [(ngModel)]="type"
                  placeholder="{{type | translate}}"
                  [autoDisplayFirst]="false"
                  (onChange)="onChangeUpdateAllSites($event)">
                  <ng-template pTemplate="item" let-item>
                    {{item.label | translate}}
                  </ng-template>
                </p-dropdown>
              </div>
              <div class="col-4 text-center">
                  <button ngbTooltip="Confirm changes" type="button" (click)="SaveChangeToAllSites();" class="btn btn-icon w-50">
                      <i class="feather icon-save"></i>
                  </button>
              </div>
              <p class="text-danger my-3 text-center small">* {{'WebsiteSetup.Changes need to be saved' | translate}}</p>
              <div class="col-2 col-sm-1">
                  <label class="container-custom">
                      <input type="checkbox" (change)="UpdateAllItemEposAdminWebsiteSetup($event.target)" [checked]="_checkedEpos">
                      <span class="checkmark"></span>
                  </label>
              </div>
              <div class="col-10 col-sm-11">
                  <span class="sub-desc">{{'SelectALL' | translate}}</span>
              </div>
            </div>

            <div class="row g-0 border-top d-flex align-items-center" [ngClass]="{'p-3': !viewSortEposAdmin, 'p-0': viewSortEposAdmin}" *ngFor="let item of _listEposAdminWebSitesTEMP; index as i">
                <ng-container *ngIf="!viewSortEposAdmin" >
                    <div class="col-2 col-sm-1">
                        <label class="container-custom">
                            <input type="checkbox" [checked]="item.isActive" (change)="UpdateItemEposAdminWebsiteSetup($event.target,item.idSiteTag,item.idSubject)">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-8 col-sm-9">
                        <span class="sub-desc">{{item.subjectName | translate }} <br> <span>{{item.descriptionSubject | translate}}
                        </span> </span>
                    </div>
                    <div class="col-2 col-sm-2">
                        <button class="btn btn-icon" (click)="EditAllFieldsAllSites(item, false);" type="button">
                            <i class="feather icon-plus "></i>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="viewSortEposAdmin">
                    <div cdkDropList class="movie-list row g-0 d-flex align-items-center example-list" (cdkDropListDropped)="dropWebsiteEposAdmin($event)">
                        <div class="col-12 p-3 example-box" *ngFor="let item of _listEposAdminWebSitesTEMP" cdkDrag>
                          {{item.subjectName | translate }}
                        </div>
                    </div>
                </ng-container>
            </div>
          </div>
        </p-tabPanel> -->

        <!-- DOMAIN SETUP TAB PANEL -->
        <p-tabPanel header="{{'WebsiteSetup.DOMAIN SETUP' | translate}}">
          <div class="card card-custom" [ngStyle]="{'width': !Media.IsMobile ? '33rem' : '100%', 'margin-bottom': '2em', 'margin': 'auto'}">
            <div class="row g-2 d-flex align-items-center p-3">
              <div class="col-12">
                <p-dropdown
                    styleClass="d-flex w-100 mb-4"
                    [options]="_listWebSites"
                    (onChange)="onChangeWebsiteTicket($event)"
                    optionLabel="description"
                    [filter]="true" filterBy="description" [showClear]="true"
                    optionValue="idSiteTag">
                </p-dropdown>

                <p-dropdown
                    styleClass="d-flex w-100"
                    [options]="_listDomains"
                    (onChange)="onChangeDomain()"
                    optionLabel="domain"
                    [(ngModel)]="idDomainSelected"
                    [filter]="true" filterBy="Domain" [showClear]="true"
                    optionValue="idConfigByDomain">
                </p-dropdown>
              <p class="text-primary my-2 text-center text-small text-muted">* {{'WebsiteSetup.Changes are saved automatically' | translate}}</p>
            </div>
            </div>
            <h3 class="ps-3 title">{{'Ticket Center' | translate}}</h3>

            <div class="row g-4 d-flex align-items-center p-3">
              <div class="col-2 col-sm-1">
                <label class="container-custom">
                    <input type="checkbox" [checked]="ticketCenterSelected == 1" (change)="onHelpTicketChange($event)" [disabled]="ticketCenterSelected == 1">
                    <span class="checkmark"></span>
                </label>
              </div>
              <div class="col-10 col-sm-11">
                  <span class="sub-desc">{{'Help Ticket Center' | translate}}</span>
              </div>

              <div class="col-2 col-sm-1">
                <label class="container-custom">
                    <input type="checkbox" [checked]="ticketCenterSelected == 2" (change)="onPartnersTicketChange($event)" [disabled]="ticketCenterSelected == 2">
                    <span class="checkmark"></span>
                </label>
              </div>
              <div class="col-10 col-sm-11">
                  <span class="sub-desc">{{'Partners Ticket Center' | translate}}</span>
              </div>

              <div class="col-2 col-sm-1">
                <label class="container-custom">
                    <input type="checkbox" [checked]="ticketCenterSelected == 3" (change)="onInternalTicketChange($event)" [disabled]="ticketCenterSelected == 3">
                    <span class="checkmark"></span>
                </label>
              </div>
              <div class="col-10 col-sm-11">
                  <span class="sub-desc">{{'Internal Ticket Center' | translate}}</span>
              </div>
            </div>

            <h3 class="ps-3 title">{{'Language' | translate}}</h3>
            <div class="row g-4 d-flex align-items-center p-3">
              <div class="col-2 col-sm-1">
                <label class="container-custom">
                    <input type="checkbox" [checked]="englishSelected" (change)="onChangeEnglishSelected($event)">
                    <span class="checkmark"></span>
                </label>
              </div>
              <div class="col-10 col-sm-11">
                  <span class="sub-desc">{{'English' | translate}}</span>
              </div>

              <div class="col-2 col-sm-1">
                <label class="container-custom">
                    <input type="checkbox" [checked]="spanishSelected" (change)="onChangeSpanishSelected($event)">
                    <span class="checkmark"></span>
                </label>
              </div>
              <div class="col-10 col-sm-11">
                  <span class="sub-desc">{{'Spanish' | translate}}</span>
              </div>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>

      <div *ngIf="clicks >= 5" class="row d-flex align-items-center">
        <div class="col-12 text-center">
          <img src="assets/images/empty-tracker-icon.png" alt="something happened">
          <span class="d-block my-3"> {{'GENERICS.UPDATED_ERROR' | translate}}
          </span>
          <a class="btn btn-danger" (click)="hardRefresh()">
            <i class="feather icon-refresh-cw"></i> {{ 'Refresh' | translate }}
          </a>
        </div>
      </div>
</p-card>
