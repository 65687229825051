import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Filters, InternalList } from '@methods/constants';
import { DeleteMethods, GetMethods, Methods, PostMethods, PutMethods } from '@methods/methods';
import { User } from '@models/auth-model';
import { FormType, ResponseData } from '@models/models';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { MediaResponse, MediaService } from '@services/media.service';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
declare const bootstrap: any;
@Component({
  selector: 'app-brand-subject-filter',
  templateUrl: './brand-subject-filter.component.html'
})
export class BrandSubjectFilterComponent implements OnInit {
  @Input() title: any;
  @Input() headerTitle: any;
  @Input() identity: number; // 1: relevance level // 2 status list // 3 department
  @Input() payloadData: any;
  @Input() isErrorActivated: boolean;
  @Input() modalTitleNewText: string;
  @Input() modalTitleEditText: string;
  @Input() newPostEvent: number;
  @Input() editPostEvent: number;
  @Input() deletePostEvent: number;
  @Input() selectAllCheckbox: number;
  @Input() addNewPostEvent: number;
  @Input() checked: boolean;
  @Input() filterData: any;
  @Input() allSiteTags: any;
  @Input() departmentList: any;
  @Input() formValidation: any;
  @Input() btnNewText: string;

  @Output() refreshData = new EventEmitter<boolean>();
  @Output() onFilterSiteById = new EventEmitter<number>();

  mediaSubscription: Subscription;
  Media: MediaResponse;
  sortView: boolean = false;
  userLogin: User;
  internalList: Filters[];
  editOrNewItemModal: any;
  isEditOrNewItem: boolean = false;
  titleModal: string = '';
  itemSelected: any;
  userForm: FormGroup;
  submitted: boolean = false;
  idSelected: number = 1;
  listFormType: FormType[];

  constructor(private _apiService: ApiService, private _notificationService: NotificationService, private _formBuilder: FormBuilder, private _translateService: TranslateService, private _mediaService: MediaService){
    this.internalList = InternalList;
    this.mediaSubscription = this._mediaService.subscribeMedia().subscribe(media => {
      this.Media = media;
    });
  }

  ngOnInit(): void {
    this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
    this.userForm = this._formBuilder.group({
      inputTextSelected: ['' , [Validators.required]],
      siteSelected: ['', Validators.required],
      department: ['', Validators.required],
      inputTextDescription: [''],
      inputTextSpanish: ['', Validators.required],
      inputTextPortuguese: ['', Validators.required],
      formType: ['', Validators.required],
      isHelp: [false],
      isInternal: [false],
      isPartner: [false],
      isDirect: [false],
      isActive: [false],
    });
    setTimeout(() => {
      this.IniModal();
    }, 2);

    if(this.identity == 4){
      this.getAllFormType();
    }
  }

  get f() { return this.userForm.controls; }

  getAllFormType(){
    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_FORM_TYPE }`;
    this._apiService.apiGetMethod(URL).subscribe({
        next: (result: ResponseData) => {
            if(result.success){
                this.listFormType = result.data;
            }
        },
        error: (err: any) => {
            console.log(err);
            this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
    });
  }

  editOrNewItem(item:any, isNew: boolean){
    this.titleModal = '';
    this.itemSelected = item;
    this.isEditOrNewItem = isNew;
    this.titleModal = (isNew) ? this.modalTitleNewText: this.modalTitleEditText;
    this.userForm.get('inputTextSelected')?.setValue(isNew ? '' : item.label);
    this.userForm.get('siteSelected')?.setValue(isNew ? '' : item.idSite);
    if(this.identity == 3 || this.identity == 4){
        this.userForm.get('isHelp')?.setValue(isNew ? false : item.isHelp);
        this.userForm.get('isInternal')?.setValue(isNew ? false : item.isInternal);
        this.userForm.get('isPartner')?.setValue(isNew ? false : item.isPartner);
        this.userForm.get('isDirect')?.setValue(isNew ? false : item.isDirect);
        this.userForm.get('isActive')?.setValue(isNew ? false : item.isActive);
        this.userForm.get('formType')?.setValue(isNew ? '' : item.formType);
        this.userForm.get('department')?.setValue(isNew ? '' : item.idDepartment);

        this.userForm.get('inputTextDescription')?.setValue(isNew ? '' : item.description);
        this.userForm.get('inputTextSpanish')?.setValue(isNew ? '' : item.spanishTranslation);
        this.userForm.get('inputTextPortuguese')?.setValue(isNew ? '' : item.portugueseTranslation);
    }else{
        if(this.identity != 2)
        this.userForm.get('department')?.setValue(isNew ? '' : item.idDepartment);
    }

    Object.keys(this.userForm.controls).forEach(key => {
      this.formValidation.forEach(element => {
        if(element.control == key){
          if(!element.show){
            var inputName = this.userForm.get(key);
            inputName?.setValidators(null);
            inputName?.updateValueAndValidity();
          }
        }
      });
    });
    this.editOrNewItemModal.show();
  }

  closeModal(){
    this.titleModal = '';
    this.editOrNewItemModal.hide();
  }

  createAndUpdateByPayloadData(){
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }
    const numberType = (this.isEditOrNewItem) ? (this.identity === 2 || this.identity === 4) ? this.addNewPostEvent : this.newPostEvent : this.editPostEvent;
    const data = this.returnObjetData(numberType);
    const URL = `${environment.WebApiUrl + this.returnUrlParameter((this.isEditOrNewItem) ? (this.identity === 2 || this.identity === 4) ? this.addNewPostEvent : this.newPostEvent : this.editPostEvent) }`;

    if(numberType === 4 || numberType === 8 || numberType === 10 || numberType === 12 || numberType === 14){
        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
              if (result.success) {
                if(this.identity == 1 || this.identity == 3){
                  this.refreshData.emit(true);
                }else{
                  this.onFilterSiteById.emit(this.idSelected)
                }

                this.userForm.reset();
                this.editOrNewItemModal.hide();
                this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
              }else{
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
              }
            },
            error: (err: any) => {
              console.log(err);
              this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
          });
    }else{
        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
              if (result.success) {
                if(this.identity == 1 || this.identity == 3){
                  this.refreshData.emit(true);
                }else{
                  this.onFilterSiteById.emit(this.idSelected)
                }

                this.userForm.reset();
                this.editOrNewItemModal.hide();
                this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
              }else{
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
              }
            },
            error: (err: any) => {
              console.log(err);
              this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }
  }

  updateItemByCheckbox(event:any, item:any){
    item.isActive = event.target.checked;
    item.name = item.label;
    const URL = `${environment.WebApiUrl +  this.returnUrlParameter(this.editPostEvent)}`;
    this._apiService.apiPostMethod(URL, item).subscribe({
      next: (result: ResponseData) => {
        if (result.success) {
          if(this.identity == 1 || this.identity == 3){
            this.refreshData.emit(true);
          }else{
            this.onFilterSiteById.emit(this.idSelected)
          }
          this.userForm.reset();
          this.editOrNewItemModal.hide();
          this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
        }else{
          this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
      },
      error: (err: any) => {
        console.log(err);
        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
      }
    });
  }

  deleteItem(item:any){
    Swal.fire({
      text: "Are you sure?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        const URL = `${environment.WebApiUrl + this.returnUrlParameter(this.deletePostEvent) }`;
        if(this.deletePostEvent == 3){
            //este endpoint es distinto es para eliminar brand setup creado
            item.isDelete = true;
            this._apiService.apiPostMethod(URL, item).subscribe({
                next: (result: ResponseData) => {
                  if (result.success) {
                    this.refreshData.emit(true);
                    this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                  }else{
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                  }
                },
                error: (err: any) => {
                  console.log(err);
                  this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }else{
            item.userInCharge = this.userLogin.idUser;
            item.name = item.label;
            this._apiService.apiPostMethod(URL, item).subscribe({
                next: (result: ResponseData) => {
                    if (result.success) {
                        if(this.identity == 1 || this.identity == 3){
                            this.refreshData.emit(true);
                        }else{
                            this.onFilterSiteById.emit(this.idSelected)
                        }
                        this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                    }else{
                        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }
      }
    })
  }

  UpdateAllItemCheckbox(event: any){
    this.payloadData.forEach(element => {
      element.isActive = event.checked;
      element.userInCharge = this.userLogin.idUser;
    });
    const URL = `${environment.WebApiUrl + this.returnUrlParameter(this.selectAllCheckbox) }`;
    this._apiService.apiPostMethod(URL, this.payloadData).subscribe({
      next: (result: ResponseData) => {
        if (result.success){
          this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
        }else{
          this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  deleteItemByPayloadData(arr:any, idvalue: number) {
    const objWithIdIndex = arr.findIndex((obj:any) => obj.idvalue === idvalue);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  }

  returnUrlParameter(idType: number){
    switch (idType) {
      case 1:
      case 2:
      case 3: return PostMethods.POST_UPDATE_INSERT_BRAND_SETUP;
      case 4: return PutMethods.UPDATE_ALL_ITEM_RELEVANCE_LEVEL;
      case 5: return PostMethods.POST_UPDATE_INSERT_BRAND_SITE;
      case 6: return DeleteMethods.DELETE_SITE_FOR_BRAND;
      case 7: return PostMethods.POST_INSERT_NEW_SUBJECT;
      case 8: return PutMethods.UPDATE_SUBJECT_ITEM;
      case 9: return DeleteMethods.DELETE_SUBJECT;
      case 10: return PutMethods.UPDATE_ALL_SUBJECT_ITEM;
      case 12: return PutMethods.UPDATE_SUBJECT_FIELD_ITEM;
      case 13: return DeleteMethods.DELETE_SUBJET_FIELD;
      case 14: return PutMethods.UPDATE_ALL_SUBJECT_FIELD_ITEM;
      case 15: return PostMethods.POST_INSERT_NEW_SUBJECT_FIELD;
      default: return null;
    }
  }

  returnObjetData(idType: number){
    switch (idType) {
      case 1: return {
        brandName: this.f.inputTextSelected.value,
        description: this.allSiteTags.find(x => x.idSiteTag == this.f.siteSelected.value)?.description,
        idBrandSetup: -1,
        idDepartment: this.f.department.value,
        idSite: this.f.siteSelected.value,
        isActive: true,
        isDelete: false,
        userInCharge: this.userLogin.idUser
      };

      case 2: return {
        brandName: this.f.inputTextSelected.value,
        description: this.allSiteTags.find(x => x.idSiteTag == this.f.siteSelected.value)?.description,
        idBrandSetup: this.itemSelected.idBrandSetup,
        idDepartment: this.f.department.value,
        idSite: this.f.siteSelected.value,
        isActive: this.itemSelected.isActive,
        isDelete: this.itemSelected.isDelete,
        lastModification: this.itemSelected.lastModification,
        userInCharge: this.userLogin.idUser
      }

      // New Site by modal
      case 5: return {
        description: this.allSiteTags.find(x => x.idSiteTag == this.f.siteSelected.value)?.description,
        idBrandSetup: this.idSelected,
        idSite: this.f.siteSelected.value,
        isActive: true,
        isDelete: false,
        userInCharge: this.userLogin.idUser
      };

      case 7: return {
        description: this.f.inputTextDescription.value,
        idDepartment: this.f.department.value,
        isActive: this.f.isActive.value,
        isDirect: this.f.isDirect.value,
        isHelp: this.f.isHelp.value,
        isInternal: this.f.isInternal.value,
        isPartner: this.f.isPartner.value,
        name: this.f.inputTextSelected.value,
        portugueseTranslation: this.f.inputTextPortuguese.value,
        spanishTranslation: this.f.inputTextSpanish.value,
        sserInCharge: this.userLogin.idUser
      }

      case 8: return {
        department: this.departmentList.find(x => x.idDepartment == this.f.department.value)?.name,
        description: this.f.inputTextDescription.value,
        idDepartment: this.f.department.value,
        idSubject: this.itemSelected.idSubject,
        isActive: this.f.isActive.value,
        isDelete: this.itemSelected.isDelete,
        isDirect: this.f.isDirect.value,
        isHelp: this.f.isHelp.value,
        isInternal: this.f.isInternal.value,
        isPartner: this.f.isPartner.value,
        name: this.f.inputTextSelected.value,
        lastModification: this.itemSelected.lastModification,
        portugueseTranslation: this.f.inputTextPortuguese.value,
        spanishTranslation: this.f.inputTextSpanish.value,
        userInCharge: this.userLogin.idUser,
        userInChargeName: null
      }

      case 12: return {
        formType: this.f.formType.value,
        idDepartment: this.f.department.value,
        idSubject: this.idSelected,
        idSubjectField: this.itemSelected.idSubjectField,
        isActive: this.f.isActive.value,
        isDelete: this.itemSelected.isDelete,
        lastModification: this.itemSelected.lastModification,
        name: this.f.inputTextSelected.value,
        portugueseTranslation: this.f.inputTextPortuguese.value,
        spanishTranslation: this.f.inputTextSpanish.value,
        textDescription: null,
        userInCharge: this.userLogin.idUser,
        userInChargeName: null
      }

      case 15: return {
        formType: this.f.formType.value,
        idSubject: this.idSelected,
        isActive: this.f.isActive.value,
        name: this.f.inputTextSelected.value,
        portugueseTranslation: this.f.inputTextPortuguese.value,
        spanishTranslation: this.f.inputTextSpanish.value,
        userInCharge: this.userLogin.idUser
      }
      default: return null;
    }
  }

  changeViewSites(){
    this.sortView = !this.sortView;
    if(!this.sortView){
      const URL = `${environment.WebApiUrl + PutMethods.UPDATE_SORT_SUBJECTS }`;
      this._apiService.apiPostMethod(URL, this.payloadData).subscribe({
        next: (result: ResponseData) => {
          if (result.success){
            this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
          }else{
            this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
          }
        },
        error: (err: any) => {
          console.log(err);
          this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
      });
    }
  }

  dropSubjects(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.payloadData, event.previousIndex, event.currentIndex);
  }

  onFindSiteData(event: any){
    this.idSelected = event.value;
    this.onFilterSiteById.emit(event.value);
  }

  TranslateText(text: string) {
    return this._translateService.instant(text);
  }

  IniModal() {
    this.editOrNewItemModal = new bootstrap.Modal(document.getElementById('editOrNewItemModal-'+this.identity), {
      keyboard: false
    });
  }
}
