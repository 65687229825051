<div>
    <ng-container *ngIf="this.routeState !== '/login' && this.routeState !== '/404'">
        <app-sidenav [isExpanded]="sidebarExpanded" (toggleSidebar)="sidebarExpanded = !sidebarExpanded"></app-sidenav>
        <div [ngClass]="{ 'sidebarExpanded': sidebarExpanded, 'hide-content': this.routeState !== '/login' }">
            <app-navbar [isExpanded]="sidebarExpanded"></app-navbar>
        </div>
    </ng-container>

    <div [ngClass]="{ 'sidebarExpanded': sidebarExpanded, 'content': this.routeState !== '/login' && this.routeState !== '/404', 'sidebarDesktopExpanded': Media.IsMobile}"
    style="overflow-y: scroll;">
        <ng-http-loader
            *ngIf="showLoading.value"
            [backdrop]="true"
            [backgroundColor]="this.themeList.spinerLoaderColor"
            [debounceDelay]="100"
            [extraDuration]="100"
            [minDuration]="100"
            [opacity]="opacity"
            [backdropBackgroundColor]="this.themeList.spinerBgColor"
            [spinner]="this.themeList.spiner">
        </ng-http-loader>
        <router-outlet></router-outlet>
        <p-scrollTop target="parent" styleClass="custom-scrolltop" [threshold]="100"></p-scrollTop>
        <div class="row text-center position-absolute" style="bottom: 0;">
            <span class="text-dim">version {{version}}</span>
        </div>
    </div>
</div>
