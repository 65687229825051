export class ConfigSite {
    SiteName: string;
    WebApiUrl: string;
    AdminUrl: string;
    DefaultHostName: string;
    CSId: number;
    uploadFilesApi: string;
    previousDisplay: string;
}

// export class User {
//     IdUser: number;
//     idUser: number;
//     email: string;
//     password: string;
//     adminType: number;
//     name: string;
//     nickname: string;
//     IsActive: boolean;
//     isTFA: boolean;
//     IdDepartment: number;
//     DepartmentName: string;
//     UserType: number;
//     UserTypeName: string;
//     AccessAllSites: boolean;
//     token: string;
//     UserInCharge: number;
//     Profile: number;
//     ProfileName: string;
//     SmartLogin: boolean;
//     ReleaseTicket: boolean;
//     ViewOnly: boolean;
// }


export class User {
    idUser: number;
    email: string;
    password: string;
    adminType: number;
    name: string;
    nickname: string;
    isActive: boolean;
    isTFA: boolean;
    idDepartment: number;
    departmentName: string;
    idUserType: number;
    userTypeName: string;
    accessAllSites: boolean;
    token: string;
    userInCharge: number;
    profile: number;
    profileName: string;
    smartLogin: boolean;
    releaseTicket: boolean;
    viewOnly: boolean;
}

export class TagComponent {
    id: number;
    title: string;
    type: string;
    url: string;
    classes: string;
    icon: string;
    ActiveForProfile: boolean;
}

export class TokenAccess {
    IdTokenAccess: number;
    User: string;
    Token: string;
    IpAddress: string;
    Date: Date;
    SendEmail: boolean;
}

export class TwoFactor {
    idUser: number;
    email: string;
    inputCode: string;
    isTFA: boolean;
}

export class SmartLogin {
    IdUser: number;
    SmartSelection: boolean;
}