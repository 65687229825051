
export class ResponseData {
    data: any;
    error: string;
    errorCode: string;
    success: boolean;
}

export class ConfigSite {
    SiteName: string;
    WebApiUrl: string;
    AdminUrl: string;
    DefaultHostName: string;
    CSId: number;
    uploadFilesApi: string;
    previousDisplay: string;
}

export class ConfigByDomain {
    idConfigByDomain: number;
    domain: string;
    idSite: number;
    theme: string;
    language: string;
    idSiteType: number;
    token: string;
}

export class TicketMessage {
    idTicketMessage: number;
    idTicket: number;
    message: string;
    sender: number;
    sendingUser: string;
    sendToPlayer: boolean;
    dateTransactionMessage: Date;
    attachedFile: string;
    attachedFileString: string;
    sendingUserName: string;
    idSiteType: number;
    replyToClient: boolean;
    idStatus: number;
}

export class ReturnTokenDto {
    Status: boolean;
    Token: string;
    Player: string;
    Email: string;
    IdSite: number;
    Language: string;
    Pw: string;

    IdCurrency: number;
    IdCurrencyDgs: number;
    CurrencyCode: number;
}

export class siteTag {
    idSiteTag: number;
    description: string;
    siteDomain: string;
}

export class Ticket {
    IdTicket: number;
    TicketNumber: number;
    IdSite: number;
    IdPlayer: string;
    IdAgent: string;
    Subject: string;
    IdType: number;
    IdStatus: number;
    DateTransaction: Date;
    Relevance: number;
    Department: TicketDepartment[];
    Email: string;
    ServiceDeskTicket: string;
    AssignedUser: string;
    OpenTicketStatus: boolean;
    OpenTicketUser: string;
}

export class TicketDepartment {
    IdTicketDepartment: number;
    IdDepartment: number;
    IdTicket: number
    DepartmentName: string;
    IdUser: number;
    NewDepartmentName: string;
    TicketNumber: number;
}

export class PlayerDto {

    IdPlayer: number;
    Player: string;
    IdAgent: number;

    IdLanguage: number;
    Language: string;

    IdCurrency: number;
    Currency: string;

    Email: string;
    Name: string;
    LastName: string;
    LastName2: string;

    Address: string;
    City: string;
    Phone: string;

    CountryCode: string;
    StateCode: string;
}

export class InternalUsers {
    IdUser: number;
    UserEmail: string;
    UserCompleteName: string;
    IsActive: boolean;
}

export class MessageStatus {
    Id: number;
    Description: string;
}


// Tickets Objects
export class SubjectTickets {

    idSubject: number;
    subjectName: string;
    idDepartment: number;
    department: string;
    isActive: boolean;
    userInCharge: number;
    description: string;
    spanishTranslation: string;
    isHelp: boolean;
    isInternal: boolean;
    isPartner: boolean;
    isDirect: boolean;
}

export class SubjectFields {

    idSubjectField: number;
    idSubject: number;
    name: string;
    isActive: boolean;
    textDescription: string;
    userInCharge: number;
    formType: number;
    spanishTranslation: string;
    idDepartment: number;
    portugueseTranslation: string;
}

export class ConfigWebsiteFields {
    IdSite: number;
    IdSub: number;
}
// Relevance Levels

export class RelevanceLevel {

    idRelevance: number;
    description: string;
    isActive: boolean;
    userInCharge: number;
}

// Ticket Status
export class TicketStatus {

    idStatusList: number;
    description: string;
    isActive: boolean;
    userInCharge: number;
}

// Department

export class Department {
    idDepartment: number;
    name: string;
    email: string;
    isActive: boolean;
    userInCharge: number;
    isInternal: boolean;
}

// Website setup

export class WebsiteSetup {
    idWebsiteSetup: number;
    idSubject: number;
    idSite: number;
    isActive: boolean;
    position: number;
}

export class WebsiteFieldsSetup {
    idWebsiteFieldsSetup: number;
    idSubjectField: number;
    idSubject: number;
    idSite: number;
    isActive: boolean;
    position: number;
    name: string;
    formType: number;
    spanishTranslation: string;
    portugueseTranslation: string;
}

export class siteTagTEMP {
    idSiteTag: number;
    description: string;
    idSubject: number;
    subjectName: string;
    isActive: boolean;
    position: number;
    descriptionSubject: string;
}

export class TicketGeneral {
    idTicket: number;
    ticketNumber: number;
    idSite: number;
    idPlayer: string;
    idAgent: number;
    subject: string;
    idTypeMessage: number;
    idStatus: number;
    relevance: number;
    assigned: number;
    idTicketMessage: number;
    message: string;
    email: string;
    dateTransaction: Date;
    sender: number;
    sendingUser: string;
    sendToPlayer: boolean;
    attachments: string;
    subjectField: string;
    assignedUser: string;
    casinoProvider: any;
    casinoGameName: any;
    casinoCategory: any;
    activeAccount: boolean;
    idSiteType: number;
}

export class User {
    idUser: number;
    email: string;
    password: string;
    adminType: number;
    name: string;
    nickname: string;
    isActive: boolean;
    isTFA: boolean;
    idDepartment: number;
    departmentName: string;
    userType: number;
    idUserType: number;
    userTypeName: string;
    accessAllSites: boolean;
    token: string;
    userInCharge: number;
    profile: number;
    profileName: string;
    smartLogin: boolean;
    releaseTicket: boolean;
    viewOnly: boolean;
}

export class ComponentsByUser {
    IdComponentsByUsers: number;
    IdTagComponent: number;
    IdUser: number;
    IsActive: boolean;
    UserInCharge: number;
    All: boolean;
}

export class UserBySite {
    idUserSite: number;
    idUser: number;
    idSite: number;
    name: string;
    isActive: boolean;
}

export class TagComponent {
    id: number;
    title: string;
    type: string;
    url: string;
    classes: string;
    icon: string;
    ActiveForProfile: boolean;
}

export class CountTickets {
    idUser: number;
    count: number;
}

// Token access

export class TokenAccess {
    IdTokenAccess: number;
    User: string;
    Token: string;
    IpAddress: string;
    Date: Date;
    SendEmail: boolean;
}

// PLAYER SITE
export class Topics {
    ThemeId: string;
    IdSubject: number;
    IdSite: number;
    Name: string;
    IsActive: boolean;
    FormType: number;
    SpanishTranslation: string;
}


// Notifications
export class NotificationsTicket {
    IdNotification: number;
    IdTicket: number;
    IdUser: number;
    NotificationType: number;
    Read: string;
    TicketNumber: number;
    IdSiteType: number;
}


export class NotificationsPlayerAgent {
    IdNotification: number;
    IdTicket: number;
    IdUser: string;
    NotificationType: number;
    Read: string;
}

export class Profile {
    IdProfile: number;
    ProfileName: string;
    UserType: number;
    AccessAllSites: boolean;
    IsActive: boolean;
    AccessSite: siteTag[];
    AccessTagComponent: TagComponent[];
    ApplyAllUsers: boolean;
}

export class FormType {
    IdFormType: number;
    FormName: string;
}

export class InfoDetailsDTO {
    idUser: number;
    transactionType: number;
    startDate: Date;
    endDate: Date;
    idSiteType: number
}

export class PersonalWorkLog {
    IdPersonalWorkLog: number;
    Account: string;
    Issue: string;
    Source: number;
    User: number;
    TransactionDate: Date;
    SourceInfo: Source;
    Comment: string;
}

export class Source {
    IdSource: number;
    Name: string;
}

export class Issues {
    IdIssue: number;
    Issue: string;
    IdDepartment: number;
}
export class OpenTicketDTO {
    IdTicket: number;
    openStatus: boolean;
    User: number;
}


export class Filters {
    Site: string;
    Department: string;
    Subject: string;
    Status: string;
    Relevance: string;
    Account: string;
    Request: string;
    Source: string;
    User: string;
    TicketNumber: string;
    Assigned: string;

    IdNotification: string;
    Details: string;
}

export class SiteProfile {
    IdSiteProfile: number;
    Name: string;
    Subject: SubjectTickets[];
    Field: SubjectFields[];
    IsActive: boolean;
}

export class WebsiteSetup_SiteProfile {
    siteList: any[];
    idSiteProfile: number;
    idWebsiteType: number;
}

// Notification Center
export class NotificationCenter {
    IdNotification: number;
    Account: string;
    Details: string;
    IdSite: number;
    Site: string;
    IdStatus: number;
    Status: string;
    Comment: string;
    Assigned: number;
    AssignedName: string;
    NotificationType: number;
}

export class NotificationCenterResponse {
    CashierNotifications: NotificationCenter[];
    SignupNotifications: NotificationCenter[];
}

export class NotificationCenterRequest {
    User: User;
    StartDate: Date;
    EndDate: Date;
}

export class Shortcut {
    idShurtcut: number;
    UserInCharge: number;
    DateCreated: Date;
    Name: string;
    Description: string;
    IdDepartment: number;
    DepartmentName: string;
    UserName: string;
    Message: string;
}

export class Note {
    idNote: number;
    date: Date;
    tickerOrTaskName: string;
    emailSubject: string;
    idUserInCharge: number;
    userInCharge: string;
    status: string;
    idDepartment: number;
    departmentName: string;
    initialComment: string;
}

export class NoteComment {
    idNote: number;
    idMessage: number;
    sender: string;
    comment: string;
    date: Date;
}

export class IQUser {
    idUserToken: number;
    idIQUser: number;
    userName: string;
    userToken: string;
    date: Date;
    siteURL: string;
    email: string;
    idSite: string;
    idSiteType: string;
}

export class IQSite {
    idSite: number;
    description: string;
    siteDomain: string;
    idAgent: number;
    lastModification: Date;
}


//pablo
export class LogRequest {
    typeRequest: string;
    idSite?: number;
    startDate: Date;
    endDate: Date;
}

export class DepartmentsByUser {
    IdDepartment: number;
    IdUser: number;
    IsActive: boolean;
    UserInCharge: number;
}

export class DepartamentoPorUsuario {
    idUser: number;
    Department: Department[];
}

export class Department2 {
    IdDepartment: number;
    Name: string;
    IsActive: boolean;
}

export class TwoFactor {
    IdUser: number;
    Email: string;
    InputCode: string;
    IsTFA: boolean;
}

export class SmartLogin {
    IdUser: number;
    SmartSelection: boolean;
}

export class NotificationsByTicket {
    IdTicket: number;
    IdUser: number;
    NotificationRead: boolean;
    IdSiteType: number;
    TransactionDate: Date;
    MarkAsUnread: number;
}

export class LanguageByUser {
    Encriptado: boolean;
    IdUser: string;
    Lan: string;
    IdSite: number;
    IdSiteType: number; //1 player, 2 agents, 3 cts admins
}

export class SiteByProfile {
    idProfile: number;
    idSite: number;
    isActive?: boolean;
}

export class ReleaseByUser {
    IdUser: number;
    IsActive: boolean;
}

export class ViewOnlyByUser {
    IdUser: number;
    IsActive: boolean;
}

export class BrandSetup {
    idBrandSetup: number;
    brandName: string;
    idSite: number;
    description: string;
    idDepartment: number;
    isActive: boolean;
    isDelete: boolean;
    lastModification: Date;
    userInCharge: number;
}

export class BrandSite {
    idBrandSites: number;
    idBrandSetup: number;
    idSite: number;
    description: string;
    isActive: boolean;
    isDelete: boolean;
    lastModification: Date;
    userInCharge: number;
    idSiteBrandSetup?: number;
    value: string;
    label: string;
}
