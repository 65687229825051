import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MediaResponse, MediaService } from '@services/media.service';
import { Subscription, filter } from 'rxjs';
import { ClientTypeList, Filters } from '@methods/constants';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';
import { ApiService } from '@services/api.service';
import { ConfigByDomain, ResponseData, TicketGeneral, TicketMessage, User } from '@models/models';
import { GetMethods, Methods, PostMethods, PreviousImage, PutMethods, getFormType, reemplazarImagenBase64ConBlob, validateFormControlType } from '@methods/methods';
import { NotificationService } from '@services/notification.service';
import { Editor, Toolbar } from 'ngx-editor';
import { CounterService } from '@services/counter.service';

@Component({
    selector: 'app-ticket-form',
    templateUrl: './ticket-form.component.html'
})
export class TicketFormComponent implements OnInit {
    @Input() subjectList: any;
    @Input() allSiteTags: any;
    @Input() relevanceList: any;
    @Input() departmentList: any;
    @Input() shortCutByDepartment: any;
    @Input() ticketCenterType: number;
    @Input() formType: number;
    @Input() showForm: any;
    @Input() TicketInfo: any;
    @Input() IdSiteType: number;
    @Input() allUserList: any;
    @Input() formTicket: FormGroup;
    @Input() statusList: any;
    @Input() editItemSelected: any;
    @Output() onCallFormMethod = new EventEmitter<number>();

    uploader: any = FileUploader;
    model: any = {};
    submitted: boolean = false;
    newMessageTicket: TicketMessage = new TicketMessage();
    newGeneralTicket: TicketGeneral = new TicketGeneral();
    mediaSubscription: Subscription;
    Media: MediaResponse;
    clientTypeList: Filters[];
    domainInfo: ConfigByDomain;
    SubjectFieldsData: any;
    userLogin: User;
    formValidation: any;
    attached: string = '';
    data: any;

    editor: Editor;
    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['code', 'blockquote'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    constructor(private _counterService: CounterService, private _mediaService: MediaService, private _formBuilder: FormBuilder, private _apiService: ApiService, private _notificationService: NotificationService) {
        this.mediaSubscription = this._mediaService.subscribeMedia().subscribe(media => {
            this.Media = media;
        });
        this.clientTypeList = ClientTypeList;
    }

    ngOnInit(): void {
        this.uploader = new FileUploader({
            url: environment.uploadFilesApi, //url del api
            //allowedMimeType: ['image/jpeg', 'image/png', 'image/jpg'],
            //maxFileSize: 10 * 1024 * 1024,
            isHTML5: true,
        });

        this.editor = new Editor();

        this.uploader.onAfterAddingFile = (item: FileItem) => {
            item.withCredentials = false;
            this.PreviousImage(item);
        };

        this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
        this.domainInfo = JSON.parse(localStorage.getItem('DomainCTS')!);
        const isSatusDisabled = (this.formType == 1 || this.formType == 2 || this.formType == 3 || this.formType == 4) ? true : false;
        let messageTextEdition = (this.editItemSelected?.message != null && this.editItemSelected?.message != undefined) ? this.editItemSelected.message : '';
        messageTextEdition = this.setMessage(messageTextEdition);

        this.formTicket = this._formBuilder.group({
            userSelected: ['', [Validators.required]],
            account: ['', [Validators.required, Validators.maxLength(30)]],
            agentPlayer: ['', [Validators.required]],
            subject: ['', [Validators.required]],
            subjectField: ['', [Validators.required]],
            website: [(this.formType == 4) ? -1 : '', [Validators.required]],
            relevance: ['', [Validators.required]],
            department: [(this.editItemSelected != null || this.editItemSelected != undefined) ? this.editItemSelected.idDepartment : '', [Validators.required]],
            replyToEmail: ['', [Validators.required]],
            shortcuts: [''],
            message: [messageTextEdition, [Validators.required]],
            emailSubject: [(this.editItemSelected != null || this.editItemSelected != undefined) ? this.editItemSelected.emailSubject : '', [Validators.required]],
            ticketLink: [(this.editItemSelected != null || this.editItemSelected != undefined) ? this.editItemSelected.tickerOrTaskName : '', [Validators.required]],
            inputTextName: [(this.editItemSelected != null || this.editItemSelected != undefined) ? this.editItemSelected.inputTextName : '', [Validators.required]],
            description: [(this.editItemSelected != null || this.editItemSelected != undefined) ? this.editItemSelected.description : '', [Validators.required]],
            status: new FormControl({ value: isSatusDisabled ? 3 : (this.editItemSelected != null || this.editItemSelected != undefined) ? this.editItemSelected.idStatus : '', disabled: isSatusDisabled }, Validators.required)
        });
        this.showForm = getFormType(this.formType);
        this.formValidation = validateFormControlType(this.formType);
        this.setFormValidators()
    }

    setFormValidators() {
        Object.keys(this.formTicket.controls).forEach(key => {
            this.formValidation.forEach(element => {
                if (element.control == key) {
                    if (!element.show) {
                        var inputName = this.formTicket.get(key);
                        inputName?.setValidators(null);
                        inputName?.updateValueAndValidity();
                    }
                }
            });
        });
    }

    get f() { return this.formTicket.controls; }

    onChangeSubjectItemSelected(IdSubject: any) {
        if (IdSubject.value != null) {
            let URL: string = ''
            if (this.domainInfo.idSite != 0) {
                URL = `${environment.WebApiUrl + GetMethods.GET_SUBJECT_FIELD_FOR_CTSADMIN_PROFILE + this.domainInfo.idSite + '&IdSubject=' + Number(IdSubject.value)}`;
            } else {
                URL = `${environment.WebApiUrl + GetMethods.GET_SUBJECT_FIELD_BY_ID + IdSubject.value}`;
            }

            this._apiService.apiGetMethod(URL).subscribe({
                next: (result: ResponseData) => {
                    if (result.success) {
                        this.SubjectFieldsData = result.data;
                    }
                },
                error: (err: any) => {
                    console.log(err);
                }
            });
        }
    }

    onChangeShurtcutMesage(message: string) {
        this.formTicket.get('message')?.setValue(message);
    }

    onChange(html: string) {
        if (html == '<p></p>') {
            this.formTicket.get('message')?.setValue('');
        } else {
            const expresionRegular = /data:image\/(?:png|jpg|jpeg);base64,/gi;
            const coincidencias = expresionRegular.test(html);

            if (coincidencias) {
                const texto = reemplazarImagenBase64ConBlob(html, this.uploader);
                setTimeout(() => {
                    this.formTicket.get('message')?.setValue(texto);
                }, 2000);
            }
        }
    }

    onChangeInput(event: any) {
        this.removeImageScreenInEditor();
    }

    /* UPLOAD ITEM */
    PreviousImage(item: FileItem) {
        this.model = PreviousImage(item, this.uploader);
        this.removeImageScreenInEditor()
    }

    removeImageScreenInEditor() {
        setTimeout(() => {
            const nombreArchivoAEliminar = 'screenshot-';
            this.uploader.queue = this.uploader.queue.filter(file => !file._file.name.startsWith(nombreArchivoAEliminar));
        }, 2000);
    }

    submitForm() {
        this.submitted = true;
        if (this.formTicket.invalid) {
            return;
        }

        if (this.uploader?.queue.length > 0) {
            this.uploader?.queue.forEach((element: { isSuccess: any; _file: { name: string; }; }) => {
                if (element.isSuccess) {
                    this.attached += element._file.name + "/";
                }
            });

            this.newMessageTicket.attachedFileString = this.attached;
            this.newGeneralTicket.attachments = this.attached;
        }else{
            this.newGeneralTicket.attachments = '';
        }

        //agregar <br> en cada <p></p>
        this.AdjustTextSeparator(this.formTicket.get('message')?.value);

        if (this.formType == 1 || this.formType == 2 || this.formType == 3 || this.formType == 4) {
            // 1 = help ticket center // 2 partners-ticket-center // 3 internal-ticket center // 4 = direct-ticket-center
            this.CreateNewGeneralTicket();
        } else {
            // 5 = New Message // 6 New Internal Message // 7 Edit Message // 8 Service - Desk INSERT NEW
            // 9 Service - Desk INSERT NEW COMMENT // 10 Service - Desk EDIT ENTRY // 12 INSERT NEW SHORT CUT
            this.sendGeneralTicketInfo();
        }
    }

    CreateNewGeneralTicket() {
        if (this.formType != 3 && this.formType != 2 && this.formType != 4) {
            this.newGeneralTicket.sendToPlayer = true;
            this.newGeneralTicket.assigned = this.f.department.value;
            this.newGeneralTicket.idAgent = this.f.agentPlayer.value;
        }

        this.newGeneralTicket.activeAccount = (this.formType == 3 || this.formType == 4) ? false : true;
        this.newGeneralTicket.assignedUser = (this.formType == 4) ? this.f.userSelected.value.toString() : "0";
        // this.newGeneralTicket.assigned = (this.formType != 4) ? "0" : this.f.userSelected.value.toString();
        this.newGeneralTicket.assigned = 0;
        this.newGeneralTicket.email = this.userLogin.email.toString();
        this.newGeneralTicket.idPlayer = this.f.account.value;
        this.newGeneralTicket.idSite = (this.formType != 4) ? this.domainInfo.idSite : this.f.website.value; //-1 direct ticket
        this.newGeneralTicket.idSiteType = this.formType;
        this.newGeneralTicket.idStatus = 3; // open
        this.newGeneralTicket.idTypeMessage = 1;
        this.newGeneralTicket.message = this.f.message.value;
        this.newGeneralTicket.relevance = (this.formType == 3 || this.formType == 2 || this.formType == 4) ? 2 : this.f.relevance.value;
        this.newGeneralTicket.sender = 1; // Type 1 = user
        this.newGeneralTicket.sendingUser = this.userLogin.idUser.toString();
        this.newGeneralTicket.subject = this.f.subject.value.toString();
        this.newGeneralTicket.subjectField = this.f.subjectField.value.toString();
        this.newGeneralTicket.casinoProvider = null;
        this.newGeneralTicket.casinoGameName = null;
        this.newGeneralTicket.casinoCategory = null;

        const URL = `${environment.WebApiUrl + this.OnSendTicketFormType(this.formType)}`;
        this._apiService.apiPostMethod(URL, this.newGeneralTicket).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                    this.uploader.queue = [];
                    this.newGeneralTicket = new TicketGeneral();
                    this.ClearForm();
                    this._counterService.setNotificationsServiceSub(true);
                    this.submitted = false;
                } else {
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    ClearForm(){
        if(this.formTicket!=null){
            if(this.formTicket.controls.status!=null){
                let tempStatus = this.formTicket.controls.status.value;
                this.formTicket.reset({status: tempStatus});
            }else{
                this.formTicket.reset()
            }
        }
    }

    sendGeneralTicketInfo() {

        this.data = this.OnSendFormTypeData(this.formType);
        const URL = `${environment.WebApiUrl + this.OnSendTicketFormType(this.formType)}`;
        if (this.formType == 7 || this.formType == 10 || this.formType == 13) {
            //metodos de tipo update
            this._apiService.apiPostMethod(URL, this.data).subscribe({
                next: (result: ResponseData) => {
                    if (result.success) {
                        this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                        if (this.formType == 5 || this.formType == 6 || this.formType == 7) {
                            this.uploader.queue = [];
                        }
                        if (this.uploader?.queue.length > 0) {
                            this.uploader?.clearQueue();
                        }

                        if (this.formType == 10 || this.formType == 13) {
                            this.onCallFormMethod.emit(this.data);
                        } else {
                            this.onCallFormMethod.emit(this.formType);
                        }
                    } else {
                        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        } else {
            this._apiService.apiPostMethod(URL, this.data).subscribe({
                next: (result: ResponseData) => {
                    if (result.success) {
                        this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                        //notificaciones
                        if(this.formType == 6 || this.IdSiteType == 2){
                            const data = {
                                idTicket: this.TicketInfo.idTicket,
                                idUser: this.userLogin.idUser,
                                idSiteType: this.IdSiteType
                            }
                            this._apiService.apiPostMethod(`${environment.WebApiUrl+PostMethods.INSERT_NOTIFICATIONS_BY_TICKET}`, data).subscribe({
                                next: (response) => {
                                    //console.log('@@', response);
                                },
                                error: (e) => console.log('error insertando notificaciones', e)
                            });
                        }
                        if (this.formType == 5 || this.formType == 6 || this.formType == 7) {
                            this.uploader.queue = [];
                        }
                        if (this.uploader?.queue.length > 0) {
                            this.uploader?.clearQueue();
                        }

                        if (this.formType == 10 || this.formType == 13) {
                            this.onCallFormMethod.emit(this.data);
                        } else {
                            this.onCallFormMethod.emit(this.formType);
                        }
                    } else {
                        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }
    }

    OnSendFormTypeData(ticketType: number) {
        switch (ticketType) {
            case 5:
            case 6:
            case 7: return {
                idTicket: this.TicketInfo.idTicket,
                idStatus: this.TicketInfo.ticketInfo.idStatus,
                idSiteType: this.IdSiteType,
                sendToPlayer: this.formType == 5 ? true : false,
                sendingUser: this.userLogin.idUser.toString(),
                replyToClient: true,
                sender: 1,
                idTicketMessage: this.TicketInfo.idTicketMessage,
                message: this.f.message.value,
                attachments: this.attached
            }

            case 8: return {
                emailSubject: this.f.emailSubject.value,
                idDepartment: this.userLogin.idDepartment,
                idUserInCharge: this.userLogin.idUser,
                initialComment: this.f.message.value,
                status: this.statusList.find(x => x.idStatusList == this.f.status.value)?.description,
                tickerOrTaskName: this.f.ticketLink.value
            }

            case 9: return {
                message: this.f.message.value,
                idNote: this.editItemSelected.idNote,
                idUserInCharge: this.userLogin.idUser
            }

            case 10: return {
                date: this.editItemSelected.date,
                departmentName: this.departmentList.find(x => x.idDepartment == this.f.department.value)?.Name,
                emailSubject: this.f.emailSubject.value,
                idDepartment: this.f.department.value,
                idNote: this.editItemSelected.idNote,
                idUserInCharge: this.userLogin.idUser,
                initialComment: null,
                position: this.editItemSelected.position,
                status: this.statusList.find(x => x.idStatusList == this.f.status.value)?.description,
                tickerOrTaskName: this.f.ticketLink.value,
                userInCharge: this.editItemSelected.userInCharge
            }

            case 12: return {
                description: this.f.description.value,
                idDepartment: this.f.department.value,
                message: this.f.message.value,
                name: this.f.inputTextName.value,
                userInCharge: this.userLogin.idUser
            }


            //SHORTCUT
            case 13: return {
                dateCreated: this.editItemSelected.editItemSelected,
                departmentName: this.departmentList.find(x => x.idDepartment == this.f.department.value)?.name,
                description: this.f.description.value,
                idDepartment: this.f.department.value,
                message: this.f.message.value,
                name: this.f.inputTextName.value,
                userInCharge: this.userLogin.idUser,
                userName: this.editItemSelected.userName,
                idShortcut: this.editItemSelected.idShortcut
            }

            default: return null;

        }
    }

    OnSendTicketFormType(ticketType: number) {
        switch (ticketType) {
            case 1:
            case 2:
            case 3:
            case 4: return PostMethods.POST_CREATE_NEW_TICKET; //TICKET
            case 5:
            case 6: return PostMethods.POST_CREATE_NEW_TICKET_MESSAGE; //TICKET
            case 7: return PutMethods.UPDATE_MESSAGE_BY_USER; //TICKET
            case 8: return PostMethods.POST_INSERT_NEW_TASK_NOTE; //SERVICE DESK ADD NEW
            case 9: return PostMethods.POST_INSERT_NEW_TASK_NOTE_MESSAGE; //SERVICE DESK ADD NEW COMMENT
            case 10: return PutMethods.UPDATE_TASK_NOTE_INFO; //SERVICE DESK UPDATE TASK
            case 12: return PostMethods.POST_INSERT_NEW_SHORTCUT; //SHORTCUT CENTER ADD NEW SHORTCUT
            case 13: return PutMethods.UPDATE_SHORT_CUT_INFO; //SHORTCUT CENTER UPDATE SHORTCUT

            default: return null;
        }
    }

    AdjustTextSeparator(html: string){
        // Reemplazar cada '<p></p>' con '<p><br></p>'
        const modifiedHtml = html.replace(/<p>\s*<\/p>/g, '<p></p>');
        // Establecer el valor del campo 'message' con el HTML modificado
        this.formTicket.get('message')?.setValue(modifiedHtml);
    }

    CleanHTML(html: string): string {
        const tempEl = document.createElement('div');
        tempEl.innerHTML = html;
        // Eliminar todos los atributos de estilo de todos los elementos
        tempEl.querySelectorAll('*').forEach((el) => {
            el.removeAttribute('style');
            el.removeAttribute('class');
        });

        // Retornar el contenido limpio en forma de cadena
        return tempEl.innerHTML;
    }

    onPaste() {
        this.formTicket.get('message')?.setValue(this.CleanHTML(this.formTicket.get('message')?.value));
    }

    setMessage(html: string){

        const div = document.createElement('div');
        div.innerHTML = html;
        const brs = div.querySelectorAll('br');
        brs.forEach(br => br.remove());
        return div.innerHTML
      }

}
