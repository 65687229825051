<p-card class="card mt-3" header="{{'USER.TICKER.TITLE' | translate}}" subheader=""
    [style]="{ width: 'auto', padding: '0' }">
    <p-table class="custom-table-td-fix" #dt [value]="ticketInfoData" [columns]="columsData">

        <ng-template pTemplate="caption">
            <div class="d-flex align-items-center justify-content-end">
                <p-button (onClick)="onMarkAsUnread()" styleClass="p-button-danger mx-1" icon="feather icon-eye-off"
                    ngbTooltip="{{'Mark as unread' | translate}}" label="{{'Mark as unread' | translate}}"></p-button>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{ col.header | translate}}
                </th>
            </tr>

        </ng-template>

        <ng-template pTemplate="body" let-TicketInfo>
            <tr *ngIf="!existsBrandSetup" class="p-selectable-row">
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)"> {{TicketInfo.ticketInfo.ticketNumber | translate}} </span>
                </td>
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.ticketInfo.idPlayer}}</span>
                </td>
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.subject | translate}}</span>
                </td>
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.site | translate}}</span>
                </td>
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.ticketInfo.dateTransaction | date:'short'}} </span>
                </td>
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.relevance | translate}}</span>
                </td>

                <td>
                    <span *ngIf="TicketInfo.ticketInfo.department.length > 0" [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.ticketInfo.department[0].departmentName | translate}}</span>
                </td>

                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.status | translate}}</span>
                </td>

                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.ticketInfo.subjectField | translate}}</span>
                </td>

                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.timer }}</span>
                </td>

                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">
                        <button data-bs-toggle="modal" data-bs-target="#serviceDesk"
                            *ngIf="TicketInfo.ticketInfo.serviceDeskTicket == null" class="btn">
                            <i class="feather icon-plus-circle "></i>
                        </button>
                        <span *ngIf="TicketInfo.ticketInfo.serviceDeskTicket != null"
                            [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.ticketInfo.serviceDeskTicket}}</span>
                    </span>
                </td>

            </tr>
            <tr *ngIf="existsBrandSetup" class="p-selectable-row">
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)"> {{TicketInfo.ticketInfo.ticketNumber | translate}} </span>
                </td>
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.ticketInfo.idPlayer}}</span>
                </td>
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.ticketInfo.dateTransaction | date:'short'}} </span>
                </td>
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.subject | translate}}</span>
                </td>
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.ticketInfo.subjectField | translate}}</span>
                </td>
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.relevance | translate}}</span>
                </td>
                <td>
                    <span *ngIf="TicketInfo.ticketInfo.department.length > 0" [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.ticketInfo.department[0].departmentName | translate}}</span>
                </td>
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.status | translate}}</span>
                </td>
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.timer }}</span>
                </td>
                <td>
                    <span [ngClass]="getStatusType(TicketInfo.status)">
                        <button data-bs-toggle="modal" data-bs-target="#serviceDesk"
                            *ngIf="TicketInfo.ticketInfo.serviceDeskTicket == null" class="btn">
                            <i class="feather icon-plus-circle "></i>
                        </button>
                        <span *ngIf="TicketInfo.ticketInfo.serviceDeskTicket != null"
                            [ngClass]="getStatusType(TicketInfo.status)">{{TicketInfo.ticketInfo.serviceDeskTicket}}</span>
                    </span>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td style="padding: 200px; text-align: center;" colspan="12">{{'No ticket found' | translate}}</td>
            </tr>
        </ng-template>
    </p-table>

    <ng-container *ngIf="userLogin.idUserType != 4">
        <div class="row">
            <div *ngIf="IdSiteType != 4 && !userLogin.viewOnly" class="col-sm-6">
                <button data-bs-toggle="modal" data-bs-target="#chooseDepartment" type="button" class="btn btn-outline-warning mt-4">
                    <i class="fas fa-exchange-alt"></i> {{'SendToDepartment' | translate}}
                 </button>
            </div>
        </div>
        <div class="row g-3 mt-3">
            <form [formGroup]="userForm" class="row g-3 mt-3">
                <div class="col-sm-6">
                    <label>{{'AssignedTo' | translate}}:</label>
                    <!-- <span class="p-input-icon-right w-100">
                        <i *ngIf="IdSiteType != 4 && !userLogin.viewOnly" data-bs-toggle="modal"  data-bs-target="#assignedUser" class="feather icon-edit" style="cursor: pointer;"></i>
                        <input type="text" pInputText class="w-100" readonly="readonly" formControlName="userAssigned" id="inputEmail3" />
                    </span> -->
                    <p-dropdown
                        styleClass="d-flex w-100"
                        placeholder="{{'Select User' | translate}}"
                        [options]="userList"
                        [filter]="true"
                        (onChange)="onChangeUser($event)"
                        formControlName="userAssigned"
                        filterBy="name"
                        [autoDisplayFirst]="false"
                        optionLabel="name"
                        optionValue="name">
                        <ng-template let-option pTemplate="item">
                            <span [class]="'customer-badge status-' + option.idUser">{{option.name}}</span>
                        </ng-template>
                    </p-dropdown>

                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label>{{'GlobalForm.Subject' | translate}} </label>

                        <p-dropdown styleClass="d-flex w-100" [options]="subjectList" optionLabel="name"
                            optionValue="label" placeholder="Select a Subject" formControlName="subjectAssigned">
                            <ng-template let-selectedItem pTemplate="selectedItem">
                                {{selectedItem.name | translate}}
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label>{{'GlobalForm.Status' | translate}}:</label>
                        <p-dropdown styleClass="d-flex w-100" [options]="statusList" optionLabel="description"
                            optionValue="idStatusList" formControlName="statusAssigned">
                            <ng-template let-selectedItem pTemplate="selectedItem">
                                {{selectedItem.description | translate}}
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>

                <div class="col-sm-6">
                    <div class="form-group">
                        <label>{{'GlobalForm.Relevance' | translate}} </label>
                        <p-dropdown styleClass="d-flex w-100" [options]="relevanceList" optionLabel="description"
                            optionValue="idRelevance" formControlName="ticketRelevance">
                            <ng-template let-selectedItem pTemplate="selectedItem">
                                {{selectedItem.description | translate}}
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>

                <ng-container *ngIf="userLogin.idUserType == 1 && IdSiteType != 4 && !userLogin.viewOnly">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label>{{'GlobalForm.Center' | translate}} </label>
                            <p-dropdown styleClass="d-flex w-100" [options]="ticketCenter" optionLabel="label"
                                optionValue="value" formControlName="ticketIdSiteType">
                                <ng-template let-selectedItem pTemplate="selectedItem">
                                    {{selectedItem.label | translate}}
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                </ng-container>


                <div class="col-sm-12 text-center">
                    <div *ngIf="!userLogin.viewOnly" class="form-group">
                        <button (click)="onSaveTicketChanges()" type="button" class="btn btn-outline-secondary mt-4">
                            <i class="feather icon-save "></i>
                            {{'Save' | translate}}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
    <dd class="m-t-15 m-b-15"><b>{{'TicketSays' | translate}}</b></dd>
    <div *ngIf="ticketMessagesList"
        [innerHTML]="ticketMessagesList[this.ticketMessagesList.length-1].message"></div>
</p-card>

<p-card class="card mt-3" header="{{'USER.TICKER.MESSAGES' | translate}}" subheader=""
    [style]="{ width: 'auto', padding: '0' }">
    <div class="row hovered py-5 reply-player-{{col.sendToPlayer}}" *ngFor="let col of ticketMessagesList">
        <div class="col-sm-auto">
            <img width="50px" height="50px" src="assets/images/userProfile.jpg" alt="user image" class="rounded-circle">
        </div>

        <div class="col">
            <h6 *ngIf="col.sender == 0" class="">
                <b>{{TicketInfo.ticketInfo.idPlayer}}</b>
                <span class="float-end f-13 text-muted text-small">
                    <b>{{'Date' | translate}}</b>
                    {{col.dateTransactionMessage| date:'short' }}
                </span>
            </h6>
            <h6 *ngIf="col.sender != 0" class="m-b-15">
                <b>{{col.sendingUser}}</b>
                <span class="float-end f-13 text-muted text-small">
                    <b>{{'Date' | translate}}</b>
                    {{col.dateTransactionMessage| date:'short' }}
                </span>
            </h6>

            <ng-container *ngIf="col.attachedFile != null">

                <a *ngFor="let att of col.attachedFile" href="{{environment.previousDisplay}}/{{att}}" target="_blank"
                    class="badge bg-info text-light text-decoration-none " style="font-size: 16px;" ><i class="feather icon-paperclip"></i> {{'View attached file' | translate}}</a>
            </ng-container>

            <hr style="border-color: #8CDDCD;">


            <!-- <div [innerHTML]="transform(col.message)"></div> -->
            <div [innerHTML]="col.message"></div>


            <p class="m-t-15 m-b-15 text-muted">{{TicketInfo.additionalInfo.casinoProvider}}
                {{TicketInfo.additionalInfo.casinoGameName}} {{TicketInfo.additionalInfo.casinoCategory}} </p>

            <div *ngIf="!userLogin.viewOnly" style="margin-right: 2.5em;">
                <button *ngIf="IdSiteType != 3 && TicketInfo.status != 'Closed' " (click)="openReplyOrNewModal(6, null)"
                    type="button" ngbTooltip="{{'ReplyInternally' | translate}}"
                    class="btn btn-icon btn-outline-primary float-end btn-rounded"
                    style="margin-left: 1em; border-radius: 50%; height: 42px;">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-reply-fill" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.079 11.9l4.568-3.281a.719.719 0 0 0 0-1.238L9.079 4.1A.716.716 0 0 0 8 4.719V6c-1.5 0-6 0-7 8 2.5-4.5 7-4 7-4v1.281c0 .56.606.898 1.079.62z" />
                    </svg>
                </button>

                <button *ngIf="IdSiteType == 3 && TicketInfo.status != 'Closed'" type="button"
                    ngbTooltip="{{'ReplyToAgent' | translate}}" (click)="openReplyOrNewModal(6, null)"
                    class="btn btn-icon btn-outline-primary float-end btn-rounded"
                    style="margin-left: 1em; border-radius: 50%; height: 42px;">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-reply-fill" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M9.079 11.9l4.568-3.281a.719.719 0 0 0 0-1.238L9.079 4.1A.716.716 0 0 0 8 4.719V6c-1.5 0-6 0-7 8 2.5-4.5 7-4 7-4v1.281c0 .56.606.898 1.079.62z" />
                    </svg>
                </button>

                <button *ngIf="TicketInfo.status != 'Closed' && IdSiteType == 1 || IdSiteType == 2"
                    (click)="openReplyOrNewModal(5, null)" type="button" ngbTooltip="{{'ReplyToClient' | translate}}"
                    class="btn btn-icon btn-outline-secondary float-end btn-rounded"
                    style="margin-left: 1em; border-radius: 50%; height: 42px;"><svg width="1em" height="1em"
                        viewBox="0 0 16 16" class="bi bi-reply-all-fill" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M8.079 11.9l4.568-3.281a.719.719 0 0 0 0-1.238L8.079 4.1A.716.716 0 0 0 7 4.719V6c-1.5 0-6 0-7 8 2.5-4.5 7-4 7-4v1.281c0 .56.606.898 1.079.62z" />
                        <path fill-rule="evenodd"
                            d="M10.868 4.293a.5.5 0 0 1 .7-.106l3.993 2.94a1.147 1.147 0 0 1 0 1.946l-3.994 2.94a.5.5 0 0 1-.593-.805l4.012-2.954a.493.493 0 0 1 .042-.028.147.147 0 0 0 0-.252.496.496 0 0 1-.042-.028l-4.012-2.954a.5.5 0 0 1-.106-.699z" />
                    </svg>
                </button>

                <button
                    *ngIf="TicketInfo.status != 'Closed' && col.sendingUser == userLogin.name || col.sendingUser == userLogin.nickname "
                    type="button" (click)="openReplyOrNewModal(7, col)" ngbTooltip="{{'EditMessage' | translate}}"
                    class="btn btn-icon btn-outline-primary float-end"
                    style="margin-left: 1em; border-radius: 50%; height: 42px;">
                    <i class="feather icon-edit "></i>
                </button>
            </div>
        </div>
    </div>
</p-card>



<div class="modal fade" id="assignedUser" tabindex="-1" aria-labelledby="assignedUserLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog modal-dialog-centered modal-search">
        <div class="modal-content">
            <div class="modal-header px-3 py-2">
                <h5 class="modal-title fw-bold w-100 text-center" id="assignedUserLabel">{{'Assigned user' | translate}}
                </h5>
                <a type="button" class="modal-title px-0" data-bs-dismiss="modal" aria-label="Close"> <i
                        class="fas fa-times"></i> </a>
            </div>
            <form>
                <div class="modal-body pt-0">
                    <div class="form-group row g-3">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">{{'UserName' | translate}}:</label>
                        <div class="col-sm-8">
                            <ng-container *ngIf="domainInfo.idSite != 992 && userLogin.idDepartment != 55">

                                <!-- <p-dropdown styleClass="d-flex w-100" [options]="userList" optionLabel="name"
                                    optionValue="idUser" placeholder="{{'Select User' | translate}}" dataKey="idUser"
                                    (onChange)="onChange($event)" [ngModelOptions]="{standalone: true}"
                                    [(ngModel)]="userSelected">
                                </p-dropdown> -->
                            </ng-container>
                            <ng-container *ngIf="domainInfo.idSite == 992 || userLogin.idDepartment == 55">
                                <p-dropdown styleClass="d-flex w-100" [options]="userList"
                                    formControlName="userAssigned" placeholder="Select User"
                                    optionLabel="userLogin.nickname"></p-dropdown>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="modal-footer form-group text-center">
                    <button type="button" class="btn btn-secondary mr-2" data-bs-dismiss="modal">{{'Close' | translate}}</button>
                    <!-- <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="onSaveAssignedUser();"><i class="feather icon-save"></i> {{'Save'| translate}}</button> -->
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" id="chooseDepartment" tabindex="-1" aria-labelledby="chooseDepartmentLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog modal-dialog-centered modal-search">
        <div class="modal-content">
            <div class="modal-header px-3 py-2">
                <h5 class="modal-title fw-bold w-100 text-center" id="chooseDepartmentLabel">{{'Send to Department' | translate}}</h5>
                <a type="button" class="modal-title px-0" data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times"></i> </a>
            </div>
            <form>
                <div class="modal-body pt-0">
                    <div class="row g-3">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <ng-container *ngIf="!existsBrandSetup">
                                    <p-dropdown styleClass="d-flex w-100" [options]="departmentList" [filter]="true"
                                        filterBy="name" [showClear]="true" optionLabel="name"
                                        optionValue="idDepartment" placeholder="Choose deparment:"
                                        dataKey="idDepartment" (onChange)="changeDepartment($event)"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="userSelected">
                                    </p-dropdown>
                                </ng-container>
                                <ng-container *ngIf="existsBrandSetup">
                                    <p-dropdown styleClass="d-flex w-100" [options]="departmentList" [filter]="true"
                                        filterBy="name" [showClear]="true" optionLabel="name"
                                        optionValue="idDepartment" placeholder="Select Department"
                                        dataKey="idDepartment" (onChange)="changeDepartment($event)"
                                        [ngModelOptions]="{standalone: true}" [(ngModel)]="userSelected">
                                    </p-dropdown>
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                                <input type="text" placeholder="CC:" pInputText class="w-100" [(ngModel)]="ccInputText"
                                    [ngModelOptions]="{standalone: true}" id="inputEmail3" />
                            </div>
                        </div>
                        <small *ngIf="departmentSelected != 0" class="text-center mb-10">{{'Ticket' | translate}}
                            #{{TicketInfo.ticketInfo.TicketNumber}} {{'will now be assigned' | translate}}</small>
                    </div>
                </div>
                <div class="modal-footer form-group text-center">
                    <button type="button" class="btn btn-secondary mr-2" data-bs-dismiss="modal">{{'Close' |
                        translate}}</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        (click)="onChangeDepartmentAssigned();"><i class="feather icon-save"></i> {{'Save'|
                        translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>


<div class="modal fade" id="serviceDesk" tabindex="-1" aria-labelledby="serviceDeskLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog modal-dialog-centered modal-search">
        <div class="modal-content">
            <div class="modal-header px-3 py-2">
                <h5 class="modal-title fw-bold w-100 text-center" id="serviceDeskLabel">Service Desk - Ticket Number
                </h5>
                <a type="button" class="modal-title px-0" data-bs-dismiss="modal" aria-label="Close"> <i
                        class="fas fa-times"></i> </a>
            </div>
            <form>
                <div class="modal-body pt-4">
                    <div class="form-group row g-3">
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Ticket number - Service Desk </label>
                                <input type="text" pInputText class="w-100" [(ngModel)]="serviceDeskInput"
                                    [ngModelOptions]="{standalone: true}" id="inputEmail3" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer form-group text-center">
                    <button type="button" class="btn btn-secondary mr-2" data-bs-dismiss="modal">{{ 'Close' | translate
                        }}</button>
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        (click)="onSaveTicketServiceDesk();"><i class="feather icon-save"></i> {{'Save'|
                        translate}}</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div class="modal fade" data-bs-backdrop="static" id="replyToClientOrNewMessage" tabindex="-1" aria-labelledby="replyToClientOrNewMessageLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header p-0 d-flex align-items-start">
                <h5 class="modal-title fw-bold w-100 text-center pt-3" id="replyToClientOrNewMessageLabel">
                    <span *ngIf="isReplyToClientOrNewMessage == 5">{{'New Message' | translate}}</span>
                    <span *ngIf="isReplyToClientOrNewMessage == 6">{{'New Internal Message' | translate}}</span>
                    <span *ngIf="isReplyToClientOrNewMessage == 7">{{'Edit Message' | translate}}</span>
                </h5>
                <div class="d-flex rounded btn-modal-action-header">
                    <a (click)="minimizeModal()" type="button" class="px-2 fs-5 text-decoration-none" data-bs-dismiss="modal"
                    aria-label="Close"> <i class="fas fa-minus-circle"></i> </a>
                    <a (click)="closeModal()" type="button" class="px-2 fs-5 text-decoration-none" data-bs-dismiss="modal"
                        aria-label="Close"> <i class="fas fa-times"></i> </a>
                </div>
            </div>
            <form>
                <div class="modal-body pt-4" *ngIf="isReplyToClientOrNewMessage !== 0">
                    <div *ngIf="showTicketForm">
                        <app-ticket-form
                          [TicketInfo]="TicketInfo"
                          [editItemSelected]="editItemSelected"
                          [IdSiteType]="IdSiteType"
                          [formType]="isReplyToClientOrNewMessage"
                          [shortCutByDepartment]="shortCutByDepartment"
                          (onCallFormMethod)="onCallFormMethod($event)">
                        </app-ticket-form>
                      </div>
                </div>
            </form>
        </div>
    </div>
</div>

<button id="reopenButton" class="btn btn-primary reopen-button" (click)="reopenModal()">
    <i class="fas fa-window-maximize"></i> Open
</button>
