import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ResponseData } from '@models/models';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private _http: HttpClient, private _route: Router, private _cookieService: CookieService) { }

  apiPostMethod(URL: string, body: any): Observable<ResponseData> {
    return this._http.post<ResponseData>(URL, body);
  }

  apiGetMethod(URL: string): Observable<ResponseData> {
    return this._http.get<ResponseData>(URL);
  }

  getRouterLink() {
    return localStorage.getItem('route');
  }

  setRouterLink(router: any) {
    localStorage.setItem('route', router);
  }

  setPermissionByMenu(permission: any) {
    localStorage.setItem('permissionByMenu', permission);
  }

  changedetected(isDetected: any) {
    localStorage.setItem('isDetected', isDetected);
  }

  getDetectionChanges() {
    return localStorage.getItem('isDetected');
  }

  logout() {
    if (document.body) {
      document.body.classList.remove(localStorage.getItem('theme')!);
    }
    const hasRefreshed = sessionStorage.getItem('hasRefreshed');
    sessionStorage.clear();
    if(hasRefreshed !== null){
        sessionStorage.setItem('hasRefreshed', hasRefreshed);
    }
    const storage = localStorage.getItem('isSmartLogin');
    if (storage == 'false') {
        this._cookieService.deleteAll();
        localStorage.clear();
    }

    this._route.navigate(['/login']);
  }
}
