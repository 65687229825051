import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ThemesOptions } from '@methods/constants';
import { GetMethods, Methods, PostMethods, PutMethods, getSmartLoginTimeOut } from '@methods/methods';
import { User } from '@models/auth-model';
import { ConfigByDomain, Filters, ResponseData } from '@models/models';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { NotificationService } from '@services/notification.service';
import { ThemeService } from '@services/theme.service';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html'
})
export class ConfigurationComponent implements OnInit {
    smartLoginForm: FormGroup;
    userLogin: User;
    domainInfo: ConfigByDomain;
    idUser: any;
    themeList: any = Filters;
    themeSelected: any = '';

    get f() { return this.smartLoginForm.controls; }

    constructor(private _formBuilder: FormBuilder,
        private _apiService: ApiService,
        private _notificationService: NotificationService,
        private _themeService: ThemeService,
        private _title: Title,
        private _translateService: TranslateService,
        private _cookieService: CookieService) {
        this.themeList = ThemesOptions;

        this._title.setTitle(`${this.TranslateText('/configuration')} | CTS `);
    }

    ngOnInit(): void {
        this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
        this.domainInfo =  JSON.parse(localStorage.getItem('DomainCTS')!);
        this.idUser = localStorage.getItem('IdUser');
        this.themeSelected = this._themeService.getThemeSelected();
        this.smartLoginForm = this._formBuilder.group({
            isSmartLogin: ['', [Validators.required]]
        });
        if(this.userLogin){
            this.getSamrtLogin();
            this.getUserPreference();
        }
    }

    getSamrtLogin() {
        const URL = `${environment.WebApiUrl + GetMethods.GET_SMART_LOGIN_SELECTION + parseInt(this.idUser)}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    // this._cookieService.set('isSmartLogin', result.data.smartLogin)
                    localStorage.setItem('isSmartLogin', result.data.smartLogin);
                    this.setCookiesSession(result.data.smartLogin);
                    this.smartLoginForm = this._formBuilder.group({
                        isSmartLogin: [result.data.smartLogin, [Validators.required]]
                    });
                }
            },
            error: (err: any) => {
                console.log(err);
            }
        });
    }

    getUserPreference(){
        const URL = `${environment.WebApiUrl + GetMethods.GET_USER_PREFERENCE + this.userLogin.idUser + '&IdSite=' + this.domainInfo.idSite }`;
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if(result.success){
                    if(result.data.theme != null){
                        this._themeService.setTheme(result.data.theme);
                    }
                }
            },
            error: (err: any) => {
              console.log(err);
            }
        });
    }

    setCookiesSession(isSmart: boolean) {
        if (isSmart) {
            this._cookieService.set("IdUser", this.userLogin.idUser.toString(), getSmartLoginTimeOut());
            // this._cookieService.set("isSmartLogin", 'true', getSmartLoginTimeOut());
            this._cookieService.set('token', this.getTokenEncoded(), getSmartLoginTimeOut());
            localStorage.setItem('isSmartLogin', 'true');

        } else {
            this._cookieService.set('token', this.getTokenEncoded());
            this._cookieService.set('IdUser', this.userLogin.idUser.toString());
        }
    }

    getTokenEncoded() {
        return btoa(this.userLogin.idUser + ":" + this.userLogin.token + ":" + this.userLogin.adminType);
    }

    TranslateText(text: string) {
        return this._translateService.instant(text);
    }

    onSetTheme(item) {
        this._themeService.setTheme(item.value);
        const data = {
            idUser: this.userLogin.idUser,
            idSite: this.domainInfo.idSite,
            theme: item.value
        }
        const URL = `${environment.WebApiUrl + PostMethods.POST_INSERT_USER_PREFERENCE }`;
        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
              if(result.success){
                this.getUserPreference();
              }
            },
            error: (err: any) => {
              console.log(err);
            }
        });

        this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
    }

    onSubmit() {
        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_SMART_LOGIN}`;
        const data = {
            idUser: parseInt(this.idUser),
            smartSelection: this.f.isSmartLogin.value,
        }
        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.getSamrtLogin();
                    this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                }else {
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
                console.log(err);
            }
        });
    }
}
