import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DeleteMethods, GetMethods, Methods, getStatusType } from '@methods/methods';
import { ResponseData, User } from '@models/models';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { MediaResponse, MediaService } from '@services/media.service';
import { NotificationService } from '@services/notification.service';
import { ThemeService } from '@services/theme.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shortcut-desk-filter',
  templateUrl: './shortcut-desk-filter.component.html'
})
export class ShortcutDeskFilterComponent implements OnInit{
  @Input() title: string;
  @Input() tabPanelData: any;
  @Input() columsData: any;
  @Input() payloadData: any;
  @Input() loading: boolean;
  @Input() identity: number;
  @Input() formTicket: any;
  @Input() statusList: any;
  @Input() showHardReloadBtn: boolean;
  @Input() deletePostEvent: number;
  @Input() userList: any;
  @Input() formValidation: any;
  @Input() formType: number;
  @Input() departmentList: any;
  @Input() editItemSelected: any;
  @Output() onCallGetInfo = new EventEmitter<any>();

  mediaSubscription: Subscription;
  Media: MediaResponse;
  showExtraInfo: boolean = false;
  showNewFormEntry: boolean = false;
  showExtraInfoForm: boolean = false;
  userLogin: User;
  commentList: any;
  showCommentList: boolean = false;
  showNewComment: boolean = false;
  itemSelectedToBeDeleted: any;
  isDarkTheme: boolean;

  constructor(private _translateService: TranslateService, private _mediaService: MediaService, private _apiService: ApiService, private _notificationService: NotificationService, private _sanitizer:DomSanitizer, private _themeService: ThemeService){
    this.mediaSubscription = this._mediaService.subscribeMedia().subscribe(media => {
      this.Media = media;
    });
  }

  ngOnInit(): void {
    this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
    this.isDarkTheme = this._themeService.getThemeSelected() == 'theme-default-dark' ? true : false;
  }

  getStatusType(status:string){
    return getStatusType(status, this.isDarkTheme)
  }

  getTabSelected(event:any){
    if(this.showExtraInfoForm){
        this.showExtraInfoForm = !this.showExtraInfoForm;
    }
    this.showNewFormEntry = ( event == 1 ) ? true: false;
    this.showExtraInfo = false;
    this.showCommentList = false;
  }

  editItem(item:any){
    this.showExtraInfo = true;
    item.idStatus = this.statusList.find(x => x.description == item.status)?.idStatusList;
    this.editItemSelected = item;
    this.editItemSelected.idTicket = (this.identity == 1) ? this.editItemSelected.idNote : this.editItemSelected.idShortcut;
    this.editItemSelected.date = (this.identity == 1) ? this.editItemSelected.date  : this.editItemSelected.dateCreated;
    this.editItemSelected.title = (this.identity == 1) ? 'SDK.View and Edit Task' : 'SC.ViewShortcut';
    this.showNewFormEntry = true;
    if(this.identity == 1){
      this.getCommentNotes(item.idNote);
    }else{
      this.editItemSelected.inputTextName = this.editItemSelected.name;
      this.editItemSelected.idDepartment = this.editItemSelected.idDepartment;
      this.editItemSelected.description = this.editItemSelected.description;
    }
  }

  editTaskForm(){
    this.showExtraInfoForm = !this.showExtraInfoForm;
  }

  addNewCommentEvent(){
    this.showNewComment = !this.showNewComment;
  }

  getCommentNotes(idComment: number){
    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_TASK_NOTE_MESSAGE + idComment }`;
    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
            this.showCommentList = ( result.data.length > 0 ) ? true: false;
            this.commentList = result.data;
        }else{
            this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
      },
      error: (err: any) => {
        console.log(err);
        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
      }
    });
  }

  transform(v:string):SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }

  deleteItem(item:any){
    this.itemSelectedToBeDeleted = item;
    Swal.fire({
      text: "Are you sure?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        const URL = `${environment.WebApiUrl + this.returnUrlParameter(this.deletePostEvent) }`;
        this._apiService.apiPostMethod(URL, item).subscribe({
          next: (result: ResponseData) => {
            if (result.success) {
              this.onCallGetInfo.emit(true);
              this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
            }else{
              this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
          },
          error: (err: any) => {
            console.log(err);
            this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
          }
        });
      }
    })
  }

  returnUrlParameter(idEvent: number){
    switch (idEvent) {
      case 3: return DeleteMethods.DELETE_TASK_NOTE + '?IdNote=' + this.itemSelectedToBeDeleted.idNote;
      case 11: return DeleteMethods.DELETE_SHORTCUT + '?IdShortcut=' + this.itemSelectedToBeDeleted.idShortcut;
      default: return null;
    }
  }

  onCallFormMethod(event:any){
    if(this.editItemSelected){
      if (this.formType == 9) {
        this.editItemSelected.message = event.message
        this.editItemSelected.departmentName = event.departmentName;
        this.editItemSelected.description = event.description;
        this.editItemSelected.name = event.name;
      }

      if(this.formType == 8){
        this.editItemSelected.departmentName = event.departmentName;
        this.editItemSelected.emailSubject = event.emailSubject;
        this.editItemSelected.status = event.status;
        this.editItemSelected.tickerOrTaskName = event.tickerOrTaskName;
        this.getCommentNotes(this.editItemSelected.idTicket);
        this.onCallGetInfo.emit(true);
      }

      if(this.formType == 12){
        this.editItemSelected.message = event.message
        this.editItemSelected.departmentName = event.departmentName;
        this.editItemSelected.description = event.description;
        this.editItemSelected.name = event.name;
        this.onCallGetInfo.emit(true);
      }

    }else{
      this.onCallGetInfo.emit(true);
    }
  }

  hardRefresh(){
    location.reload();
  }

  TranslateText(text: string) {
    return this._translateService.instant(text);
  }
}
