<p-card *ngIf="!showExtraInfo" class="card" header="{{ 'Personal Worklog' | translate}}" subheader="" [style]="{ width: 'auto', padding: '0' }">
    <p-table #dt
        [value]="payload"
        [columns]="columsData"
        [scrollable]="true" 
        scrollHeight="700px" 
        dataKey="id"
        styleClass="p-datatable-striped"
        [rowHover]="true" 
        [rows]="20" 
        [rowsPerPageOptions]="[20,40,60]"
        [showCurrentPageReport]="true" 
        [loading]="loading" 
        [paginator]="true" 
        responsiveLayout="stack" 
        [tableStyle]="{'min-width': '50rem'}"
        currentPageReportTemplate="{{'Showing {first} to {last} of {totalRecords} entries' | translate}}" 
        [filterDelay]="0"
        [globalFilterFields]="['issue', 'account', 'sourceInfo.name']">
        <ng-template pTemplate="caption">
            <div class="row mt-3">
                <div class="col-2 col-md-1" *ngIf="user.idUserType == 1 || user.idUserType == 3">
                    <button ngbTooltip="{{'Refresh' | translate}}" (click)="getGlobalInfo(0, false)" type="button" class="btn btn-secondary">
                        <i class="feather icon-refresh-cw "></i>                   
                    </button>
                </div>
                <div class="col-2 col-md-1" *ngIf="user.idUserType == 1 || user.idUserType == 3">
                    <button ngbTooltip="{{'Find Users by department' | translate}}" (click)="getGlobalInfo(4, false)" type="button" class="btn btn-warning">
                        <i class="feather icon-folder"></i>                    
                    </button>
                </div>
                <div class="col-2 col-md-1" *ngIf="user.idUserType == 1">
                    <button ngbTooltip="{{'Change Report by Department' | translate}}" (click)="getGlobalInfo(4, true)" type="button" class="btn btn-info">
                        <i class="feather icon-briefcase"></i>                    
                    </button>
                </div>

                <div class="col-2 col-md-1" *ngIf="user.idUserType == 1 || user.idUserType == 3">
                    <!-- <button ngbTooltip="Export Excel" (click)="generateExcel()" type="button" class="btn btn-success">
                        <i>
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                width="20" height="20"
                                viewBox="0 0 50 50"
                                style=" fill:#ffffff;">
                                <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z"></path>
                            </svg>
                        </i>                      
                    </button> -->
                    <ng-container *ngIf="payload">
                        <button [disabled]="payload.length == 0" class="btn btn-danger" ngbTooltip="{{'Download As' | translate}}" (click)="downloadModal()"> <i class="feather icon-download"></i></button>
                    </ng-container>
                </div>

                <div *ngIf="keyFilterId != 4 && keyFilterId != 6 && keyFilterId != 7" class="col-2 col-md-1">
                    <button ngbTooltip="{{'Add new request' | translate}}" (click)="showRegister = !showRegister" type="button" class="btn btn-primary">
                        <i class="feather icon-plus"></i>                    
                    </button>
                </div>
            </div>
            <div *ngIf="showFilter" class="mt-3 py-4 p-component text-center custom-bg">
                <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"><i class="feather icon-filter"></i>  {{'Select the user you want to view' | translate}}</button>
                <button class="ms-3 btn btn-secondary" (click)="getGlobalInfo(0, false)" type="button"><i class="feather icon-x"></i>  {{'Cancel' | translate}}</button>
            </div>
            <div *ngIf="showRegister" class="p-component custom-bg">
                <form [formGroup]="formTicket" class="row mt-3 p-4 d-flex align-items-center" [ngClass]="{'g-2': Media.IsMobile}" (ngSubmit)="insertNewEvent(9)" >
                    <div class="col-12 col-md">{{ currentDate | date:'medium'}}</div>
                    <div class="col-12 col-md">
                        <input [ngClass]="{ 'is-invalid invalid': submitted && f.Account.errors }" pInputText formControlName="Account" type="text" class="w-100" name="Account" placeholder="{{'Account' | translate}}"> 
                        <div *ngIf="submitted && f.Account.errors" class="invalid-feedback">
                            <div *ngIf="f.Account.errors.required">{{'Account' | translate}} {{'is required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 col-md">
                        <p-dropdown
                            styleClass="d-flex w-100" 
                            [options]="listIssues" 
                            placeholder="{{'Request' | translate}}"
                            formControlName="Issue"
                            optionLabel="issue"
                            [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.Issue.errors }"
                            optionValue="issue">
                            <ng-template let-option pTemplate="item">
                                <span [class]="'customer-badge status-' + option.issue">{{option.issue | translate}}</span>
                            </ng-template>
                        </p-dropdown>
                        <div *ngIf="submitted && f.Issue.errors" class="invalid-feedback">
                            <div *ngIf="f.Issue.errors.required">{{'Issue' | translate}} {{'is required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 col-md">
                        <p-dropdown 
                            styleClass="d-flex w-100" 
                            [options]="listSource" 
                            placeholder="{{'Source' | translate}}"
                            formControlName="Source"
                            optionLabel="name"
                            [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.Source.errors }"
                            optionValue="idSource">
                            <ng-template let-option pTemplate="item">
                                <span [class]="'customer-badge status-' + option.idSource">{{option.name | translate}}</span>
                            </ng-template>
                        </p-dropdown>
                        <div *ngIf="submitted && f.Source.errors" class="invalid-feedback">
                            <div *ngIf="f.Source.errors.required">{{'Source' | translate}} {{'is required' | translate}}</div>
                        </div>
                    </div>
                    <div class="col-12 col-md text-center"> {{userLogin.name}} </div>
                    <div class="col-12 col-md text-center"> 
                        <a class="btn btn-info" type="button" (click)="insertNewEvent(9)">
                            {{'Save' | translate}}
                            <i class="feather icon-save"></i>
                        </a>
                    </div>
                </form>
            </div>
            <div class="row g-2 my-1">
                <div class="col-12 col-sm-12 col-md">
                    <p-calendar [showClear]="true" placeholder="{{'Start Date' | translate}}" [(ngModel)]="date1" [maxDate]="currentDate" (ngModelChange)="getGlobalInfo(8, false)" class="datePickTC"  dateFormat="d/m/yy" styleClass="w-100"></p-calendar>
                </div>
                <div class="col-12 col-sm-12 col-md">
                    <p-calendar placeholder="{{'End Date' | translate}}" [(ngModel)]="date2" [maxDate]="currentDate" class="datePickTC" (ngModelChange)="getGlobalInfo(8, false)" dateFormat="d/m/yy" styleClass="w-100"></p-calendar>
                </div>
                <div class="col-12 col-sm-12 col-md">
                    <input pInputText type="text" (input)="dt.filter($any($event.target) .value, 'account', 'contains')" placeholder="{{'Account' | translate}}" class="w-100 h-100">
                </div>
                <div class="col-12 col-sm-12 col-md">
                    <p-dropdown (onChange)="dt.filter($event.value, 'issue', 'equals')"
                        styleClass="d-flex w-100" 
                        [options]="listIssues" 
                        [showClear]="true" 
                        placeholder="{{'Request' | translate}}"
                        optionLabel="issue"
                        optionValue="issue">
                    </p-dropdown>
                </div>
                <div class="col-12 col-sm-12 col-md">
                    <p-dropdown (onChange)="dt.filter($event.value, 'sourceInfo.name', 'equals')"
                        styleClass="d-flex w-100" 
                        [options]="listSource" 
                        [showClear]="true" 
                        placeholder="{{'Source' | translate}}"
                        optionLabel="name"
                        optionValue="name">
                    </p-dropdown>
                </div>
            </div>
        </ng-template>

        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="text-uppercase" *ngFor="let col of columns">
                    {{ col.header | translate }}
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-payloadData>
            <tr class="p-selectable-row">
                <td>{{ payloadData.transactionDate | date:'medium' }}</td>
                <td>{{ payloadData.account }}</td>
                <td>{{ payloadData.issue | translate}}</td>
                <td>{{ payloadData.sourceInfo.name }}</td>
                <td *ngIf="!showFilter">{{ userLogin.name }}</td>
                <td>
                    <a class="btn btn-icon p-0" (click)="watchCommentItem(payloadData);" data-bs-toggle="modal" data-bs-target="#commentModal" type="button">
                        <i [ngClass]="{'far fa-comment-dots': payloadData.comment == '', 'feather icon-eye': payloadData.comment != '' }"></i>
                    </a>
                </td>
            </tr>
        </ng-template>
        
        <ng-template pTemplate="emptymessage">
            <tr>
                <td style="padding: 200px; text-align: center;" colspan="12">
                   
                    <span class="p-3 bg-warning p-component" *ngIf="!showHardReloadBtn"> <i class="feather icon-alert-circle"></i> {{ 'GENERICS.NO_DATA_FOUND' | translate }} </span>
                    <span *ngIf="showHardReloadBtn"> {{'GENERICS.UPDATED_ERROR' | translate}} <a class="btn btn-danger" (click)="hardRefresh()"> <i class="feather icon-refresh-cw"></i> {{ 'Refresh' | translate }}</a> </span>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
          <h5 id="offcanvasRightLabel">{{'Choose a User' | translate}}</h5>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="mx-3">
            <ng-container *ngIf="showDepartmentFilter">
                <p-dropdown (onChange)="departmentSelected($event.value)"
                    styleClass="d-flex w-100" 
                    [options]="departmentAllList" 
                    placeholder="{{'Select department' | translate}}"
                    optionLabel="Name"
                    optionValue="IdDepartment">
                </p-dropdown>
            </ng-container>
        </div>
        <div class="offcanvas-body">
            <div class="row g-0 d-flex align-items-center p-3 border-top" *ngFor="let item of userList">
                <div class="col-2 col-sm-1">
                    <label class="container-custom">
                        <input type="checkbox" data-bs-dismiss="offcanvas" [checked]="itemUserSelected?.idUser == item.idUser" (click)="searchUserLog(item)">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="col-8 col-sm-9">
                    <span class="sub-desc">{{item.name}}</span>
                </div>
            </div>
        </div>
      </div>
</p-card>

<div class="modal fade" id="commentModal" tabindex="-1" role="dialog" aria-labelledby="commentModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div *ngIf="itemCommentSelected" class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" *ngIf="itemCommentSelected.comment != ''" id="commentModal">{{'Comment' | translate}}</h5>
                <h5 class="modal-title" *ngIf="itemCommentSelected.comment == ''" id="commentModal">{{'New Comment' | translate}}</h5>
                <a type="button" class="modal-title px-0" data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times"></i> </a>
            </div>
            <div class="modal-body">
                <p *ngIf="itemCommentSelected.comment != ''">{{itemCommentSelected.comment}}</p>
                <textarea *ngIf="itemCommentSelected.comment == ''" name="" id="" class="w-100" cols="30" rows="10" [(ngModel)]="newComment"></textarea>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ 'Close' | translate }}</button>
                <button *ngIf="itemCommentSelected.comment == ''" type="button" class="btn btn-primary" (click)="insertNewEvent(11)" >Save</button>
            </div>
        </div>
    </div>
  </div>

<div class="modal fade" id="downloadAs" tabindex="-1" aria-labelledby="downloadAsLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="downloadAsLabel">{{ 'Download As' | translate }}</h5>
          <a type="button" class="modal-title px-0" data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times"></i> </a>
        </div>
        <div class="modal-body">
            <ng-container>
                <app-export-as [payloadData]="payload" [exportType]="6" [title]="'HEADER_REPORT.PERSONAL_WORKLOG'" [exportColumns]="exportColumns" [pdfPayloadData]="infoData"></app-export-as>
            </ng-container>
        </div>
      </div>
    </div>
</div>