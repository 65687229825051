import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeleteMethods, GetMethods, Methods, PostMethods, PutMethods } from '@methods/methods';
import { ResponseData } from '@models/models';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { MediaResponse, MediaService } from '@services/media.service';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html'
})
export class EditProfileComponent {

  @Input() itemSelected: any;
  @Input() tabSelected: number;
  @Input() allChecked: boolean;
  @Input() allSiteTags: any;

  @Output() onGetOutputData = new EventEmitter<number>();

  mediaSubscription: Subscription;
  Media: MediaResponse;
  sortView: boolean = false;
  showSubjectCheckbox: boolean = false;
  allChecked2: boolean = false;
  siteTagSelected: any;
  messageResult: string = ''

  constructor(private _mediaService: MediaService, private _apiService: ApiService, private _notificationService: NotificationService, private _translateService: TranslateService){
    this.mediaSubscription = this._mediaService.subscribeMedia().subscribe(media => {
      this.Media = media;
    });
  }

  getSubjectFieldById(item:any) {
    const URL = `${environment.WebApiUrl + GetMethods.GET_SUBJECT_FIELD_BY_ID + item.idSubject }`;
    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        result.data.forEach(element => {
          if(this.tabSelected == 3){
            element.isActive = false;
          }
          element.description = element.name;
          element.active = false;
        });
        this.showSubjectCheckbox = true;
        this.itemSelected.accessSite = result.data;
        if(this.itemSelected.field.length > 0){
          this.itemSelected.field.forEach(element => {
            this.itemSelected.accessSite.forEach(value => {
              if(element.idSubjectField == value.idSubjectField){
                value.active = element.active
                value.isActive = element.isActive
              }
            });
          });
        }
      },
      error: (err: any) => {
        console.log(err);
        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
      }
    });
  }

  addSite(key:number) {
    var find = this.allSiteTags.find(c => c.idSiteTag == this.siteTagSelected);
    if (find != null) {
      const found = this.buscarObjetoPorId(this.itemSelected.accessSite, this.siteTagSelected);
      if (found) {
        this.getMessageInfo('GENERICS.ALREADY_EXIST', 3000);
      } else {
        find.idSite = find.idSiteTag
        this.itemSelected.accessSite.push(find);
        if( key == 0){
          this.OnAddNewInfo(key);
        }
      }
    }
  }

  buscarObjetoPorId(array, id) {
    return array.find(value => value.idSiteTag === id);
  }

  getSubjectFieldByIdWithoutCall(item:any){
    this.itemSelected.accessSite = [];
    this.itemSelected.field.forEach(element => {
      if(element.idSubject == item.idSubject){
        element.description = element.name;
        element.active = element.isActive;
        this.itemSelected.accessSite.push(element)
      }
    });

    var allChecked2 = this.itemSelected.accessSite.find(x => x.isActive == false);
    this.allChecked2 = ( allChecked2 == null  || allChecked2 == undefined) ? true : false;

    this.showSubjectCheckbox = true;
  }

  selectAllCheckbox(event:any, key){
    if(key == 1){
      this.itemSelected.payload.forEach(element => {
        if(this.tabSelected == 0 || this.tabSelected == 1){
          element.activeForProfile = event.checked;
          element.idComponent = element.idTagComponent;
          element.active = event.checked;
        }
        if(this.tabSelected == 2 || this.tabSelected == 3){
          element.isActive = event.checked;
          element.idComponent = element.idTagComponent;
          element.active = event.checked;
        }
      });
    }
    if(key == 2){
      this.itemSelected.accessSite.forEach(element => {
        element.active = event.checked;
        element.isActive = event.checked;
      });
      if (this.itemSelected.field.find(c => c.IdSubject == this.itemSelected.accessSite[0].idSubject) == null) {
        this.itemSelected.field = this.itemSelected.field.concat(this.itemSelected.accessSite);
      }
    }
  }

  updateCheckbox(event:any, item:any, key: number){
    if(key == 1){
      this.itemSelected.payload.forEach(element => {
        if (this.tabSelected == 0 || this.tabSelected == 1){
          if (element.idTagComponent == item.idTagComponent) {
            element.activeForProfile = event.checked;
            element.idComponent = element.idTagComponent;
            element.active = event.checked;
          }
        }

        if(this.tabSelected == 2 || this.tabSelected == 3 ){
          if(element.idSubject == item.idSubject){
            element.isActive = event.checked;
            element.active = event.checked;
          }
        }
      });
    }

    // verifica en el edit el segundo card con el boton de checkbox

    if(key == 2){
      if (this.itemSelected.field.find(c => c.idSubject == this.itemSelected.accessSite[0].idSubject) == null) {
        this.itemSelected.field = this.itemSelected.field.concat(this.itemSelected.accessSite);
      }
      this.itemSelected.field.forEach(element => {
        if (element.idSubjectField == item.idSubjectField) {
          element.isActive = event.checked;
          element.active = event.checked;
        }
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.itemSelected.payload, event.previousIndex, event.currentIndex);
  }

  changeViewSites(){
    this.sortView = !this.sortView;
  }

  deleteSiteForUser(item:any){
    if(this.tabSelected != 1){
      Swal.fire({
        text: "Are you sure?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            idProfile: this.itemSelected.idProfile,
            idSite: item.idSite
          }
          const URL = `${environment.WebApiUrl + this.returnUrlParameter(4) }`;
          this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
              if (result.success) {
                this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                var find = this.itemSelected.accessSite.find(c => c.idSiteTag == item.idSiteTag);
                if (find != null) {
                  var index = this.itemSelected.accessSite.indexOf(find);
                  if (index > -1) {
                    this.itemSelected.accessSite.splice(index, 1);
                  }
                }
              }else{
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
              }
            },
            error: (err: any) => {
              console.log(err);
              this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
          });
        }
      })
    }else{
      var find = this.itemSelected.accessSite.find(c => c.idSiteTag == item.idSiteTag);
      if (find != null) {
        var index = this.itemSelected.accessSite.indexOf(find);
        if (index > -1) {
          this.itemSelected.accessSite.splice(index, 1);
        }
      }
    }
  }

  OnAddNewInfo(key:number){
    if(this.itemSelected.profileName != undefined && this.itemSelected.profileName != ''){
        const data = this.onDataParams(key);
        const URL = `${environment.WebApiUrl + this.returnUrlParameter(key)} `;

        if(key == 0){
            this._apiService.apiPostMethod(URL, data).subscribe({
                next: (result: ResponseData) => {
                    if (result.success) {
                        this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                    } else {
                        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                    }
                },
                error: (err: any) => {
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                    console.log(err);
                }
            });
        }else{
            this._apiService.apiPostMethod(URL, data).subscribe({
                next: (result: ResponseData) => {
                    if(result.success){
                        this.onGetOutputData.emit(this.tabSelected);
                        this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                    }else{
                        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }
    }else{
      Swal.fire('' , this.TranslateText('GENERICS.ADD_A_PROFILE_NAME'), 'warning')
    }
  }

  onDataParams(key: number){
    switch (key) {
      case 0: return this.itemSelected; //UpdateProfile
      case 1: return {
        accessAllSites: (this.itemSelected.accessSite != undefined) ? this.itemSelected.accessAllSites: false,
        accessSite: (this.itemSelected.accessSite != undefined) ? this.itemSelected.accessSite : [],
        accessTagComponent: this.removeInvalidItems(this.itemSelected.payload),
        profileName: this.itemSelected.profileName,
        isActive: (this.itemSelected.isActive != undefined ) ? this.itemSelected.isActive : false
      };

      case 3: return {
        field : this.itemSelected.field,
        name :  this.itemSelected.profileName,
        subject: this.itemSelected.payload
      }
      default: return null;
    }
  }


  returnUrlParameter(key: number){
    switch (key) {
      case 0: return PutMethods.UPDATE_PROFILE;
      case 1: return PostMethods.POST_INSERT_NEW_PROFILE;
      case 3: return PostMethods.POST_INSERT_NEW_SITE_PROFILE;
      case 4: return DeleteMethods.DELETE_SITE_FOR_PROFILE;
      default: return null;
    }
  }

  removeInvalidItems(arr) {
    return arr.filter(item => item.activeForProfile === true);
  }

  TranslateText(text: string) {
    return this._translateService.instant(text);
  }

  getMessageInfo(message: string, time: number) {
    this.messageResult = message;
    setTimeout(() => {
      this.messageResult = '';
    }, time);
  }
}
