<div *ngIf="!isErrorActivated" class="row g-3 px-2">

    <div class="col-12 col-sm-12 col-md-6">
        <app-brand-subject-filter
            *ngIf="identity === 1"
            [title]="'BrandSetup.BrandSetups'"
            [identity]="identity"
            [payloadData]="payloadData"
            [isErrorActivated]="isErrorActivated"
            [checked]="checked"
            [modalTitleNewText]="'BrandSetup.Add New Brand'"
            [modalTitleEditText]="'BrandSetup.Edit Brand'"
            [btnNewText]="'BrandSetup.Add New Brand'"
            [newPostEvent]="1"
            [editPostEvent]="2"
            [deletePostEvent]="3"
            [selectAllCheckbox]="4"
            [headerTitle]="cardHeaderTitle1"
            [allSiteTags]="allSiteTags"
            [departmentList]="departmentList"
            [formValidation]="formValidateInputs"
            (refreshData)="refreshData($event)">
        </app-brand-subject-filter>
    </div>

    <div class="col-12 col-sm-12 col-md-6">
        <app-brand-subject-filter
            [title]="'BrandSetup.SitesByBrand'"
            [identity]="identity2"
            [payloadData]="payloadSecondData"
            [isErrorActivated]="isErrorActivated"
            [checked]="checked"
            [modalTitleNewText]="'BrandSetup.Add New Site'"
            [btnNewText]="'BrandSetup.Add New Site'"
            [newPostEvent]="1"
            [editPostEvent]="2"
            [deletePostEvent]="6"
            [selectAllCheckbox]="4"
            [addNewPostEvent]="5"
            [headerTitle]="cardHeaderTitle2"
            [filterData]="payloadData"
            [allSiteTags]="allSiteTags"
            [departmentList]="departmentList"
            [formValidation]="formValidateInputs2"
            (onFilterSiteById)="onFilterSiteById($event)"
            (refreshData)="refreshData($event)">
        </app-brand-subject-filter>
    </div>
</div>

<p-card *ngIf="isErrorActivated">
    <div class="row d-flex align-items-center">
        <div class="col-12 text-center">
            <img src="assets/images/empty-tracker-icon.png" alt="something happened">
            <span class="d-block my-3"> {{'GENERICS.UPDATED_ERROR' | translate}} 
            </span>
            <a class="btn btn-danger" (click)="hardRefresh()"> 
                <i class="feather icon-refresh-cw"></i> {{ 'Refresh' | translate }}
            </a> 
        </div>
    </div>
</p-card>