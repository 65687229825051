import { Component, OnInit } from '@angular/core';
import { ConfigByDomain } from '@models/models';
@Component({
  selector: 'app-partners-ticket-center',
  templateUrl: './partners-ticket-center.component.html'
})
export class PartnersTicketCenterComponent implements OnInit {
    domainInfo: ConfigByDomain;
    
    ngOnInit(): void {
        //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
        //Add 'implements OnInit' to the class.
        this.domainInfo =  JSON.parse(localStorage.getItem('DomainCTS')!); 
    }
}
