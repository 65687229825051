import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Filters, MatchModeOptions } from '@methods/constants';
import { GetMethods, PostMethods, PutMethods, getStatusType, transformMediumDate } from '@methods/methods';
import { User } from '@models/auth-model';
import { ConfigByDomain, ResponseData } from '@models/models';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { MediaResponse, MediaService } from '@services/media.service';
import { NotificationService } from '@services/notification.service';
import { ThemeService } from '@services/theme.service';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

declare const bootstrap: any;

@Component({
  selector: 'app-table-generic',
  templateUrl: './table-generic.component.html'
})
export class TableGenericComponent implements OnInit {
  @Input() ticketCenterType: number; /* 1 = Agent and Players Tickets, 2 = Partners Tickets, 3 = Internal Tickets, 4 = Direct Tickets*/
  @Input() payloadData: any;
  @Input() columsData: any;
  @Input() loading: any;
  @Input() allSiteTags: any;
  @Input() departmentList: any;
  @Input() subjectList: any;
  @Input() relevanceList: any;
  @Input() statusList: any;
  @Input() tabSelected: any;
  @Input() showHardReloadBtn: any;
  @Input() rowsSave: number = 20;
  @Input() existsBrandSetup: boolean = false;

  @Output() refreshData = new EventEmitter<number>();
  @Output() onTicketSelected = new EventEmitter<number>();

  mediaSubscription: Subscription;
  Media: MediaResponse;
  filteredData: any[] = [];
  exportColumns!: any[];
  @ViewChild('dt') dt: Table;
  date1: Date;
  date2: Date;
  matchModeOptions: Filters[];
  userLogin: User;
  isViewClosedTickets: boolean = false;
  showHardReload: boolean = false;
  isDarkTheme: boolean;

  domainInfo: ConfigByDomain;

  constructor( private _translateService: TranslateService, private _apiService: ApiService, private config: PrimeNGConfig, private _mediaService: MediaService, private _notificationService: NotificationService, private _themeService: ThemeService){
    this.date2 = new Date();
    this.matchModeOptions = MatchModeOptions;
    this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
    this.isDarkTheme = this._themeService.getThemeSelected() == 'theme-default-dark' ? true : false;


    this.mediaSubscription = this._mediaService.subscribeMedia().subscribe(media => {
      this.Media = media;
    });
  }

  ngOnInit(): void {

    setTimeout(() => {
      this.matchModeOptions.forEach(e => {
        this.TranslateText(e.label);
      })
    }, 20000);
    this.setRowsPerPage();
    //this.matchModeOptions.map(x => this._translateService.instant(x.label))
    this.domainInfo = JSON.parse(localStorage.getItem('DomainCTS')!);

  }

setRowsPerPage(){
    const selectedRowsPerPage = localStorage.getItem("selectedRowsPerPage");
    if (selectedRowsPerPage) {
        this.rowsSave = parseInt(selectedRowsPerPage);
    }
}

  onRowsPerPageChange(event: any) {
    const selectedRowsPerPage = event.rows;
    localStorage.setItem("selectedRowsPerPage", selectedRowsPerPage);
  }

  getStatusType(status:string){
    return getStatusType(status, this.isDarkTheme);
  }

  DateFilter(){
    this.payloadData = [];
    const data = {
      startDate: transformMediumDate(this.date1),
      endDate: transformMediumDate(this.date2),
      idSiteType: this.ticketCenterType,
      idUser: this.userLogin.idUser,
      transactionType: this.isViewClosedTickets? 1 : 0
    }

    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_TICKET_INFO + '?idUser=' + data.idUser + '&transactionType=' + data.transactionType + '&startDate=' + data.startDate +'&endDate=' + data.endDate + '&IdSiteType=' + data.idSiteType }`;
    if(this.date1 != null){
      this._apiService.apiGetMethod(URL).subscribe({
        next: (result: ResponseData) => {
          if(result.success){
            this.payloadData = result.data;
          } else {
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
          this.loading = false;
        },
        error: (err: any) => {
          this.loading = false;
          console.log(err);
        }
      });
    }
  }

  getViewClosedTickets(){
   // const URL = `${environment.WebApiUrl + Methods.GET_ALL_TICKET_INFO}`;
    this.isViewClosedTickets = !this.isViewClosedTickets;
    if(this.date1==null){
        this.date1 = new Date();
        this.date1.setDate(this.date1.getDate() - 4);
    }
    const data = {
        startDate: transformMediumDate(this.date1),
        endDate: transformMediumDate(this.date2),
        idUser: this.userLogin.idUser,
        transactionType: this.isViewClosedTickets? 1 : 0,
        idSiteType: this.ticketCenterType,
    }
    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_TICKET_INFO + '?idUser=' + data.idUser + '&transactionType=' + data.transactionType + '&startDate=' + data.startDate +'&endDate=' + data.endDate + '&IdSiteType=' + data.idSiteType }`;
    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          this.payloadData = result.data;
        }else {
            this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err);
      }
    });
  }

  refresh(){
    this.refreshData.emit(this.tabSelected);
  }

  hardRefresh(){
    location.reload();
  }

  GoFullMessage(col:any){
    this.onTicketSelected.emit(col);
  }

  ReleaseTicket(idTicket:number) {
    const URL = `${environment.WebApiUrl + PostMethods.POST_RELEASE_TICKET}`;
    const data = {
        idTicket
    }
    this._apiService.apiPostMethod(URL, data).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
        }else{
          this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err);
        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
      }
    });
  }

  downloadModal(){
    var myModal = new bootstrap.Modal(document.getElementById('downloadAsTable'), {
      keyboard: false
    })

    this.exportColumns = this.columsData.slice(0, -1).map((col:any) => ({
        title: this.TranslateText(col.title),
        dataKey: col.field
    }));
    this.exportColumns.push({
        title: this.TranslateText('HEADER_REPORT.STATUS'),
        dataKey: 'status'
    })
    this.exportColumns.push({
        title: this.TranslateText('HEADER_REPORT.DATE'),
        dataKey: 'date'
    })
    this.filteredData = this.dt.filteredValue ? this.dt.filteredValue : this.payloadData;
    if(this.filteredData.length != 0)
    myModal.show()
  }

  TranslateText(text: string) {
    return this._translateService.instant(text);
  }

}
