import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GetMethods, Methods, PostMethods, transformMediumDate } from '@methods/methods';
import { ResponseData, User } from '@models/models';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { MediaResponse, MediaService } from '@services/media.service';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
declare const bootstrap: any;

@Component({
  selector: 'app-personal-worklog',
  templateUrl: './personal-worklog.component.html'
})
export class PersonalWorklogComponent implements OnInit {
  mediaSubscription: Subscription;
  Media: MediaResponse;
  userLogin: User;
  user: User = new User();
  formTicket: FormGroup;
  showHardReloadBtn: boolean = false;
  showExtraInfo: boolean = false;
  loading: boolean = false;
  showRegister: boolean = false;
  showFilter: boolean = false;
  showDepartmentFilter: boolean = false;
  submitted: boolean = false;
  columsData: any;
  payload: any;
  listSource: any;
  listIssues: any;
  listIssueSelected: any;
  listSourceSelected: any;
  itemUserSelected: any;
  itemCommentSelected: any;
  keyFilterId: number = 0;
  clicks: number = 0;
  idDepartment: number = 1;
  departmentList: any;
  departmentAllList: any;
  userList: any;
  date1: Date;
  date2: Date = new Date();
  currentDate: Date = new Date();
  newComment: string = '';
  infoData: any = [];
  exportColumns!: any[];

  constructor(
    private _apiService: ApiService,
    private _notificationService: NotificationService,
    private _translateService: TranslateService,
    private _formBuilder: FormBuilder, private _mediaService:MediaService){
    this.mediaSubscription = this._mediaService.subscribeMedia().subscribe(media => {
      this.Media = media;
    });
  }

  ngOnInit() {
    this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);

    this.formTicket = this._formBuilder.group({
      Account: ['', [Validators.required]],
      Issue: ['', [Validators.required]],
      Source: ['', [Validators.required]],
    });

    this.getGlobalInfo(0, false);
    this.getGlobalInfo(1, false);
    this.getGlobalInfo(2, false);
    this.getGlobalInfo(3, false);
    this.getGlobalInfo(10, false);
  }

  get f() { return this.formTicket.controls; }

  searchUserLog(item:any){
    this.itemUserSelected = item;
    this.getGlobalInfo(7, false);
  }

  TranslateText(text: string) {
    return this._translateService.instant(text);
  }

  getGlobalInfo(key:any, showDepartmentFilter: boolean){
    this.keyFilterId = key;
    this.showDepartmentFilter = showDepartmentFilter;
    const URL = `${environment.WebApiUrl + this.returnUrlParameter(key)}`;
    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          this.showFilter = (key == 4 || key == 6 || key == 7) ? true: false;
          this.columsData = this.onHeaderColumnTableOptions((key == 7 ) ? 1 : 0);
          if(key == 0 || key == 7) {
            this.payload = result.data;
          }

          if(key == 1){
            this.listIssues = result.data;
          }

          if(key == 2){
            this.listSource = result.data;
          }

          if(key == 3){
            this.user = result.data;
          }

          if(key == 4 || key == 6){
            this.payload = [];
            this.userList = result.data;
          }

          if(key == 5 ){
            this.departmentList = result.data;
            if(this.departmentList.length >= 0){
              this.getGlobalInfo(6, false);
            }
          }

          if(key == 8){
            this.payload = result.data;
            this.loading = false;
          }

          if(key == 10) {
            this.departmentAllList = result.data;
          }
        }

        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
        this.showHardReloadBtn = true;
        this.onClick();
        console.log(err);
      }
    });
  }

  watchCommentItem(item:any){
    this.itemCommentSelected = item;
  }

  onHeaderColumnTableOptions(tab:number){
    switch (tab) {
      case 0: return [
        { field: 'Date', header: 'Date', title: 'HEADER_REPORT.DATE'},
        { field: 'Account', header: 'Account', title: 'HEADER_REPORT.ACCOUNT'},
        { field: 'Request', header: 'Request', title: 'HEADER_REPORT.REQUEST'},
        { field: 'Source', header: 'Source', title: 'HEADER_REPORT.SOURCE'},
        { field: 'User', header:'logs.User', title: 'HEADER_REPORT.USER'},
        { field: 'Actions', header:'User.Actions'},
      ];

      case 1: return [
        { field: 'Date', header: 'Date', title: 'HEADER_REPORT.DATE'},
        { field: 'Account', header: 'Account', title: 'HEADER_REPORT.ACCOUNT'},
        { field: 'Request', header: 'Request', title: 'HEADER_REPORT.REQUEST'},
        { field: 'Source', header: 'Source', title: 'HEADER_REPORT.SOURCE'},
        { field: 'Comment', header:'Comment'}
      ];

      case 2: return [
        { field: 'profileName', header: 'Profile.Profile name'},
        { field: 'active', header: 'Profile.Active'},
        { field: 'action', header: 'Profile.Action' }
      ];

      default: return null;
    }
  }


  downloadModal(){
    var myModal = new bootstrap.Modal(document.getElementById('downloadAs'), {
      keyboard: false
    })
    if(this.payload.length != 0){
      this.exportColumns = this.columsData.map((col) => {
        if (col.title !== undefined) {
          return {
            title: this.TranslateText(col.title),
            dataKey: col.field
          };
        }
        return null;
      }).filter((col) => col !== null);

      this.payload.forEach((d:any) => {
      const info = [
        transformMediumDate(d.transactionDate),
        d.account,
        d.issue,
        d.sourceInfo.name,
        this.userLogin.name
      ]
        this.infoData.push(info);
      });
       myModal.show();
    }
  }

  returnUrlParameter(key: number){
    switch (key) {
      case 0: return GetMethods.GET_PERSONAL_WORKLOG_BY_ID + this.userLogin.idUser;
      case 1: return GetMethods.GET_ALL_ISSUES_BY_DEPARTMENT_ID + this.userLogin.idDepartment;
      case 2: return GetMethods.GET_SOURCE;
      case 3: return GetMethods.GET_USER_INFO_BY_ID + this.userLogin.idUser;
      case 4: return GetMethods.GET_ALL_USER_BY_DEPARTMENT_ID + this.userLogin.idDepartment.toString();
      case 5:
      case 10: return GetMethods.GET_DEPARTMENT_LIST;
      case 6: return GetMethods.GET_ALL_USER_BY_DEPARTMENT_ID + this.idDepartment.toString();
      case 7: return GetMethods.GET_PERSONAL_WORKLOG_BY_ID + this.itemUserSelected.idUser;
      case 8: if(this.date1 == null){ this.date1 =  new Date(); this.date1.setDate(this.date1.getDate() - 4)} return GetMethods.GET_PERSONAL_WORKLOG_BY_DATE + this.userLogin.idUser +'&startDate='+ transformMediumDate(this.date1)+ '&endDate=' + transformMediumDate(this.date2);
      case 9: return PostMethods.POST_INSERT_NEW_PERSONAL_WORKLOG;
      case 11: return PostMethods.POST_INSERT_NEW_COMMENT;
      default: return null;
    }
  }

  departmentSelected(idDepartment: number){
    this.idDepartment = idDepartment;
    this.getGlobalInfo(6, true);
  }

  insertNewEvent(key:number){
    if(key == 9) {
      this.submitted = true;
      if (this.formTicket.invalid) {
        return;
      }
    }

    const URL = `${environment.WebApiUrl + this.returnUrlParameter(key) }`;
    const data = this.onPayloadData(key);
    this._apiService.apiPostMethod(URL, data).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
         if(key == 9 || key == 11){
          this.getGlobalInfo(0, false);
          this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
          this.formTicket.reset();
          this.submitted = false;
         }
        }
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        this.showHardReloadBtn = true;
        console.log(err);
      }
    });
  }

  onPayloadData(key:number){
    switch (key) {
      case 11: return {
        comment : this.newComment,
        idPersonalWorkLog : this.itemCommentSelected.idPersonalWorkLog
      }
      case 9: return {
        account: this.f.Account.value,
        issue: this.f.Issue.value,
        source: this.f.Source.value,
        transactionDate: this.currentDate,
        idUser: this.userLogin.idUser
      }
      default: return null;
    }
  }

  hardRefresh(){
    location.reload();
  }

  onClick() {
    this.clicks += 1;
    if(this.clicks >= 2){
      this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
    }
  };

}
