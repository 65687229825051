<p-card *ngIf="!showExtraInfo" class="card" header="{{ 'Profile.Profiles Admin' | translate}}" subheader="" [style]="{ width: 'auto', padding: '0' }">
    <p-tabView [scrollable]="Media.IsMobile" (onChange)="getTabSelected($event.index)" id="closableTabView">
        <p-tabPanel headerStyleClass="text-uppercase" *ngFor="let item of tabPanelData" header="{{ item.tabTitle | translate}}" [selected]="item.id == tabSelected">
            <ng-container *ngIf="item.showTable">
                <p-table #dt
                    [value]="payload"
                    [columns]="columsData"
                    [scrollable]="true" 
                    scrollHeight="700px" 
                    dataKey="id"
                    styleClass="p-datatable-striped"
                    [rowHover]="true" 
                    [rows]="20" 
                    [rowsPerPageOptions]="[20,40,60]"
                    [showCurrentPageReport]="true" 
                    [loading]="loading" 
                    [paginator]="true" 
                    responsiveLayout="stack" 
                    [tableStyle]="{'min-width': '50rem'}"
                    currentPageReportTemplate="{{'Showing {first} to {last} of {totalRecords} entries' | translate}}" 
                    [filterDelay]="0"
                    [globalFilterFields]="['profileName', 'name']">
                    <ng-template pTemplate="caption">
                        <div class="row g-2">
                            <div class="col-12 col-sm">
                                <input pInputText type="text" (input)="dt.filterGlobal($any($event.target) .value, 'contains')" placeholder="{{ 'Search' | translate }}" class="p-column-filter w-100">
                            </div>
                        </div>
                    </ng-template>

                    <ng-template pTemplate="header" let-columns>
                        <tr>
                            <th class="text-uppercase" *ngFor="let col of columns">
                                {{ col.header | translate }}
                            </th>
                        </tr>
                    </ng-template>

                    <ng-template pTemplate="body" let-payloadData>
                        <tr class="p-selectable-row">
                            <td *ngIf="tabSelected == 0">{{payloadData.profileName | translate}}</td>
                            <td *ngIf="tabSelected == 0">{{payloadData.accessAllSites | translate }}</td>
                            <td *ngIf="tabSelected == 2">{{payloadData.name | translate}}</td>
                            <td>{{payloadData.isActive}}</td> 
                            <td>
                                <button class="btn btn-icon" [ngClass]="{'p-1': Media.IsMobile}" (click)="editItem(payloadData)" type="button">
                                    <i class="feather icon-edit"></i>
                                </button>
                            </td> 
                        </tr> 
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td style="padding: 200px; text-align: center;" colspan="12">
                                <span *ngIf="!showHardReloadBtn"> {{ 'GENERICS.NO_DATA_FOUND' | translate }} </span>
                                <span *ngIf="showHardReloadBtn"> {{'GENERICS.UPDATED_ERROR' | translate}} <a class="btn btn-danger" (click)="hardRefresh()"> <i class="feather icon-refresh-cw"></i> {{ 'Refresh' | translate }}</a> </span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-container>
            <ng-container *ngIf="item.showNewRegister">
                <app-edit-profile
                    [itemSelected]="(tabSelected == 0 || tabSelected == 1) ? siteTagList : subjectList"
                    [allChecked]="allChecked"
                    [tabSelected]="tabSelected"
                    [allSiteTags]="allSiteTags"
                    (onGetOutputData)="onGetOutputData($event)">
                </app-edit-profile>
            </ng-container>
        </p-tabPanel>
    </p-tabView>
</p-card>

<div *ngIf="showExtraInfo" class="stickty-button">
    <div class="row g-0 d-flex justify-content-between">
        <div class="col-6">
            <p-button (onClick)="getTabSelected(tabSelected);" pTooltip="Back"  styleClass="p-button-primary ms-2" icon="feather icon-arrow-left" label="Back"></p-button>  
        </div>
        <div class="col-6 text-end">
            <p-button (onClick)="udpateItemInfo(tabSelected);" pTooltip="{{'Save Changes' | translate}}"  styleClass="p-button-secondary me-2" icon="feather icon-save" label="{{'Save Changes' | translate}}"></p-button>  
        </div>
    </div>
</div>

<p-card *ngIf="showExtraInfo" class="card custom-top-margin">
    <app-edit-profile
        [itemSelected]="itemSelected"
        [allChecked]="allChecked"
        [allSiteTags]="allSiteTags"
        [tabSelected]="tabSelected">
    </app-edit-profile>
</p-card>