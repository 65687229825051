import { Component } from '@angular/core';
import { Filters } from '@methods/constants';
import { GetMethods, Methods } from '@methods/methods';
import { ResponseData } from '@models/models';
import { ApiService } from '@services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html'
})
export class DepartmentComponent {
  payloadData: Filters[];
  isErrorActivated: boolean = false;
  identity: number = 3;
  checked: boolean = false;

  constructor(private _apiService: ApiService){}

  ngOnInit(): void {
      this.getDepartmentList();
  }

  getDepartmentList(){
    this.payloadData = [];
    this.isErrorActivated = false;
    const URL = `${environment.WebApiUrl + GetMethods.GET_DEPARTMENT_LIST}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          result.data.forEach((value: any) => {
            value.idvalue = value.idDepartment;
            value.label = value.name;
          });
          var allChecked = result.data.find(x => x.isActive == false);
          this.checked = ( allChecked == null  || allChecked == undefined) ? true : false;
          this.payloadData = result.data;
        }
      },
      error: (err: any) => {
        this.isErrorActivated = true;
        console.log(err);
      }
    });
  }

  refreshData($event:any){
    this.getDepartmentList();
  }

}
