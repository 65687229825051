import { Component, OnInit } from '@angular/core';
import { Filters } from '@methods/constants';
import { GetMethods, Methods } from '@methods/methods';
import { ResponseData } from '@models/models';
import { ApiService } from '@services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-brand-set-up',
  templateUrl: './brand-set-up.component.html'
})
export class BrandSetUpComponent implements OnInit {
  payloadData: Filters[];
  payloadSecondData: any;
  isErrorActivated: boolean = false;
  identity: number = 1;
  identity2: number = 2
  checked: boolean = false;
  cardHeaderTitle1: any;
  cardHeaderTitle2: any;
  departmentList: any;
  allSiteTags: any;
  formValidateInputs: any;
  formValidateInputs2: any;

  constructor(private _apiService: ApiService){}

  ngOnInit(): void {
    this.formValidateInputs = [
      { control: 'inputTextSelected', show: true },
      { control: 'siteSelected', show: true },
      { control: 'department', show: true },
      { control: 'inputTextDescription', show: false },
      { control: 'inputTextSpanish', show: false },
      { control: 'inputTextPortuguese', show: false },
      { control: 'formType', show: false },
      { control: 'isHelp', show: false },
      { control: 'isInternal', show: false },
      { control: 'isPartner', show: false },
      { control: 'isDirect', show: false },
      { control: 'isActive', show: false },
    ]

    this.formValidateInputs2 = [
      { control: 'inputTextSelected', show: false },
      { control: 'siteSelected', show: true },
      { control: 'department', show: false },
      { control: 'inputTextDescription', show: false },
      { control: 'inputTextSpanish', show: false },
      { control: 'inputTextPortuguese', show: false },
      { control: 'formType', show: false },
      { control: 'isHelp', show: false },
      { control: 'isInternal', show: false },
      { control: 'isPartner', show: false },
      { control: 'isDirect', show: false },
      { control: 'isActive', show: false },
    ]

    this.cardHeaderTitle1 = [
      { title1: 'Status', title2: 'Brand Name', title3 : 'Actions' }
    ]

    this.cardHeaderTitle2 = [
      { title1: 'Website', title2: '', title3 : 'Actions' }
    ]
    this.getBrandSetUp();
    this.getAllWebsiteList();
    this.getAllDepartmentList();
  }

  getBrandSetUp(){
    this.payloadData = [];
    const URL = `${environment.WebApiUrl + GetMethods.GET_BRAND_SET_UP_BY_ID_SITE + -1}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
            if(result.success){
                result.data.forEach((value: any) => {
                    value.idvalue = value.idBrandSetup;
                    value.label = value.brandName;
                    value.showCheckbox = true;
                    value.showEdit = true;
                });
                this.payloadData = result.data;
                this.getBrandSiteById(result.data[0].idvalue )
            }
      },
      error: (err: any) => {
        this.isErrorActivated = true;
        console.log(err);
      }
    });
  }

  getBrandSiteById(idSite: any) {
    this.payloadSecondData = [];
    const URL = `${environment.WebApiUrl + GetMethods.GET_BRAND_SELECTED_BY_ID_SITE + idSite}`;
    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
            result.data.forEach((value: any) => {
                value.idvalue = value.idBrandSetup;
                value.label = value.description;
                value.showCheckbox = false;
                value.showEdit = false;
            });
            this.payloadSecondData = result.data;
        }
      },
      error: (err: any) => {
        this.isErrorActivated = true;
        console.log(err);
      }
    });
  }

  getAllWebsiteList() {
    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_SITE_TAGS}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          this.allSiteTags = result.data;
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  getAllDepartmentList(){
    this.departmentList = [];
    const URL = `${environment.WebApiUrl + GetMethods.GET_DEPARTMENT_LIST}`;
    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
      result.data.forEach((value: any) => {
        value.value = value.name;
        value.label = value.name;
      });
      this.departmentList = result.data.filter((x:any) => x.isActive == true);
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  refreshData($event:any){
    this.getBrandSetUp();
  }

  onFilterSiteById(event:any) {
    this.getBrandSiteById(event);
  }

  hardRefresh(){
    location.reload();
  }
}