import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Filters, InternalList } from '@methods/constants';
import { DeleteMethods, Methods, PostMethods, PutMethods } from '@methods/methods';
import { User } from '@models/auth-model';
import { ResponseData } from '@models/models';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@services/api.service';
import { NotificationService } from '@services/notification.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
declare const bootstrap: any;


@Component({
  selector: 'app-checkbox-editor',
  templateUrl: './checkbox-editor.component.html',
})
export class CheckboxEditorComponent implements OnInit {
  @Input() title: any;
  @Input() identity: number; // 1: relevance level // 2 status list // 3 department
  @Input() payloadData: any;
  @Input() isErrorActivated: boolean;
  @Input() modalTitleNewText: string;
  @Input() modalTitleEditText: string;
  @Input() newPostEvent: number;
  @Input() editPostEvent: number;
  @Input() deletePostEvent: number;
  @Input() selectAllCheckbox:number;
  @Input() checked: boolean;
  @Output() refreshData = new EventEmitter<boolean>();

  sortView: boolean = false;
  userLogin: User;
  internalList: Filters[];
  editOrNewItemModal: any;
  isEditOrNewItem: boolean = false;
  titleModal: string = '';
  inputTextSelected: string = '';
  itemSelected: any;
  isNewCheckboxActive: boolean = false;
  userForm: FormGroup;
  submitted: boolean = false;

  constructor(private _apiService: ApiService, private _notificationService: NotificationService, private _formBuilder: FormBuilder, private _translateService: TranslateService){
    this.internalList = InternalList;
  }

  ngOnInit(): void {
    this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
    this.userForm = this._formBuilder.group({
      inputTextSelected: ['' , [Validators.required]],
      email: ['' , [Validators.email]],
      isInternal: [''],
      isActive: [false]
    });
    setTimeout(() => {
      this.IniModal();
    }, 2);
  }

  get f() { return this.userForm.controls; }

  changeViewSites(){
    this.sortView = true;
  }

  editOrNewItem(item:any, isNew: boolean){
    this.titleModal = '';
    this.itemSelected = item;
    this.isEditOrNewItem = isNew;
    this.titleModal = this.TranslateText((isNew) ? this.modalTitleNewText: this.modalTitleEditText);
    this.userForm.get('inputTextSelected')?.setValue(isNew ? '' : item.label);
    if(this.identity === 3){
      this.userForm.get('email')?.setValue(isNew ? '' : item.email);
      this.userForm.get('isInternal')?.setValue(isNew ? '' : item.isInternal);
      this.userForm.get('isActive')?.setValue(isNew ? '' : item.isActive);

      var emailControl =  this.userForm.get('email');
      emailControl?.setValidators([Validators.required]);
      emailControl?.updateValueAndValidity();

      var isInternalControl =  this.userForm.get('isInternal');
      isInternalControl?.setValidators([Validators.required]);
      isInternalControl?.updateValueAndValidity();
    }
    this.editOrNewItemModal.show();
  }

  closeModal(){
    this.titleModal = '';
    this.editOrNewItemModal.hide();
  }

  createAndUpdateByPayloadData(){
    this.submitted = true;
    if (this.userForm.invalid) {
      return;
    }
    const numberType = (this.isEditOrNewItem) ? this.newPostEvent : this.editPostEvent;
    const data = this.returnObjetData(numberType);
    const URL = `${environment.WebApiUrl + this.returnUrlParameter(numberType) }`;

    if(numberType == 1 || numberType == 5 || numberType == 9){
        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.refreshData.emit(true);
                    this.userForm.reset();
                    this.editOrNewItemModal.hide();
                    this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                    this.submitted = false;
                }else{
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
              console.log(err);
              this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }else{
        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.refreshData.emit(true);
                    this.userForm.reset();
                    this.editOrNewItemModal.hide();
                    this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                }else{
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
              console.log(err);
              this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }
  }

  updateItemByCheckbox(event:any, item:any){
    item.isActive = event.target.checked;
    item.userInCharge = this.userLogin.idUser;
    const URL = `${environment.WebApiUrl +  this.returnUrlParameter(this.editPostEvent)}`;
    this._apiService.apiPostMethod(URL, item).subscribe({
      next: (result: ResponseData) => {
        if (result.success) {
          this.refreshData.emit(true);
          this.userForm.reset();
          this.editOrNewItemModal.hide();
          this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
        }else{
          this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
      },
      error: (err: any) => {
        console.log(err);
        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
      }
    });
  }

  deleteItem(item:any){
    Swal.fire({
      text: "Are you sure?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
      allowOutsideClick: false
    }).then((result) => {
      if (result.isConfirmed) {
        const URL = `${environment.WebApiUrl + this.returnUrlParameter(this.deletePostEvent) }`;
        item.userInCharge = this.userLogin.idUser;
        this._apiService.apiPostMethod(URL, item).subscribe({
          next: (result: ResponseData) => {
            if (result.success) {
              this.refreshData.emit(true);
              this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
            }else{
              this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
          },
          error: (err: any) => {
            console.log(err);
            this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
          }
        });
      }
    })
  }

  UpdateAllItemCheckbox(event: any){
    this.payloadData.forEach(element => {
      element.isActive = event.checked;
      element.userInCharge = this.userLogin.idUser;
    });
    const URL = `${environment.WebApiUrl + this.returnUrlParameter(this.selectAllCheckbox) }`;
    this._apiService.apiPostMethod(URL, this.payloadData).subscribe({
      next: (result: ResponseData) => {
        if (result.success){
          this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
        }else{
          this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  deleteItemByPayloadData(arr:any, idvalue: number) {
    const objWithIdIndex = arr.findIndex((obj:any) => obj.idvalue === idvalue);
    if (objWithIdIndex > -1) {
      arr.splice(objWithIdIndex, 1);
    }
    return arr;
  }

  returnUrlParameter(idType: number){
    switch (idType) {
      case 1 : return PostMethods.POST_INSERT_NEW_RELEVANCE_LEVEL;

      case 2: return PutMethods.UPDATE_RELEVANCE_LEVEL_ITEM;

      case 3: return DeleteMethods.DELETE_RELEVANCE_ITEM;

      case 4: return PutMethods.UPDATE_ALL_ITEM_RELEVANCE_LEVEL;

      case 5: return PostMethods.POST_INSERT_NEW_TICKET_STATUS;

      case 6: return PutMethods.UPDATE_TICKET_STATUS_ITEM;

      case 7: return DeleteMethods.DELETE_STATUS_ITEM;

      case 8: return PutMethods.UPDATE_ALL_TICKET_STATUS_ITEM;

      case 9: return PostMethods.POST_INSERT_NEW_DEPARTMENT;

      case 10: return PutMethods.UPDATE_DEPARTMENT_ITEM;

      case 11: return DeleteMethods.DELETE_DEPARTMENT_ITEM;

      case 12: return PutMethods.UPDATE_ALL_ITEM_DEPARTMENT;

      default: return null;
    }
  }

  returnObjetData(idType: number){
    switch (idType) {
      // create new in Relevance and status
      case 1:
      case 5: return {
        isActive: this.f.isActive.value,
        description: this.f.inputTextSelected.value,
        userInCharge: this.userLogin.idUser
      };

      case 2: return {
        description: this.f.inputTextSelected.value,
        idRelevance: this.itemSelected.idvalue,
        isActive: this.itemSelected.isActive,
        isDelete: this.itemSelected.isDelete,
        lastModification: this.itemSelected.lastModification,
        userInCharge: this.userLogin.idUser,
        userInChargeName: null
      }

      case 6: return {
        description: this.f.inputTextSelected.value,
        idStatusList: this.itemSelected.idvalue,
        isActive: this.itemSelected.isActive,
        isDelete: this.itemSelected.isDelete,
        lastModification: this.itemSelected.lastModification,
        userInCharge: this.userLogin.idUser,
        userInChargeName: null
      }

      // New Department by modal
      case 9: return {
        email: this.f.email.value,
        isActive: this.f.isActive.value,
        isInternal: this.f.isInternal.value,
        name: this.f.inputTextSelected.value,
        userInCharge: this.userLogin.idUser
      };

      // Edit Department by modal
      case 10: return{
        email: this.f.email.value,
        idDepartment: this.itemSelected.idDepartment,
        isActive: this.f.isActive.value,
        isDelete: this.itemSelected.isDelete,
        isInternal: this.f.isInternal.value,
        lastModification: this.itemSelected.lastModification,
        name: this.f.inputTextSelected.value,
        userInCharge: this.userLogin.idUser,
        userInChargeName : null
      }

      default: return null;
    }
  }

  hardRefresh(){
    location.reload();
  }

  TranslateText(text: string) {
    return this._translateService.instant(text);
  }

  IniModal() {
    this.editOrNewItemModal = new bootstrap.Modal(document.getElementById('editOrNewItemModal'), {
      keyboard: false
    });
  }
}
