import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TagComponent, TokenAccess, User } from '@models/auth-model';
import { ApiService } from '@services/api.service';
import { GetMethods, Methods, PostMethods, getSmartLoginTimeOut } from '@methods/methods';
import { environment } from 'src/environments/environment';
import { MediaResponse, MediaService } from '@services/media.service';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { BrandSetup, ConfigByDomain, ResponseData } from '@models/models';
import packageJson from '../../../../../package.json';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
    public version: string = packageJson.version;
    private mediaSubscription: Subscription;
    Media: MediaResponse;

    userLogin: User = new User();
    loginForm: FormGroup;
    verifyForm: FormGroup;
    verifyTFAuthForm: FormGroup;
    submitted: boolean = false;
    submittedToken: boolean = false;
    submittedTokenTFA: boolean = false;
    messageResult: string = "";
    isEmailToken: boolean = false;
    isTwoFactorAuth: boolean = false;
    loading: boolean = false;

    _menuItem: TagComponent[];
    _menuItem2: TagComponent[];

    tokenAccess: TokenAccess = new TokenAccess();
    GettokenAccess: TokenAccess = new TokenAccess();

    //brandSetup
    _BrandSetup: BrandSetup[] = [];
    _configByDomain: ConfigByDomain = new ConfigByDomain();


    constructor(
        private _apiService: ApiService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _mediaService: MediaService,
        private _cookieService: CookieService) {
        this.mediaSubscription = this._mediaService.subscribeMedia().subscribe(media => {
            this.Media = media;
        });
    }


    ngOnInit() {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required]],
            password: ['', [Validators.required]]
        });

        this.verifyForm = this._formBuilder.group({
            token: ['', [Validators.required]]
        });

        this.verifyTFAuthForm = this._formBuilder.group({
            tokenTFA: ['', [Validators.required]]
        });
        this.purge();

    }
    get f() { return this.loginForm.controls; }
    get g() { return this.verifyForm.controls; }
    get j() { return this.verifyTFAuthForm.controls; }


    onSubmit() { // Inicio de sesion normal
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;

        const data = {
            email: this.f.email.value,
            password: this.f.password.value
        }

        const URL = `${environment.WebApiUrl + PostMethods.POST_LOGIN_USER }`;

        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
                this.loading = false;
                if (result.success) {
                    this.userLogin = result.data;
                    this.userLogin.adminType = 1;

                    if (result.data.smartLogin) {
                        const resultToken = this._cookieService.get('token');
                        if (resultToken != '') {
                            localStorage.setItem('UserAccessCTS', JSON.stringify(this.userLogin));
                            localStorage.setItem('IdUser', this.userLogin.idUser.toString());
                            this.GetBrandsSetup();
                            this._cookieService.set("IdUser", this.userLogin.idUser.toString(), getSmartLoginTimeOut());
                            localStorage.setItem('isSmartLogin', 'true');
                            this._cookieService.set('token', this.getTokenEncoded(), getSmartLoginTimeOut());
                            this._router.navigate(['/dashboard/tickets']);
                        }
                    } else {
                        localStorage.setItem('isSmartLogin', 'false');
                    }

                    if (result.data.isTFA) {
                        this.isTwoFactorAuth = true;
                    }

                    if(!result.data.isTFA && !result.data.smartLogin || !result.data.isTFA && result.data.smartLogin){
                        this._cookieService.set('IdUser', this.userLogin.idUser.toString(), getSmartLoginTimeOut());
                        localStorage.setItem('UserAccessCTS', JSON.stringify(this.userLogin));
                        this.GetBrandsSetup();
                        localStorage.setItem('IdUser', this.userLogin.idUser.toString());
                        this._cookieService.set('token', this.getTokenEncoded(), getSmartLoginTimeOut());
                        localStorage.setItem('isSmartLogin', 'false');
                        this._router.navigate(['/dashboard/tickets']);
                    }

                } else {
                    this.getMessageInfo("Please check your credentials", 3000);
                }
            },
            error: (err: any) => {
                console.log(err);
                this.loading = false;
                this.getMessageInfo("Something went wrong please try again or contact with you administrator", 6000);
            }
        });
    }

    getTokenEncoded() {
        return btoa(this.userLogin.idUser + ":" + this.userLogin.token + ":" + this.userLogin.adminType);
    }

    onSubmitEmailToken() {
        this.submittedToken = true;
        if (this.verifyForm.invalid) {
            return;
        }

        this._cookieService.set('token', this.getTokenEncoded());
        // this._cookieService.set("isSmartLogin", 'false', getSmartLoginTimeOut());
        localStorage.setItem('isSmartLogin', 'false');
        this._cookieService.set('IdUser', this.userLogin.idUser.toString());

        this.loading = true;
        const data = {
            Token: this.g.token.value.toString(),
            User: this.userLogin.idUser
        }
        const URL = `${environment.WebApiUrl + Methods.POST_SEND_ACCESS_TOKEN}`;

        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: any) => {
                if (result.IdTokenAccess != 0) {
                    this.loading = false;
                    // localStorage.setItem('UserAccessCTS', JSON.stringify(this.userLogin));
                    // localStorage.setItem('IdUser', this.userLogin.idUser.toString());
                    this.GetBrandsSetup();
                    this._router.navigate(['/dashboard/tickets']);
                } else {
                    this.loading = false;
                    this.getMessageInfo('Invalid token', 3000)
                }
            },
            error: (err: any) => {
                console.log(err);
                this.loading = false;
            }
        });
    }

    verifyTwoFactorAuthenticationToken() {
        this.submittedTokenTFA = true;
        if (this.verifyTFAuthForm.invalid) {
            return;
        }

        this.loading = true;
        const URL = `${environment.WebApiUrl + GetMethods.GET_VALIDATION_TWO_FACTOR_TOKEN + this.userLogin.email + '&Inputcode=' + this.j.tokenTFA.value }`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.loading = false;
                    if(result.data){
                        this._cookieService.set('token', this.getTokenEncoded(), getSmartLoginTimeOut());
                        this._cookieService.set('IdUser', this.userLogin.idUser.toString(), getSmartLoginTimeOut());

                        localStorage.setItem('UserAccessCTS', JSON.stringify(this.userLogin));
                        localStorage.setItem('IdUser', this.userLogin.idUser.toString());
                        this.GetBrandsSetup();
                        this._router.navigate(['/dashboard/tickets']);
                    }else{
                        this.getMessageInfo('Invalid token!', 3000);
                    }
                } else {
                    this.loading = false;
                    this.getMessageInfo('Invalid token!', 3000);
                }
            },
            error: (err: any) => {
                console.log(err);
                this.loading = false;
                this.getMessageInfo('Login Error, please contact with your administrator', 3000);
            }
        });
    }


    ChangeMethod() {
        this.tokenAccess.User = this.userLogin.idUser.toString();
        this.tokenAccess.IpAddress = "10.0.0.1";
        this.tokenAccess.Date = new Date();
        this.tokenAccess.SendEmail = true;
        const URL = `${environment.WebApiUrl + Methods.POST_INSERT_ACCESS_TOKEN}`;

        this._apiService.apiPostMethod(URL, this.tokenAccess).subscribe(data => {
            this.tokenAccess.Token = data.data.token;
            this.isTwoFactorAuth = false;
            //this.isEmailToken = true;
        });
    }

    getMessageInfo(message: string, time: number) {
        this.messageResult = message;
        setTimeout(() => {
            this.messageResult = '';
        }, time);
    }

    purge(){
        const hasRefreshed = sessionStorage.getItem('hasRefreshed');
        if(!hasRefreshed){
            const URL = `${environment.WebApiUrl + PostMethods.POST_PURGE}`;
            this._apiService.apiPostMethod(URL, null).subscribe({
            next: (result: ResponseData) => {
                console.log('purge result', result);
               if(result.success){
                sessionStorage.setItem('hasRefreshed', 'true');
               }
            },
            error: (err: any) => {
                console.log('purge error =>', err);
            }
            });
        }
    }

    GetBrandsSetup() {
        this._configByDomain = JSON.parse(localStorage.getItem('DomainCTS')!);
        this._apiService.apiGetMethod(`${environment.WebApiUrl}${GetMethods.GET_BRAND_SET_UP_BY_ID_SITE}${this._configByDomain.idSite}`).subscribe(data => {
          if (data.success) {
            this._BrandSetup = data.data;
            sessionStorage.setItem('BrandSetup', JSON.stringify(this._BrandSetup[0]));
          }
        });
      }
}
