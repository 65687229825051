<form [formGroup]="newUserForm" (ngSubmit)="submitForm()" class="form-group row g-3 px-3 align-items-center">

    <ng-container >
        <dt for="userName" class="col-sm-3">{{'GlobalForm.Name' | translate}}</dt>
        <div class="col-sm-9" >
            <input type="text" pInputText class="w-100" formControlName="userName" [ngClass]="{ 'is-invalid invalid': submitted && f.userName.errors }" />
            <div *ngIf="submitted && f.userName.errors" class="invalid-feedback">
                <div *ngIf="f.userName.errors.required">{{TranslateText('GlobalForm.Name')  + ' ' + TranslateText('GlobalForm.IsRequired')}}</div>
                <div *ngIf="errors.errorName">{{TranslateText('GlobalForm.NameErroDuplicate')}}</div>
            </div>
            <div class="invalid-feedback d-block">
                <div *ngIf="errors.errorName">{{TranslateText('GlobalForm.NameErroDuplicate')}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container >
        <dt for="nickName" class="col-sm-3">{{'GlobalForm.NickName' | translate}}</dt>
        <div class="col-sm-9" >
            <input type="text" pInputText class="w-100" formControlName="nickName" [ngClass]="{ 'is-invalid invalid': submitted && f.nickName.errors }" />
            <div *ngIf="submitted && f.nickName.errors" class="invalid-feedback">
                <div *ngIf="f.nickName.errors.required">{{TranslateText('GlobalForm.NickName')  + ' ' + TranslateText('GlobalForm.IsRequired')}}</div>
            </div>
            <div class="invalid-feedback d-block">
                <div *ngIf="errors.errorNickname">{{TranslateText('GlobalForm.NickNameErroDuplicate')}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container >
        <dt for="email" class="col-sm-3">{{'GlobalForm.Email' | translate}}</dt>
        <div class="col-sm-9" >
            <input type="text" pInputText class="w-100" [email]="true" formControlName="email" [ngClass]="{ 'is-invalid invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">{{TranslateText('GlobalForm.Email')  + ' ' + TranslateText('GlobalForm.IsRequired')}}</div>
                <div *ngIf="f.email.errors.email">{{TranslateText('GlobalForm.EmailValid')}}</div>
            </div>
            <div class="invalid-feedback d-block">
                <div *ngIf="errors.errorEmail">{{TranslateText('GlobalForm.EmailErroDuplicate')}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container >
        <dt for="password" class="col-sm-3">{{'GlobalForm.Password' | translate}}</dt>
        <div class="col-sm-9 p-input-icon-right" >
            <i style="cursor: pointer; position: absolute; top: 21px;" ngbTooltip="{{hidePassword ? 'Show password': 'Hide password'}}" class="feather {{hidePassword ? 'icon-eye' : 'icon-eye-off'}} px-3 py-1" (click)="showPassword(hidePassword)"></i>
            <input type="{{!hidePassword ? 'text': 'password'}}" pInputText class="w-100" formControlName="password" [ngClass]="{ 'is-invalid invalid': submitted && f.password.errors }" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">{{TranslateText('GlobalForm.Password')  + ' ' + TranslateText('GlobalForm.IsRequired')}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container >
        <dt for="profile" class="col-sm-3">{{'GlobalForm.Profile' | translate}}</dt>
        <div class="col-sm-9" >
            <p-dropdown
                styleClass="d-flex w-100"
                [options]="profileList"
                optionLabel="profileName"
                [autoDisplayFirst]="false"
                formControlName="profile"
                [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.profile.errors }"
                optionValue="idProfile">
            </p-dropdown>
            <div *ngIf="submitted && f.profile.errors" class="invalid-feedback">
                <div *ngIf="f.profile.errors.required">{{ TranslateText('GlobalForm.Profile') + ' ' + TranslateText('GlobalForm.IsRequired')}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container >
        <dt for="userType" class="col-sm-3">{{'GlobalForm.UserType' | translate}}</dt>
        <div class="col-sm-9" >
            <p-dropdown
                styleClass="d-flex w-100"
                [options]="UserTypeList"
                optionLabel="label"
                [autoDisplayFirst]="false"
                formControlName="userType"
                [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.userType.errors }"
                optionValue="value">
            </p-dropdown>
            <div *ngIf="submitted && f.userType.errors" class="invalid-feedback">
                <div *ngIf="f.userType.errors.required">{{ TranslateText('GlobalForm.UserType') + ' ' + TranslateText('GlobalForm.IsRequired')}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container >
        <dt for="departments" class="col-sm-3">{{'GlobalForm.Department' | translate}}</dt>
        <div class="col-sm-9" >
            <p-dropdown
                styleClass="d-flex w-100"
                [options]="departmentList"
                optionLabel="label"
                [filter]="true" filterBy="label" [showClear]="true"
                [autoDisplayFirst]="false"
                formControlName="departments"
                [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.departments.errors }"
                optionValue="idDepartment">
            </p-dropdown>
            <div *ngIf="submitted && f.departments.errors" class="invalid-feedback">
                <div *ngIf="f.departments.errors.required">{{ TranslateText('GlobalForm.Department') + ' ' + TranslateText('GlobalForm.IsRequired')}}</div>
            </div>
        </div>
    </ng-container>

    <div class="w-100 text-center">
        <p-button pTooltip="{{'Save' | translate}}" (onClick)="submitForm()"  styleClass="p-button-primary mx-1" icon="feather icon-save" label="{{'Save' | translate}}"></p-button>
    </div>
</form>
