import { inject } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { environment } from 'src/environments/environment';

export function getSmartLoginTimeOut() {
    return 1
}

export function TranslateText(text: string) {
    const translateService = inject(TranslateService);
    return translateService.instant(text);
}

export function transformDate(value: any) {
    var datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, 'dd/MM/yyyy hh:mm a');
    return value;
}

export function transformMediumDate(value: any) {
    var datePipe = new DatePipe("en-US");
    value = datePipe.transform(value, 'yyyy-MM-dd');
    return value;
}

export function getStatusType(status: string, isDarkTheme: boolean) {
    switch (status) {
        case "In-Progress": return 'text-in-progress';

        case "Open": return 'text-open';

        case "Resolved": return 'text-resolved';

        default: return isDarkTheme ? 'text-light' : 'text-dark';
    }
}

export function getThemeFilter(type: any) {
    switch (type) {
        case '':
        case null:
        case undefined:
        case 'theme-default':
        case 'theme-default-light': return 'theme-default-light';

        case 'theme-default-dark': return 'theme-default-dark';
        case 'theme-ctsadmin': return 'theme-ctsadmin';

        default: return 'theme-default-light';
    }
}

export function responseError(type: any) {
    switch (type) {
        case 0:
        case 405:
        case 500: return 'An error occurred in the process.';

        case 401: return 'Access denied, login is required to access this page!.';


        default: return 'An error occurred in the process.';
    }
}

// Función para verificar si un string es una imagen base64
export function esImagenBase64(base64String) {
    return /^data:image\/(png|jpe?g);base64,/i.test(base64String);
}

// Función para convertir la imagen base64 a Blob
export function base64ToBlob(base64Data: string, contentType: string): Blob {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
}


// Función para reemplazar la imagen base64 con el Blob
export function reemplazarImagenBase64ConBlob(texto, uploader: FileUploader) {

    const regexImg = /<img[^>]+src="([^">]+)"/g;
    let resultado;
    while ((resultado = regexImg.exec(texto)) !== null) {
        const src = resultado[1];
        if (esImagenBase64(src)) {
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0');
            const day = String(currentDate.getDate()).padStart(2, '0');
            const hours = String(currentDate.getHours()).padStart(2, '0');
            const minutes = String(currentDate.getMinutes()).padStart(2, '0');
            const seconds = String(currentDate.getSeconds()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}-${hours}-${minutes}-${seconds}`;

            const blob = base64ToBlob(src.split(',')[1], 'image/png');

            const fileName = 'screenshot-' + formattedDate + '.png'

            const file: File = new File([blob], fileName, { type: 'image/png', lastModified: Date.now() });

            uploader.addToQueue([file]);

            const newURL = environment.previousDisplay + fileName;
            texto = texto.replace(src, newURL);
        }
    }
    return texto;
}



export function PreviousImage(item: FileItem, uploader: FileUploader) {

    try {
        uploader.options.url = environment.uploadFilesApi;
        item.url = environment.uploadFilesApi;
        item.upload();
        uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) =>
            onErrorItem(item, response, status, headers)
        uploader.onSuccessItem = (item: any, response: any, status: any, headers: any) => {
            if (status == 200) {
                var responseBanner = JSON.parse(response);
                const model: any = [];
                model.Previous = true;
                model.fileUrl = `${environment.previousDisplay}${responseBanner.name}`;
                model.imageInfo = responseBanner;
                return model;
            } else {
                console.log("error");
            }
        };
    } catch (error) {
        console.log(error);
    }
}

export function onErrorItem(
    item: FileItem,
    response: string,
    status: number,
    headers: ParsedResponseHeaders
): any {
    console.log(item);
    console.log(response);
    console.log(status);
    console.log(headers);
}


export function validateFormControlType(type: number) {
    /**
    Form Component functions
        // 1 HEPL TICKET CENTER
        // 2 PARTNERS TICKET CENTER
        // 3 INTERNAL TICKET CENTER
        // 4 DIRECT TICKET CENTER
        // 5 6 7 TICKET MESSAGE INFO // MODAL FORMS New Message
        // 8 SERVICE DESK NEW ENTRY
        // 9 SERVICE DESK NEW COMMENT
        // 10 SERVICE DESK EDIT ENTRY
        // 12 SHORCUT CENTER ADD NEW ENTRY
    */
    switch (type) {
        case 1: return [
            { control: 'userSelected', show: false },
            { control: 'account', show: true },
            { control: 'agentPlayer', show: true },
            { control: 'subject', show: true },
            { control: 'subjectField', show: true },
            { control: 'website', show: true },
            { control: 'relevance', show: true },
            { control: 'department', show: true },
            { control: 'status', show: true },
            { control: 'replyToEmail', show: false },
            { control: 'shortcuts', show: true },
            { control: 'message', show: true },
            { control: 'emailSubject', show: false },
            { control: 'ticketLink', show: false },
            { control: 'inputTextName', show: false },
            { control: 'description', show: false }
        ]

        case 2: return [
            { control: 'userSelected', show: false },
            { control: 'account', show: true },
            { control: 'agentPlayer', show: false },
            { control: 'subject', show: true },
            { control: 'subjectField', show: true },
            { control: 'website', show: false },
            { control: 'relevance', show: false },
            { control: 'department', show: false },
            { control: 'status', show: true },
            { control: 'replyToEmail', show: false },
            { control: 'shortcuts', show: true },
            { control: 'message', show: true },
            { control: 'emailSubject', show: false },
            { control: 'ticketLink', show: false },
            { control: 'inputTextName', show: false },
            { control: 'description', show: false }
        ]

        case 3: return [
            { control: 'userSelected', show: false },
            { control: 'account', show: true },
            { control: 'agentPlayer', show: false },
            { control: 'subject', show: true },
            { control: 'subjectField', show: true },
            { control: 'website', show: false },
            { control: 'relevance', show: false },
            { control: 'department', show: false },
            { control: 'status', show: true },
            { control: 'replyToEmail', show: false },
            { control: 'shortcuts', show: true },
            { control: 'message', show: true },
            { control: 'emailSubject', show: false },
            { control: 'ticketLink', show: false },
            { control: 'inputTextName', show: false },
            { control: 'description', show: false }
        ]

        case 4: return [
            { control: 'userSelected', show: true },
            { control: 'account', show: true },
            { control: 'agentPlayer', show: false },
            { control: 'subject', show: true },
            { control: 'subjectField', show: true },
            { control: 'website', show: false },
            { control: 'relevance', show: false },
            { control: 'department', show: false },
            { control: 'status', show: true },
            { control: 'replyToEmail', show: false },
            { control: 'shortcuts', show: true },
            { control: 'message', show: true },
            { control: 'emailSubject', show: false },
            { control: 'ticketLink', show: false },
            { control: 'inputTextName', show: false },
            { control: 'description', show: false }
        ]

        case 5:
        case 6:
        case 7: return [
            { control: 'userSelected', show: false },
            { control: 'account', show: false },
            { control: 'agentPlayer', show: false },
            { control: 'subject', show: false },
            { control: 'subjectField', show: false },
            { control: 'website', show: false },
            { control: 'relevance', show: false },
            { control: 'department', show: false },
            { control: 'status', show: false },
            { control: 'replyToEmail', show: false },
            { control: 'shortcuts', show: true },
            { control: 'message', show: true },
            { control: 'emailSubject', show: false },
            { control: 'ticketLink', show: false },
            { control: 'inputTextName', show: false },
            { control: 'description', show: false }
        ]

        case 8: return [
            { control: 'userSelected', show: false },
            { control: 'account', show: false },
            { control: 'agentPlayer', show: false },
            { control: 'subject', show: false },
            { control: 'subjectField', show: false },
            { control: 'website', show: false },
            { control: 'shortcuts', show: false },
            { control: 'relevance', show: false },
            { control: 'department', show: false },
            { control: 'status', show: true },
            { control: 'replyToEmail', show: false },
            { control: 'message', show: true },
            { control: 'emailSubject', show: true },
            { control: 'ticketLink', show: true },
            { control: 'inputTextName', show: false },
            { control: 'description', show: false }
        ]

        case 9: return [
            { control: 'userSelected', show: false },
            { control: 'account', show: false },
            { control: 'agentPlayer', show: false },
            { control: 'subject', show: false },
            { control: 'subjectField', show: false },
            { control: 'website', show: false },
            { control: 'shortcuts', show: false },
            { control: 'relevance', show: false },
            { control: 'department', show: false },
            { control: 'status', show: false },
            { control: 'replyToEmail', show: false },
            { control: 'message', show: true },
            { control: 'emailSubject', show: false },
            { control: 'ticketLink', show: false },
            { control: 'inputTextName', show: false },
            { control: 'description', show: false }
        ]

        case 10: return [
            { control: 'userSelected', show: false },
            { control: 'account', show: false },
            { control: 'agentPlayer', show: false },
            { control: 'subject', show: false },
            { control: 'subjectField', show: false },
            { control: 'website', show: false },
            { control: 'shortcuts', show: false },
            { control: 'relevance', show: false },
            { control: 'department', show: true },
            { control: 'status', show: true },
            { control: 'replyToEmail', show: false },
            { control: 'message', show: false },
            { control: 'emailSubject', show: true },
            { control: 'ticketLink', show: true },
            { control: 'inputTextName', show: false },
            { control: 'description', show: false }
        ]

        case 12:
        case 13: return [
            { control: 'userSelected', show: false },
            { control: 'account', show: false },
            { control: 'agentPlayer', show: false },
            { control: 'subject', show: false },
            { control: 'subjectField', show: false },
            { control: 'website', show: false },
            { control: 'shortcuts', show: false },
            { control: 'relevance', show: false },
            { control: 'department', show: true },
            { control: 'status', show: false },
            { control: 'replyToEmail', show: false },
            { control: 'message', show: true },
            { control: 'emailSubject', show: false },
            { control: 'ticketLink', show: false },
            { control: 'inputTextName', show: true },
            { control: 'description', show: true }
        ]
        default: return null;
    }
}

export function getFormType(type: number) {
    /**
    Form Component functions
        // 1 HEPL TICKET CENTER
        // 2 PARTNERS TICKET CENTER
        // 3 INTERNAL TICKET CENTER
        // 4 DIRECT TICKET CENTER
        // 5 and 6 MODAL FORMS TICKET MESSAGE INFO
        // 8 SERVICE DESK NEW ENTRY
        // 9 SERVICE DESK ADD A COMMENT
        // 10 SERVICE DESK EDIT ENTRY
        // 12 SHORCUT CENTER ADD NEW ENTRY
        // 13 SHORCUT CENTER EDIT ENTRY
    */
    switch (type) {
        case 1: return {
            showUsers: false,
            showAccount: true,
            showAgentPlayer: true,
            showSubject: true,
            showSubjetField: true,
            showWebsite: true,
            showRelevance: true,
            showDepartment: true,
            showStatus: true,
            showReplyToEmail: true,
            showShortCuts: true,
            showMessage: true,
            showAttachment: true,
            showInputTextName: false,
            showDescription: false
        };

        case 2: return {
            showUsers: false,
            showAccount: true,
            showAgentPlayer: false,
            showSubject: true,
            showSubjetField: true,
            showWebsite: false,
            showRelevance: false,
            showDepartment: false,
            showStatus: true,
            showReplyToEmail: false,
            showShortCuts: true,
            showMessage: true,
            showAttachment: true,
            showInputTextName: false,
            showDescription: false
        }

        case 3: return {
            showUsers: false,
            showAccount: true,
            showAgentPlayer: false,
            showSubject: true,
            showSubjetField: true,
            showWebsite: false,
            showRelevance: false,
            showDepartment: false,
            showStatus: true,
            showReplyToEmail: false,
            showShortCuts: true,
            showMessage: true,
            showAttachment: true,
            showInputTextName: false,
            showDescription: false
        };

        case 4: return {
            showUsers: true,
            showAccount: true,
            showAgentPlayer: false,
            showSubject: true,
            showSubjetField: true,
            showWebsite: false,
            showRelevance: false,
            showDepartment: false,
            showStatus: true,
            showReplyToEmail: false,
            showShortCuts: true,
            showMessage: true,
            showAttachment: true,
            showInputTextName: false,
            showDescription: false
        }

        case 6:
        case 5: return {
            showUsers: false,
            showAccount: false,
            showAgentPlayer: false,
            showSubject: false,
            showSubjetField: false,
            showWebsite: false,
            showRelevance: false,
            showDepartment: false,
            showStatus: false,
            showReplyToEmail: false,
            showShortCuts: true,
            showMessage: true,
            showAttachment: true,
            showInputTextName: false,
            showDescription: false
        }

        case 7: return {
            showUsers: false,
            showAccount: false,
            showAgentPlayer: false,
            showSubject: false,
            showSubjetField: false,
            showWebsite: false,
            showRelevance: false,
            showDepartment: false,
            showStatus: false,
            showReplyToEmail: false,
            showShortCuts: true,
            showMessage: true,
            showAttachment: false,
            showInputTextName: false,
            showDescription: false
        }

        case 8: return {
            showUsers: false,
            showAccount: false,
            showAgentPlayer: false,
            showSubject: false,
            showSubjetField: false,
            showWebsite: false,
            showRelevance: false,
            showDepartment: false,
            showStatus: true,
            showEmailSubject: true,
            showTicket: true,
            showReplyToEmail: false,
            showShortCuts: false,
            showMessage: true,
            showAttachment: false,
            showInputTextName: false,
            showDescription: false
        }

        case 9: return {
            showUsers: false,
            showAccount: false,
            showAgentPlayer: false,
            showSubject: false,
            showSubjetField: false,
            showWebsite: false,
            showRelevance: false,
            showDepartment: false,
            showStatus: false,
            showEmailSubject: false,
            showTicket: false,
            showReplyToEmail: false,
            showShortCuts: false,
            showMessage: true,
            showAttachment: false,
            showInputTextName: false,
            showDescription: false
        }

        case 10: return {
            showUsers: false,
            showAccount: false,
            showAgentPlayer: false,
            showSubject: false,
            showSubjetField: false,
            showWebsite: false,
            showRelevance: false,
            showDepartment: true,
            showStatus: true,
            showEmailSubject: true,
            showTicket: true,
            showReplyToEmail: false,
            showShortCuts: false,
            showMessage: false,
            showAttachment: false,
            showInputTextName: false,
            showDescription: false
        }

        case 12:
        case 13: return {
            showUsers: false,
            showAccount: false,
            showAgentPlayer: false,
            showSubject: false,
            showSubjetField: false,
            showWebsite: false,
            showRelevance: false,
            showDepartment: true,
            showStatus: false,
            showEmailSubject: false,
            showTicket: false,
            showReplyToEmail: false,
            showShortCuts: false,
            showMessage: true,
            showAttachment: false,
            showInputTextName: true,
            showDescription: true
        }

        default: return null;
    }
}

export function removeTicketObjectWithId(arr: any, IdTicket: number) {
    const objWithIdIndex = arr.findIndex((obj: any) => obj.IdTicket === IdTicket);
    if (objWithIdIndex > -1) {
        arr.splice(objWithIdIndex, 1);
    }

    return arr;
}


export function DeleteUserById(arr: any, IdUser: number) {
    const objWithIdIndex = arr.findIndex((obj: any) => obj.IdUser === IdUser);
    if (objWithIdIndex > -1) {
        arr.splice(objWithIdIndex, 1);
    }

    return arr;
}


export enum Methods {
    POST_LOGIN_USER = 'Ticket/Login',
    POST_SEND_ACCESS_TOKEN = 'Ticket/GetAccessToken',
    POST_SEND_ACCESS_TFA_TOKEN = 'Ticket/GetAccessTFA', // no esta en swagger
    POST_INSERT_ACCESS_TOKEN = 'Ticket/InsertTokenAccess',
    GET_TWO_FACTOR_SETTINGS = 'Ticket/GetSettingsTwoFactor',
    UPDATE_ENABLE_TWO_FACTOR = 'Ticket/EnableTwoFactor',
    GET_SMART_LOGIN_SELECTION = 'Ticket/GetSmartLoginSelection',
    GET_CONFIG_BY_DOMAIN = 'TicketPlayer/GetConfigByDomain',
    UPDATE_SMART_LOGIN = 'Ticket/UpdateSmartLoginSelection',
    GET_ALL_TICKET_INFO = 'Ticket/GetAllInfo',
    GET_MENU_ITEM_BY_ID_USER = 'Ticket/GetMenuItemByUser?idUser=',
    GET_ALL_SITE_TAGS = 'Ticket/GetSiteTag',
    GET_MY_TICKETS = 'Ticket/GetMyTickets',
    GET_ALL_SUBJECTS = 'Ticket/GetSubjects',
    GET_DEPARTMENT_LIST = 'Ticket/GetDepartmentList',
    GET_STATUS_LIST = 'Ticket/GetStatusList',
    GET_RELEVANCE_LIST = 'Ticket/GetRelevanceList',
    POST_RELEASE_TICKET = 'Ticket/ReleaseTicket?IdTicket=',
    GET_USER_INFO_BY_ID = 'Ticket/GetUserInfoByIdUser?idUser=',
    GET_USER_BY_DEPARTMENT = 'Ticket/GetUserByDepartment?ticket=',
    POST_ADD_USER_INTO_TICKET = 'Ticket/InsertUpdateAssignedUser',
    UPDATE_TICKET_INFORMATION = 'Ticket/UpdateTicketInformation',
    UPDATE_NOTIFICATION_BY_TICKET = 'Ticket/UpdateNotificationsByTicket',
    UPDATE_DEPARTMENT_ASSIGNED = 'Ticket/ChangeDepartmentAssigned',
    POST_ADD_SERVICE_DESK_TICKET = 'Ticket/AddServiceDeskTicketService',
    GET_ALL_MESSAGE_BY_TICKET = 'Ticket/GetAllMessageByTicket?idTicket=',
    GET_SHORTCUTS_BY_DEPARTMENT = 'Ticket/GetShortcutsByDepartment?IdDepartment=',
    GET_SUBJECT_FIELD_FOR_CTSADMIN_PROFILE = 'Ticket/GetSubjectFieldForCTSAdminProfile',
    GET_SUBJECT_FIELD_BY_ID = 'Ticket/GetSubjectField?idSub=',
    POST_CREATE_NEW_TICKET = 'Ticket/CreateNewTicket',
    POST_CREATE_NEW_TICKET_MESSAGE = 'Ticket/CreateNewTicketMessage',
    UPDATE_MESSAGE_BY_USER = 'Ticket/EditMessage',
    GET_ALL_USERS = 'Ticket/GetAllUsers',
    GET_WEBSITE_SETUP_BY_SITE = 'Ticket/GetWebsiteSetupBySite?idSite=',
    UPDATE_TICKET_STATUS = 'Ticket/UpdateOpenTicketStatus',
    GET_SITE_BY_USER = 'Ticket/GetSiteByUser?IdUser=',
    GET_DOMAIN_BY_ID_SITE = 'Ticket/GetDomainBySite?idSite=',
    UPDATE_DOMAIN_BY_ID_SITE = 'Ticket/UpdateDomainByIdSite',
    GET_AGENTS_WEBSITE_SETUP_BY_SITE = 'Ticket/GetAgentsWebsiteSetupBySite?idSite=',
    UPDATE_WEBSITE_SETUP = 'Ticket/UpdateItemWebsiteSetup',
    UPDATE_ALL_ITEM_WEBSITE_SETUP = 'Ticket/UpdateAllItemWebsiteSetup',
    UPDATE_ALL_ITEM_CTS_ADMIN_WEBSITES_SETUP = 'Ticket/UpdateAllItemCtsAdminWebsiteSetup',
    UPDATE_ITEM_CTS_ADMIN_WEBSITE_SETUP = 'Ticket/UpdateItemCtsAdminWebsiteSetup',
    UPDATE_ITEM_WEBSITE_SETUP = 'Ticket/UpdateItemAgentWebsiteSetup',
    UPDATE_ALL_ITEM_AGENT_WEBSITE_SETUP = 'Ticket/UpdateAllItemAgentWebsiteSetup',
    POST_SAVE_UPDATE_FOR_ALL_SITES = 'Ticket/SaveUpdateForAllSites', // no esta en swagger
    POST_SAVE_UPDATE_FOR_ALL_AGENT_SITES = 'Ticket/SaveUpdateForAllAgentSites', // no esta en swagger
    UPDATE_SORT_AGENT_WEBSITE = 'Ticket/SortAgentWebsite',
    UPDATE_SORT_WEBSITE_SETUP = 'Ticket/SortWebsiteSetup',
    UPDATE_SORT_WEBSITE_FIELD_SETUP = 'Ticket/SortWebsiteFieldsSetup',
    UPDATE_SORT_AGENT_WEBSITE_FIELD_SETUP = 'Ticket/SortAgentWebsiteFieldsSetup',
    GET_SUBJECT_FIELD_BY_SITE = 'Ticket/GetSubjectFieldBySite',
    GET_SUBJECT_FIELD_BY_AGENT_SITE = 'Ticket/GetSubjectFieldByAgentSite',
    GET_SUBJECT_FIELD_BY_CTS_ADMIN_SITE = 'Ticket/GetSubjectFieldByCTSAdminSite',
    UPDATE_SORT_CTS_ADMIN_WEBSITE_FIELD_SETUP = 'Ticket/SortCTSAdminWebsiteFieldsSetup',
    UPDATE_ITEM_WEBSITE_FIELD_SETUP = 'Ticket/UpdateItemWebsiteFieldSetup',
    UPDATE_ITEM_AGENT_WEBSITE_FIELD_SETUP = 'Ticket/UpdateItemAgentWebsiteFieldSetup',
    UPDATE_ALL_ITEM_WEBSITE_FIELD_SETUP = 'Ticket/UpdateAllItemWebsiteFieldSetup',
    UPDATE_ALL_ITEM_AGENT_WEBSITE_FIELD_SETUP = 'Ticket/UpdateAllItemAgentWebsiteFieldSetup',
    POST_SAVE_UPDATE_FIELDS_FOR_ALL_SITES = 'Ticket/SaveUpdateFieldsForAllSites', // no esta en swagger
    POST_SAVE_UPDATE_FIELDS_FOR_ALL_AGENT_SITES = 'Ticket/SaveUpdateFieldsForAllAgentSites', // no esta en swagger
    GET_CTS_ADMINWEBSITE_SETUP_BY_ID_SITE = 'Ticket/GetCtsAdminWebsiteSetupBySite?idSite=',
    UPDATE_ITEM_CTS_ADMIN_WEBSITE_FIELD_SETUP = 'Ticket/UpdateItemCTSAdminWebsiteFieldSetup',
    UPDATE_ALL_ITEM_CTS_ADMIN_FIELD_SETUP = 'Ticket/UpdateAllItemCTSadminFieldSetup',
    UPDATE_SORT_CTS_ADMIN_WEBSITE_SETUP = 'Ticket/SortCTSAdminWebsiteSetup',
    GET_ALL_WEBSITES_PROFILES_TO_SELECT = 'Ticket/GetAllWebsitesProfilesToSelect',
    POST_INSERT_WEBSITE_CONFIG_BY_PROFILE = 'Ticket/InsertWebsiteConfigurationByProfile',  // no esta en swagger
    GET_EPOS_SETUP_BY_ID_SITE = 'Ticket/GetEposSetupBySite?idSite=',
    POST_UPDATE_ALL_ITEM_EPOS_SETUP = 'Ticket/UpdateAllItemEposSetup', // no esta en swagger
    UPDATE_ITEM_EPOS_SETUP = 'Ticket/UpdateItemEposSetup',
    GET_SUBJECT_FIELD_BY_EPOS_SITE = 'Ticket/GetSubjectFieldByEposSite',
    UPDATE_SORT_EPOS_FIELD_SETUP = 'Ticket/SortEposFieldsSetup',
    UPDATE_ITEM_EPOS_FIELD_SETUP = 'Ticket/UpdateItemEposFieldSetup',
    UPDATE_ALL_ITEM_EPOS_FIELD_SETUP = 'Ticket/UpdateAllItemEposFieldSetup',
    UPDATE_SORT_EPOS_SETUP = 'Ticket/SortEposSetup',
    GET_EPOS_ADMIN_SETUP_BY_ID_SITE = 'Ticket/GetEposAdminSetupBySite?idSite=',
    UPDATE_ALL_ITEM_EPOS_ADMIN_SETUP = 'Ticket/UpdateAllItemEposAdminSetup',
    UPDATE_ITEM_EPOS_ADMIN_SETUP = 'Ticket/UpdateItemEposAdminSetup',
    GET_SUBJET_FIELD_BY_EPOS_ADMIN_SITE = 'Ticket/GetSubjectFieldByEposAdminSite',
    UPDATE_SORT_EPOS_ADMIN_FIELD_SETUP = 'Ticket/SortEposAdminFieldsSetup',
    UPDATE_ITEM_EPOS_ADMIN_FIELD_SETUP = 'Ticket/UpdateItemEposAdminFieldSetup',
    UPDATE_ALL_ITEM_EPOS_ADMIN_FIELD_SETUP = 'Ticket/UpdateAllItemEposAdminFieldSetup',
    UPDATE_SORT_EPOS_ADMIN_SETUP = 'Ticket/SortEposAdminSetup',
    GET_ALL_PROFILES = 'Ticket/GetAllProfiles',
    GET_DEPARMENT_BY_USER = 'Ticket/GetDepartmentsByUser?idUser=',
    DELETE_USER = 'Ticket/DeleteUser',
    POST_INSERT_NEW_USER = 'Ticket/InsertNewUser',
    GET_USER_COMPONET_USER_ADMIN = 'Ticket/GetUserComponentsUserAdmin?idUser=',
    GET_DEPARTMENT_LIST_BY_IDUSER = 'Ticket/GetDepartmentListByUser?idUser=',

    UPDATE_USER_INFO = 'Ticket/UpdateUserInfo?TipoUpdate=',
    UPDATE_VIEW_ONLY_BY_USER = 'Ticket/UpdateViewOnlyByUser',
    UPDATE_RELEASE_TICKET_BY_USER = 'Ticket/UpdateReleaseTicketByUser',
    UPDATE_TFA_SELECTION = 'Ticket/UpdateTFASelection',

    POST_INSERT_NEW_RELEVANCE_LEVEL = 'Ticket/InsertNewRelevance',
    UPDATE_RELEVANCE_LEVEL_ITEM = 'Ticket/UpdateItemRelevanceLevel',
    DELETE_RELEVANCE_ITEM = 'Ticket/DeleteRelevance',
    UPDATE_ALL_ITEM_RELEVANCE_LEVEL = 'Ticket/UpdateAllItemRelevanceLevel',

    POST_INSERT_NEW_TICKET_STATUS = 'Ticket/InsertNewTicketStatus',
    UPDATE_TICKET_STATUS_ITEM = 'Ticket/UpdateItemTicketStatus',
    DELETE_STATUS_ITEM = 'Ticket/DeleteStatus',
    UPDATE_ALL_TICKET_STATUS_ITEM = 'Ticket/UpdateAllItemTicketStatus',

    POST_INSERT_NEW_DEPARTMENT = 'Ticket/InsertNewDepartment',
    UPDATE_DEPARTMENT_ITEM = 'Ticket/UpdateItemDepartment',
    DELETE_DEPARTMENT_ITEM = 'Ticket/DeleteDepartment',
    UPDATE_ALL_ITEM_DEPARTMENT = 'Ticket/UpdateAllItemDepartment',

    POST_INSERT_NEW_USER_BY_SITE = 'Ticket/InsertNewUserBySite',
    DELETE_SITE_FOR_USER = 'Ticket/DeleteSiteForUser',
    UPDATE_USER_RIGHTS = 'Ticket/UpdateUserRights',
    UPDATE_DEPARTMENTS_BY_USER = 'Ticket/UpdateDepartmentsByUser',

    GET_TICKET_LOGS = 'Ticket/GetTicketLogs',
    GET_USER_LOGS = 'Ticket/GetUserLogs',
    GET_SUBJECT_LOGS = 'Ticket/GetSubjectLogs',
    GET_SUBJECT_FIELDS_LOGS = 'Ticket/GetSubjectFieldsLogs',
    GET_RELEVANCE_LOGS = 'Ticket/GetRelevanceLogs',
    GET_STATUS_LOGS = 'Ticket/GetStatusLogs',
    GET_DEPARTMENT_LOGS = 'Ticket/GetDepartmentLogs',

    GET_BRAND_SET_UP_BY_ID_SITE = 'Ticket/GetBrandSetup?IdSite=',
    GET_BRAND_SELECTED_BY_ID_SITE = 'Ticket/GetBrandSites?IdBrandSetup=',

    POST_UPDATE_INSERT_BRAND_SETUP = 'Ticket/UpdateInsertBrandSetup',
    POST_UPDATE_INSERT_BRAND_SITE = 'Ticket/UpdateInsertBrandSite',
    DELETE_SITE_FOR_BRAND = 'Ticket/DeleteSiteForBrand',

    GET_ALL_FORM_TYPE = 'Ticket/GetAllFormType',
    POST_INSERT_NEW_SUBJECT = 'Ticket/InsertNewSubject',
    UPDATE_SUBJECT_ITEM = 'Ticket/UpdateItemSubject',
    DELETE_SUBJECT = 'Ticket/DeleteSubject',
    POST_INSERT_NEW_SUBJECT_FIELD = 'Ticket/InsertNewSubjectField',
    UPDATE_SUBJECT_FIELD_ITEM = 'Ticket/UpdateItemSubjectField',
    UPDATE_ALL_SUBJECT_ITEM = 'Ticket/UpdateAllItemSubject',
    UPDATE_ALL_SUBJECT_FIELD_ITEM = 'Ticket/UpdateAllItemSubjectFields',
    DELETE_SUBJET_FIELD = 'Ticket/DeleteSubjectField',
    UPDATE_SORT_SUBJECTS = 'Ticket/SortSubjects',

    GET_ALL_TASK_NOTES = 'Ticket/GetAllTaskNotes?idDepartment=',
    POST_INSERT_NEW_TASK_NOTE = 'Ticket/InsertNewTaskNote',
    POST_INSERT_NEW_TASK_NOTE_MESSAGE = 'Ticket/InsertNewTaskNoteMessage',
    UPDATE_TASK_NOTE_INFO = 'Ticket/UpdateTaskNoteInfo',
    DELETE_TASK_NOTE = 'Ticket/DeleteTaskNote',
    GET_USER_NAME_BY_DEPARTMENT = 'Ticket/GetUserNameByDepartment?idDepartment=',

    GET_ALL_SHORCUTS = 'Ticket/GetAllShortcuts',
    POST_INSERT_NEW_SHORTCUT = 'Ticket/InsertNewShortcut',
    DELETE_SHORTCUT = 'Ticket/DeleteShortcut',
    GET_ALL_TASK_NOTE_MESSAGE = 'Ticket/GetAllTaskNoteMessages?idNote=',
    UPDATE_SHORT_CUT_INFO = 'Ticket/UpdateShortcutInfo',

    GET_ALL_WEBSITES_PROFILES = 'Ticket/GetAllWebsitesProfiles',
    UPDATE_PROFILE = 'Ticket/UpdateProfile',
    POST_INSERT_NEW_SITE_BY_PROFILE = 'Ticket/InsertNewSiteByProfile',
    DELETE_SITE_FOR_PROFILE = 'Ticket/DeleteSiteForProfile',

    GET_SITE_TAG_COMPONENTS = 'Ticket/GetTagComponents',
    POST_INSERT_NEW_PROFILE = 'Ticket/InsertNewProfile',
    POST_INSERT_NEW_SITE_PROFILE = 'Ticket/CreateNewSiteProfile',
    UPDATE_SITE_PROFILE = 'Ticket/UpdateSiteProfile',

    GET_PERSONAL_WORKLOG_BY_ID = 'Ticket/GetPersonalWorkLog?IdUser=',
    GET_ALL_ISSUES_BY_DEPARTMENT_ID = 'Ticket/GetAllIssuesByDepartment?idDepartment=',
    GET_ALL_USER_BY_DEPARTMENT_ID = 'Ticket/GetAllUserByDepartment?idDepartment=',
    GET_SOURCE = 'Ticket/GetSource',
    GET_PERSONAL_WORKLOG_BY_DATE = 'Ticket/GetPersonalWorkLogByDate',
    POST_INSERT_NEW_PERSONAL_WORKLOG = 'Ticket/InsertPersonalWorkLog',
    POST_INSERT_NEW_COMMENT = 'Ticket/SaveNewComment',

    GET_OPEN_TICKET_STATUS = 'Ticket/GetOpenTicketStatus?idTicket=',
    UPDATE_LANGUAGE_BY_USER = 'Ticket/UpdateLanguageByUser'
}

export enum GetMethods {
    GET_CONFIG_BY_DOMAIN = '/api/ConfigByDomain/GetConfigByDomain',

    //BRAND SETUP
    GET_BRAND_SET_UP_BY_ID_SITE = '/api/BrandSetup/GetBrandSetup?IdSite=',
    GET_BRAND_SELECTED_BY_ID_SITE = '/api/BrandSetup/GetBrandSites?IdBrandSetup=',

    //DEPARTMENT
    GET_DEPARTMENT_LIST = '/api/Department/GetDepartmentList',
    GET_DEPARTMENT_LOGS = '/api/Department/GetDepartmentLogs?startDate=',
    GET_DEPARTMENT_LIST_BY_IDUSER = '/api/Department/GetDepartmentListByUser?idUser=',

    //DGSDATA
    GET_ALL_SITE_TAGS = '/api/DGSDATA/GetSiteTags',

    //PERSONALWORKLOG
    GET_ALL_USER_BY_DEPARTMENT_ID = '/api/PersonalWorklog/GetAllUserByDepartment?IdDepartment=',
    GET_USER_INFO_BY_ID = '/api/PersonalWorklog/GetUserInfoByIdUser?IdUser=',
    GET_ALL_ISSUES_BY_DEPARTMENT_ID = '/api/PersonalWorklog/GetAllIssuesByDepartment?IdDepartment=',
    GET_PERSONAL_WORKLOG_BY_ID = '/api/PersonalWorklog/GetPersonalWorkLog?IdUser=',
    GET_SOURCE = '/api/PersonalWorklog/GetSource',
    GET_PERSONAL_WORKLOG_BY_DATE = '/api/PersonalWorklog/GetPersonalWorkLogByDate?IdUser=',

    //RELEVANCE
    GET_RELEVANCE_LIST = '/api/Relevance/GetRelevanceList',
    GET_RELEVANCE_LOGS = '/api/Relevance/GetRelevanceLogs?startDate=',

    //SERVICE DESK
    GET_ALL_TASK_NOTES = '/api/ServiceDesk/GetAllTaskNotes?IdDepartment=',
    GET_ALL_TASK_NOTE_MESSAGE = '/api/ServiceDesk/GetAllTaskNoteMessages?IdNote=',
    GET_USER_NAME_BY_DEPARTMENT = '/api/ServiceDesk/GetUserNameByDepartment?idDepartment=',

    //STATUS
    GET_STATUS_LIST = '/api/Status/GetStatusList',
    GET_STATUS_LOGS = '/api/Status/GetStatusLogs',

    //SUBJECT
    GET_ALL_SUBJECTS = '/api/Subject/GetSubjects',
    GET_SUBJECT_LOGS = '/api/Subject/GetSubjectLogs?startDate=',
    GET_ALL_FORM_TYPE = '/api/Subject/GetAllFormType',
    GET_SUBJECTS_BY_CTS_ADMIN_PROFILE = '/api/Subject/GetSubjectsByCtsAdminProfile',

    //SUBJECTFIELD
    GET_SUBJECT_FIELD_BY_ID = '/api/SubjectField/GetSubjectField?IdSubject=',
    GET_SUBJECT_FIELD_BY_SITE = '/api/SubjectField/GetSubjectFieldBySite?IdSite=',
    GET_SUBJECT_FIELD_BY_AGENT_SITE = '/api/SubjectField/GetSubjectFieldByAgentSite?IdSite=',
    GET_SUBJECT_FIELDS_LOGS = '/api/SubjectField/GetSubjectFieldsLogs?startDate=',
    GET_SUBJECT_FIELD_BY_CTS_ADMIN_SITE = '/api/SubjectField/GetSubjectFieldByCTSAdminSite?IdSite=',
    GET_SUBJECT_FIELD_FOR_CTSADMIN_PROFILE = '/api/SubjectField/GetSubjectFieldForCTSAdminProfile?IdSite=',
    GET_SUBJECT_FIELD_BY_EPOS_SITE = '/api/SubjectField/GetSubjectFieldByEposSite?IdSite=',
    GET_SUBJET_FIELD_BY_EPOS_ADMIN_SITE = '/api/SubjectField/GetSubjectFieldByEposAdminSite?IdSite=',

    //TICKET
    GET_ALL_TICKET_INFO = '/api/Ticket/GetAllInfo',
    GET_MY_TICKETS = '/api/Ticket/GetMyTickets',
    GET_ALL_MESSAGE_BY_TICKET = '/api/Ticket/GetAllMessageByTicket?IdTicket=',
    GET_OPEN_TICKET_STATUS = '/api/Ticket/GetOpenTicketStatus?IdTicket=',
    GET_USER_BY_DEPARTMENT = '/api/Ticket/GetUserByDepartment?ticket=',

    //TWO_FACTOR_AUTHENTICATOR
    GET_TWO_FACTOR_SETTINGS = '/api/TwoFactorAuthenticator/GetSettingsTwoFactor',
    GET_VALIDATION_TWO_FACTOR_TOKEN = '/api/TwoFactorAuthenticator/ValidateTwoFactorPIN?Email=',

    //USER
    GET_ALL_USERS = '/api/User/GetAllUsers',
    GET_USER_COMPONET_USER_ADMIN = '/api/User/GetUserComponentsUserAdmin?IdUser=',
    GET_DEPARMENT_BY_USER = '/api/User/GetDepartmentsByUser?idUser=',
    GET_SITE_BY_USER = '/api/User/GetSiteByUser?IdUser=',
    GET_USER_LOGS = '/api/User/GetUserLogs?startDate=',

    //SETTINGS
    GET_MENU_ITEM_BY_ID_USER = '/api/Settings/GetMenuItemByUser?IdUser=',
    GET_TICKETS_COUNTER_BY_ID_USER = '/api/Ticket/GetTicketsCount?IdUser=',
    GET_SMART_LOGIN_SELECTION = '/api/Settings/GetSmartLoginSelection?IdUser=',
    GET_USER_PREFERENCE= '/api/Settings/GetUserPreferences?IdUser=',

    //SHORTCUT
    GET_ALL_SHORCUTS = '/api/Shortcut/GetAllShortcuts',
    GET_SHORTCUTS_BY_DEPARTMENT = '/api/Shortcut/GetShortcutsByDepartment?IdDepartment=',

    //PROFILE
    GET_ALL_PROFILES = '/api/Profile/GetAllProfiles',
    GET_SITE_TAG_COMPONENTS = '/api/Profile/GetTagComponents',
    GET_ALL_WEBSITES_PROFILES = '/api/Profile/GetAllWebsitesProfiles',

    //WEBSITESETUP
    GET_WEBSITE_SETUP_BY_SITE = '/api/WebsiteSetup/GetWebsiteSetupBySite?IdSite=',
    GET_DOMAIN_BY_ID_SITE = '/api/WebsiteSetup/GetDomainBySite?IdSite=',
    GET_AGENTS_WEBSITE_SETUP_BY_SITE = '/api/WebsiteSetup/GetAgentsWebsiteSetupBySite?IdSite=',
    GET_CTS_ADMINWEBSITE_SETUP_BY_ID_SITE = '/api/WebsiteSetup/GetCtsAdminWebsiteSetupBySite?IdSite=',
    GET_EPOS_SETUP_BY_ID_SITE = '/api/WebsiteSetup/GetEposSetupBySite?IdSite=',
    GET_EPOS_ADMIN_SETUP_BY_ID_SITE = '/api/WebsiteSetup/GetEposAdminSetupBySite?IdSite=',

    //LOGS
    GET_TICKET_LOGS = '/api/Ticket/GetTicketLogs?startDate=',

}

export enum PostMethods {
    //LOGIN
    POST_LOGIN_USER = '/api/Login',
    POST_INSERT_ACCESS_TOKEN = '/api/Login/InsertTokenAccess',
    POST_SEND_ACCESS_TOKEN = '/api/Ticket/GetAccessToken',

    //BRAND SETUP
    POST_UPDATE_INSERT_BRAND_SETUP = '/api/BrandSetup/UpdateInsertBrandSetup',
    POST_UPDATE_INSERT_BRAND_SITE = '/api/BrandSetup/UpdateInsertBrandSite',

    //DEPARTMENT
    POST_INSERT_NEW_DEPARTMENT = '/api/Department/InsertNewDepartment',

    //PERSONALWORKLOG
    POST_INSERT_NEW_PERSONAL_WORKLOG = '/api/PersonalWorklog/InsertPersonalWorkLog',
    POST_INSERT_NEW_COMMENT = '/api/PersonalWorklog/SaveNewComment',

    //RELEVANCE
    POST_INSERT_NEW_RELEVANCE_LEVEL = '/api/Relevance/InsertNewRelevance',

    //STATUS
    POST_INSERT_NEW_TICKET_STATUS = '/api/Status/InsertNewTicketStatus',

    //SUBJECT
    POST_INSERT_NEW_SUBJECT = '/api/Subject/InsertNewSubject',

    //SERVICE DESK
    POST_INSERT_NEW_TASK_NOTE = '/api/ServiceDesk/InsertNewTaskNote',
    POST_INSERT_NEW_TASK_NOTE_MESSAGE = '/api/ServiceDesk/InsertNewTaskNoteMessage',

    //SHORTCUT
    POST_INSERT_NEW_SHORTCUT = '/api/Shortcut/InsertNewShortcut',

    //SUBJECTFIELD
    POST_INSERT_NEW_SUBJECT_FIELD = '/api/SubjectField/InsertNewSubjectField',

    //PROFILE
    POST_INSERT_NEW_SITE_BY_PROFILE = '/api/Profile/InsertNewSiteByProfile',
    POST_INSERT_NEW_PROFILE = '/api/Profile/InsertNewProfile',
    POST_INSERT_NEW_SITE_PROFILE = '/api/Profile/CreateNewSiteProfile',

    //WEBSITESETUP
    POST_INSERT_WEBSITE_CONFIG_BY_PROFILE = '/api/WebsiteSetup/InsertWebsiteConfigurationByProfile',

    //TICKET
    POST_CREATE_NEW_TICKET = '/api/Ticket/CreateNewTicket',
    POST_CREATE_NEW_TICKET_MESSAGE = '/api/Ticket/CreateNewTicketMessage',
    POST_RELEASE_TICKET = '/api/Ticket/ReleaseTicket',
    POST_ADD_USER_INTO_TICKET = '/api/Ticket/InsertUpdateAssignedUser',
    POST_ADD_SERVICE_DESK_TICKET = '/api/Ticket/AddServiceDeskTicketService',

    //USER
    POST_INSERT_NEW_USER = '/api/User/InsertNewUser',
    POST_INSERT_NEW_USER_BY_SITE = '/api/User/InsertNewUserBySite',

    POST_INSERT_USER_PREFERENCE = '/api/Settings/UpdateInsertUserPreferences',

    POST_PURGE = '/api/ConfigByDomain/Purge',

    INSERT_NOTIFICATIONS_BY_TICKET = '/api/Ticket/InsertNotificationsByTicket',
}

export enum PutMethods {
    //DEPARTMENT
    UPDATE_DEPARTMENT_ITEM = '/api/Department/UpdateItemDepartment',
    UPDATE_ALL_ITEM_DEPARTMENT = '/api/Department/UpdateAllItemDepartment',

    //RELEVANCE
    UPDATE_RELEVANCE_LEVEL_ITEM = '/api/Relevance/UpdateItemRelevanceLevel',
    UPDATE_ALL_ITEM_RELEVANCE_LEVEL = '/api/Relevance/UpdateAllItemRelevanceLevel',

    //STATUS
    UPDATE_TICKET_STATUS_ITEM = '/api/Status/UpdateItemTicketStatus',
    UPDATE_ALL_TICKET_STATUS_ITEM = '/api/Status/UpdateAllItemTicketStatus',

    //SUBJECT
    UPDATE_SUBJECT_ITEM = '/api/Subject/UpdateItemSubject',
    UPDATE_ALL_SUBJECT_ITEM = '/api/Subject/UpdateAllItemSubject',
    UPDATE_SORT_SUBJECTS = '/api/Subject/SortSubjects',

    //SERVICE DESK
    UPDATE_TASK_NOTE_INFO = '/api/ServiceDesk/UpdateTaskNoteInfo',

    //SHORTCUT
    UPDATE_SHORT_CUT_INFO = '/api/Shortcut/UpdateShortcutInfo',

    //SUBJECTFIELD
    UPDATE_SUBJECT_FIELD_ITEM = '/api/SubjectField/UpdateItemSubjectField',
    UPDATE_ALL_SUBJECT_FIELD_ITEM = '/api/SubjectField/UpdateAllItemSubjectFields',

    //TICKET
    UPDATE_TICKET_INFORMATION = '/api/Ticket/UpdateTicketInformation',
    UPDATE_DEPARTMENT_ASSIGNED = '/api/Ticket/ChangeDepartmentAssigned',
    UPDATE_TICKET_STATUS = '/api/Ticket/UpdateOpenTicketStatus',
    UPDATE_NOTIFICATION_BY_TICKET = '/api/Ticket/UpdateNotificationsByTicket',
    UPDATE_MESSAGE_BY_USER = '/api/Ticket/EditMessage',

    //TWO_FACTOR_AUTHENTICATOR
    UPDATE_ENABLE_TWO_FACTOR = '/api/TwoFactorAuthenticator/EnableTwoFactor',

    //SETTINGS
    UPDATE_LANGUAGE_BY_USER = '/api/Settings/UpdateLanguageByUser',
    UPDATE_SMART_LOGIN = '/api/Settings/UpdateSmartLoginSelection',

    UPDATE_CLOSE_DATE_TICKETS = '/api/Ticket/UpdateCloseDateTickets',

    //PROFILE
    UPDATE_PROFILE = '/api/Profile/UpdateProfile',
    UPDATE_SITE_PROFILE = '/api/Profile/UpdateSiteProfile',

    //USER
    UPDATE_USER_INFO = '/api/User/UpdateUserInfo',
    UPDATE_USER_RIGHTS = '/api/User/UpdateUserRights',
    UPDATE_ALL_USER_RIGHTS = '/api/User/UpdateAllUserRights',
    UPDATE_DEPARTMENTS_BY_USER = '/api/User/UpdateDepartmentsByUser',
    UPDATE_VIEW_ONLY_BY_USER = '/api/User/UpdateViewOnlyByUser',
    UPDATE_RELEASE_TICKET_BY_USER = '/api/User/UpdateReleaseTicketByUser',
    UPDATE_TFA_SELECTION = '/api/User/UpdateTFASelection',

    //WEBSITESETUP
    UPDATE_DOMAIN_BY_ID_SITE = '/api/WebsiteSetup/UpdateDomainByIdSite',
    UPDATE_WEBSITE_SETUP = '/api/WebsiteSetup/UpdateItemWebsiteSetup',
    UPDATE_ALL_ITEM_WEBSITE_SETUP = '/api/WebsiteSetup/UpdateAllItemWebsiteSetup',
    UPDATE_ALL_ITEM_CTS_ADMIN_WEBSITES_SETUP = '/api/WebsiteSetup/UpdateAllItemCtsAdminWebsiteSetup',
    UPDATE_ITEM_CTS_ADMIN_WEBSITE_SETUP = '/api/WebsiteSetup/UpdateItemCtsAdminWebsiteSetup',
    UPDATE_ITEM_WEBSITE_SETUP = '/api/WebsiteSetup/UpdateItemAgentWebsiteSetup',
    UPDATE_ALL_ITEM_AGENT_WEBSITE_SETUP = '/api/WebsiteSetup/UpdateAllItemAgentWebsiteSetup',
    UPDATE_SORT_AGENT_WEBSITE = '/api/WebsiteSetup/SortAgentWebsite',
    UPDATE_SORT_WEBSITE_SETUP = '/api/WebsiteSetup/SortWebsiteSetup',
    UPDATE_SORT_WEBSITE_FIELD_SETUP = '/api/WebsiteSetup/SortWebsiteFieldsSetup',
    UPDATE_SORT_AGENT_WEBSITE_FIELD_SETUP = '/api/WebsiteSetup/SortAgentWebsiteFieldsSetup',
    UPDATE_SORT_CTS_ADMIN_WEBSITE_FIELD_SETUP = '/api/WebsiteSetup/SortCTSAdminWebsiteFieldsSetup',
    UPDATE_ITEM_WEBSITE_FIELD_SETUP = '/api/WebsiteSetup/UpdateItemWebsiteFieldSetup',
    UPDATE_ITEM_AGENT_WEBSITE_FIELD_SETUP = '/api/WebsiteSetup/UpdateItemAgentWebsiteFieldSetup',
    UPDATE_ALL_ITEM_WEBSITE_FIELD_SETUP = '/api/WebsiteSetup/UpdateAllItemWebsiteFieldSetup',
    UPDATE_ALL_ITEM_AGENT_WEBSITE_FIELD_SETUP = '/api/WebsiteSetup/UpdateAllItemAgentWebsiteFieldSetup',
    UPDATE_ITEM_CTS_ADMIN_WEBSITE_FIELD_SETUP = '/api/WebsiteSetup/UpdateItemCTSAdminWebsiteFieldSetup',
    UPDATE_ALL_ITEM_CTS_ADMIN_FIELD_SETUP = '/api/WebsiteSetup/UpdateAllItemCTSadminFieldSetup',
    UPDATE_SORT_CTS_ADMIN_WEBSITE_SETUP = '/api/WebsiteSetup/SortCTSAdminWebsiteSetup',
    UPDATE_ITEM_EPOS_SETUP = '/api/WebsiteSetup/UpdateItemEposSetup',
    UPDATE_SORT_EPOS_FIELD_SETUP = '/api/WebsiteSetup/SortEposFieldsSetup',
    UPDATE_ITEM_EPOS_FIELD_SETUP = '/api/WebsiteSetup/UpdateItemEposFieldSetup',
    UPDATE_ALL_ITEM_EPOS_FIELD_SETUP = '/api/WebsiteSetup/UpdateAllItemEposFieldSetup',
    UPDATE_SORT_EPOS_SETUP = '/api/WebsiteSetup/SortEposSetup',
    UPDATE_ALL_ITEM_EPOS_ADMIN_SETUP = '/api/WebsiteSetup/UpdateAllItemEposAdminSetup',
    UPDATE_ITEM_EPOS_ADMIN_SETUP = '/api/WebsiteSetup/UpdateItemEposAdminSetup',
    UPDATE_SORT_EPOS_ADMIN_FIELD_SETUP = '/api/WebsiteSetup/SortEposAdminFieldsSetup',
    UPDATE_ITEM_EPOS_ADMIN_FIELD_SETUP = 'Ticket/UpdateItemEposAdminFieldSetup',
    UPDATE_ALL_ITEM_EPOS_ADMIN_FIELD_SETUP = '/api/WebsiteSetup/UpdateAllItemEposAdminFieldSetup',
    UPDATE_SORT_EPOS_ADMIN_SETUP = '/api/WebsiteSetup/SortEposAdminSetup',

}

export enum DeleteMethods {

    //BRAND SETUP
    DELETE_SITE_FOR_BRAND = '/api/BrandSetup/DeleteSiteForBrand',

    //PROFILE
    DELETE_SITE_FOR_PROFILE = '/api/Profile/DeleteSiteForProfile',

    //DEPARTMENT
    DELETE_DEPARTMENT_ITEM = '/api/Department/DeleteDepartment',

    //RELEVANCE
    DELETE_RELEVANCE_ITEM = '/api/Relevance/DeleteRelevance',

    //SERVICE DESK
    DELETE_TASK_NOTE = '/api/ServiceDesk/DeleteTaskNote',

    //STATUS
    DELETE_STATUS_ITEM = '/api/Status/DeleteStatus',

    //SUBJECT
    DELETE_SUBJECT = '/api/Subject/DeleteSubject',

    //SUBJECTFIELD
    DELETE_SUBJET_FIELD = '/api/SubjectField/DeleteSubjectField',

    //SHORTCUT
    DELETE_SHORTCUT = '/api/Shortcut/DeleteShortcut',

    //USER
    DELETE_USER = '/api/User/DeleteUser',
    DELETE_SITE_FOR_USER = '/api/User/DeleteSiteForUser',
}
