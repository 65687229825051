import { Component, OnInit } from '@angular/core';
import { GetMethods, Methods, PostMethods, PutMethods } from '@methods/methods';
import { ResponseData } from '@models/models';
import { ApiService } from '@services/api.service';
import { MediaResponse, MediaService } from '@services/media.service';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
    mediaSubscription: Subscription;
    Media: MediaResponse;
    payload: any;
    payloadData: any;
    payloadData2: any;
    columsData: any;
    tabPanelData: any;
    itemSelected: any = [];
    allSiteTags: any;
    siteTagSelected: any;
    siteTagList: any;
    subjectList: any;
    tabSelected: number = 0;
    loading: boolean = false;
    showExtraInfo: boolean = false;
    allChecked: boolean = false;
    showHardReloadBtn: boolean = false;
    newSiteItemArray: any = [];

    constructor(private _mediaService: MediaService, private _apiService: ApiService, private _notificationService: NotificationService) {
        this.mediaSubscription = this._mediaService.subscribeMedia().subscribe(media => {
            this.Media = media;
        });
    }

    ngOnInit(): void {
        this.tabPanelData = [
            { tabTitle: 'Profile.USER PROFILE MANAGER', id: 0, showTable: true, showNewRegister: false },
            { tabTitle: 'Profile.NEW USER PROFILE', id: 1, showTable: false, showNewRegister: true },
            { tabTitle: 'Profile.SITE PROFILE MANAGER', id: 2, showTable: true, showNewRegister: false },
            { tabTitle: 'Profile.NEW SITE PROFILE', id: 3, showTable: false, showNewRegister: true }
        ]

        this.columsData = this.onHeaderColumnTableOptions(this.tabSelected);

        this.getAllProfilesList();
        this.getAllWebsiteProfilesList();
        this.getAllWebsiteList();
        this.getSiteTag();
        this.getSubjects();
    }

    getTabSelected(tabSelected: number) {
        this.tabSelected = tabSelected;
        this.showExtraInfo = false;
        this.payload = (tabSelected == 0) ? this.payloadData : this.payloadData2;
        this.columsData = this.onHeaderColumnTableOptions(this.tabSelected);
    }

    getAllProfilesList() {
        this.payloadData = []
        const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_PROFILES}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.payloadData = result.data;
                    this.payload = result.data;
                }
            },
            error: (err: any) => {
                this.loading = false;
                console.log(err);
                this.showHardReloadBtn = true;
            }
        });
    }

    getSiteTag() {
        this.siteTagList = []
        const URL = `${environment.WebApiUrl + GetMethods.GET_SITE_TAG_COMPONENTS}`;
        //obtiene la lista en el primer tab del primer card cuando editas el perfil
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.siteTagList.payload = result.data;
                    this.siteTagList.accessSite = [];
                }
            },
            error: (err: any) => {
                this.loading = false;
                console.log(err);
                this.showHardReloadBtn = true;
            }
        });
    }

    getSubjects() {
        this.subjectList = [];
        const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_SUBJECTS}`;
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    //Esto recibe el nombre en los subjects en el cuarto tab
                    result.data.forEach(element => {
                        element.isActive = false;
                        element.title = element.subjectName
                    });
                    this.subjectList.payload = result.data;
                    this.subjectList.field = [];
                    this.subjectList.accessSite = [];
                }
            },
            error: (err: any) => {
                this.loading = false;
                console.log(err);
                this.showHardReloadBtn = true;
            }
        });
    }

    getAllWebsiteProfilesList() {
        this.payloadData2 = []
        const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_WEBSITES_PROFILES}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.payloadData2 = result.data;
                }
            },
            error: (err: any) => {
                this.loading = false;
                console.log(err);
                this.showHardReloadBtn = true;
            }
        });
    }

    getAllWebsiteList() {
        const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_SITE_TAGS}`;
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    result.data.forEach((value: any) => {
                        value.value = value.description;
                        value.label = value.description;
                    });
                    this.allSiteTags = result.data;
                }
            },
            error: (err: any) => {
                this.loading = false;
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);

                console.log(err);
            }
        });
    }

    onHeaderColumnTableOptions(tab: number) {
        switch (tab) {
            case 0: return [
                { field: 'profileName', header: 'Profile.Profile name', },
                { field: 'access', header: 'Profile.Access All sites' },
                { field: 'active', header: 'Profile.Active' },
                { field: 'action', header: 'Profile.Action' }
            ];

            case 2: return [
                { field: 'profileName', header: 'Profile.Profile name', },
                { field: 'active', header: 'Profile.Active' },
                { field: 'action', header: 'Profile.Action' }
            ];

            default: return null;
        }
    }

    editItem(item: any) {
        if(item.accessTagComponent){
            if(item.accessTagComponent.length == 0){
                item.accessTagComponent = this.siteTagList.payload;
            }
        }
        this.itemSelected = item;
        this.showExtraInfo = true;
        this.itemSelected.payload = (this.tabSelected == 0) ? this.itemSelected.accessTagComponent : this.itemSelected.subject;
        this.itemSelected.payload.forEach(element => {
            element.title = (this.tabSelected == 0) ? element.title : element.name;
            element.active = (this.tabSelected == 0) ? element.activeForProfile : element.isActive;
        });
        this.itemSelected.profileName = (this.tabSelected == 2) ? item.name : item.profileName;
        var allChecked = item.payload.find(x => x.active == false);
        this.allChecked = (allChecked == null || allChecked == undefined) ? true : false;
    }

    selectAllCheckbox(event: any) {
        this.itemSelected.accessTagComponent.forEach(element => {
            element.activeForProfile = event.checked;
        });
    }

    updateCheckbox(event: any, item: any) {
        this.itemSelected.accessTagComponent.forEach(element => {
            if (element.id == item.id) {
                element.activeForProfile = event.checked;
            }
        });
    }

    udpateItemInfo(key: number) {
        const data = this.onDataParams(key);
        const URL = `${environment.WebApiUrl + this.returnUrlParameter(key)} `;
        this._apiService.apiPostMethod(URL, data).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                    if (key == 2) {
                        this.getAllWebsiteProfilesList();
                    }
                } else {
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
                this.loading = false;
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                console.log(err);
            }
        });
    }

    hardRefresh() {
        location.reload();
    }

    onDataParams(key: number) {
        switch (key) {
            case 0: return this.itemSelected;

            case 1: return {
                idProfile: this.itemSelected.idProfile,
                isActive: true,
                idSite: this.siteTagSelected
            }

            case 2: return {
                field: this.itemSelected.field,
                idSiteProfile: this.itemSelected.idSiteProfile,
                isActive: this.itemSelected.isActive,
                name: this.itemSelected.profileName,
                subject: this.itemSelected.subject
            }

            default: return null;
        }
    }

    returnUrlParameter(key: number) {
        switch (key) {
            case 0: return PutMethods.UPDATE_PROFILE;
            case 1: return PostMethods.POST_INSERT_NEW_SITE_BY_PROFILE;
            case 2: return PutMethods.UPDATE_SITE_PROFILE;
            default: return null;
        }
    }

    onGetOutputData(event: any) {
        if (event == 1) {
            this.getAllProfilesList();
        }

        if (event == 3) {
            this.getAllWebsiteProfilesList();
        }
    }
}
