<div class="container-fluid">
    <div class="row gap-5">
        <div class="col-12 col-md-12 col-lg-4 align-items-center justify-content-center card card-custom p-3">
            <app-two-factor></app-two-factor>
        </div>
        <div class="col-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center card card-custom">
            <div class="p-4">
                <h6 class="fw-bold">{{'Theme' | translate}}</h6>
                <p-dropdown  
                    styleClass="d-flex w-100" 
                    [options]="themeList"
                    optionLabel="label"
                    [(ngModel)]="themeSelected"
                    (onChange)="onSetTheme($event)"
                    optionValue="value">
                    <ng-template let-option pTemplate="item">
                        <span [class]="'customer-badge status-' + option.value">{{ option.label | translate }}</span>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
        <div class="col-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center card card-custom">
            <div class="p-4">
                <h6 class="fw-bold">{{'Smart Login' | translate}}</h6>
                <div class="m-auto text-center">
                    <form [formGroup]="smartLoginForm" (ngSubmit)="onSubmit()">
                        <p-inputSwitch formControlName="isSmartLogin" (onChange)="onSubmit()"></p-inputSwitch>
                    </form>
                    <!-- <button class="btn btn-primary w-100" (click)="onSubmit();">
                        {{'GENERICS.SAVE' | translate}}
                    </button> -->
                </div>
            </div>
        </div>
    </div>
    
</div>
