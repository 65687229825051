<app-checkbox-editor
    *ngIf="identity === 2"
    [title]="'StatusList.Ticket status'"
    [identity]="identity"
    [payloadData]="payloadData"
    [isErrorActivated]="isErrorActivated"
    [checked]="checked"
    [modalTitleNewText]="'StatusList.NewStatus'"
    [modalTitleEditText]="'StatusList.EditStatus'"
    [newPostEvent]="5"
    [editPostEvent]="6"
    [deletePostEvent]="7"
    [selectAllCheckbox]="8"
    (refreshData)="refreshData($event)"
>
</app-checkbox-editor>