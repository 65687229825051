import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TicketCenter, Filters } from '@methods/constants';
import { GetMethods, Methods, PostMethods, PutMethods, getStatusType, removeTicketObjectWithId } from '@methods/methods';
import { User } from '@models/auth-model';
import { BrandSetup, ConfigByDomain, NotificationsByTicket, ResponseData, TicketMessage } from '@models/models';
import { ApiService } from '@services/api.service';
import { NotificationService } from '@services/notification.service';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '@services/theme.service';
import { CounterService } from '@services/counter.service';
declare const bootstrap: any;

@Component({
  selector: 'app-ticket-message-info',
  templateUrl: './ticket-message-info.component.html'
})
export class TicketMessageInfoComponent implements OnInit {
  @Input() TicketInfo: any;
  @Input() IdSiteType: number; /* 1 = Agent and Players Tickets, 2 = Partners Tickets, 3 = Internal Tickets, 4 DirectTicket */
  @Input() subjectList: any;
  @Input() statusList: any;
  @Input() relevanceList: any;
  @Input() departmentList: any;
  @Input() payloadData: any;
  @Input() shortCutByDepartment: any;
  @Input() formType: number;
  @Output() onBackBtnEvent = new EventEmitter<boolean>();

  columsData: any;
  loading: boolean = false;
  ticketInfoData: any = [];
  userLogin: User;
  domainInfo: ConfigByDomain;
  userList: User[];
  userForm: FormGroup;
  ticketCenter: Filters[];
  countries: any[];
  userSelected: any;
  selectedUserName: string;
  departmentSelected: any;
  notificationsByTicket: NotificationsByTicket = new NotificationsByTicket();
  brandSetup: BrandSetup = new BrandSetup();
  existsBrandSetup: boolean = false;
  ccInputText: string = '';
  serviceDeskInput: string = '';
  reloadSite: boolean = false;
  ticketMessagesList: TicketMessage[];
  environment: any;
  isReplyToClientOrNewMessage: number = 0;
  formModal: any;
  editItemSelected: any;
  isDarkTheme: boolean;
  showTicketForm: boolean = false;
  validUserChange: boolean = false;
  constructor(private _translateService: TranslateService, private _apiService: ApiService,  private _formBuilder: FormBuilder, private _notificationService: NotificationService, private _sanitizer:DomSanitizer, private _themeService: ThemeService, private _counterService: CounterService){
    this.ticketCenter = TicketCenter;
    this.environment = environment;
  }

  ngOnInit(): void {
    this.ticketInfoData.push(this.TicketInfo); // data user info
    this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
    this.domainInfo =  JSON.parse(localStorage.getItem('DomainCTS')!);
    this.brandSetup = JSON.parse(sessionStorage.getItem('BrandSetup')!);
    this.isDarkTheme = this._themeService.getThemeSelected() == 'theme-default-dark' ? true : false;
    this.existsBrandSetup = this.brandSetup != null ? true: false;
    this.columsData = [
      { field: 'ticket', header: 'Ticket' },
      { field: 'agent', header: 'Agent/Player' },
      { field: 'subject', header: 'Subject' },
      { field: 'website', header: 'Website' },
      { field: 'date', header: 'Date' },
      { field: 'priority', header: 'Priority' },
      { field: 'department', header: 'Department' },
      { field: 'status', header: 'Status' },
      { field: 'field', header: 'Field' },
      { field: 'time', header: 'Time' },
      { field: 'serviceDesk', header: 'Service Desk' },
    ];

    if(this.existsBrandSetup){
        this.columsData = [
            { field: 'ticket', header: 'Ticket' },
            { field: 'agent', header: 'Agent/Player' },
            { field: 'date', header: 'Date' },
            { field: 'subject', header: 'Subject' },
            { field: 'field', header: 'Field' },
            { field: 'priority', header: 'Priority' },
            { field: 'department', header: 'Department' },
            { field: 'status', header: 'Status' },
            { field: 'time', header: 'Time' },
            { field: 'serviceDesk', header: 'Service Desk' },
          ];
    }

    this.subjectList.forEach((element:any) => {
      element.name = element.label,
      element.value = element.idSubject.toString()
    });
    this.statusList;

    this.userForm = this._formBuilder.group({
      userAssigned: this.TranslateText(this.TicketInfo.ticketInfo.assignedUser),
      subjectAssigned: this.TicketInfo.subject,
      statusAssigned: this.TicketInfo.ticketInfo.idStatus,
      ticketRelevance : this.TicketInfo.ticketInfo.relevance,
      ticketIdSiteType: this.TicketInfo.ticketInfo.idSiteType.toString()
    });


    this.formModal = new bootstrap.Modal(document.getElementById('replyToClientOrNewMessage'), {
      keyboard: false
    })

    if(this.userLogin.viewOnly){
      this.userForm.get('statusAssigned')?.disable();
    }

    this.getAllMessagesByTicket();

    if(this.formType == 4 ){
        this.getAllUsersList();
    }else{
        this.getUser();
    }

  }
  get f() { return this.userForm.controls; }

  getUser(){
    this.userList = [];
    if(this.existsBrandSetup && this.domainInfo.idSite == 992){
        this.userList = [];
        this.userList.push(this.userLogin);
        return;
    }
    const URL = `${environment.WebApiUrl + GetMethods.GET_USER_BY_DEPARTMENT +  this.TicketInfo.ticketInfo.idTicket}`;
    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          this.userList = result.data;
          if(this.TicketInfo.ticketInfo.assignedUser!=undefined){
            this.TicketInfo.ticketInfo.assignedUser = this.userList.find(x => x.name === this.TranslateText(this.TicketInfo.ticketInfo.assignedUser))?.idUser
          }else{
            this.TicketInfo.ticketInfo.assignedUser = 0;
          }

        }
      },
      error: (err: any) => {
        console.log(err);
        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
      }
    });
  }

  getAllUsersList(){
    this.userList = []
    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_USERS}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          result.data.filter((x: { isActive: boolean; }) => x.isActive === true);
          result.data.forEach((value: any) => {
            value.value = value.IdDepartment;
            value.label = value.Description;
          });
          this.userList = result.data;
        }
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err);
      }
    });
  }

  getAllMessagesByTicket(){
    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_MESSAGE_BY_TICKET + this.TicketInfo.ticketInfo.idTicket}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
            this.ticketMessagesList = result.data;
            this.ticketMessagesList.forEach(element =>{
                element.message = this.setMessage(element.message);
            });
        }
      },
      error: (err: any) => {
        console.log(err);
        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
      }
    });
  }

  onMarkAsUnread(){
    const data = {
      idTicket: this.TicketInfo.idTicket,
      markAsUnread: 1
    }
    const URL = `${environment.WebApiUrl + PutMethods.UPDATE_NOTIFICATION_BY_TICKET }`;
    this._apiService.apiPostMethod(URL, data).subscribe({
      next: (result: ResponseData) => {
        if (result.success){
          location.reload();
        }else{
          this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
      },
      error: (err: any) => {
        console.log(err);
        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
      }
    });
  }

  onChangeUser(event:any) {
    this.validUserChange = true;
    this.TicketInfo.ticketInfo.assignedUser = this.userList.find(x => x.name === event.value)?.idUser
    }

onSaveAssignedUser() {
const URL = `${environment.WebApiUrl + PostMethods.POST_ADD_USER_INTO_TICKET }`;
this._apiService.apiPostMethod(URL, this.TicketInfo.ticketInfo).subscribe({
    next: (result: ResponseData) => {
    if (result.success){
        this.TicketInfo.ticketInfo.assignedUser = this.userList.find(x => x.idUser === this.TicketInfo.ticketInfo.assignedUser)?.name
        this.userForm.controls['userAssigned'].setValue(this.TicketInfo.ticketInfo.assignedUser)
        // this._notificationService.success('GENERICS.UPDATED_SUCCESS', 'bg-success', 'animate__backInUp', 6000);
    }else{
        // this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
    }
    },
    error: (err: any) => {
    console.log(err);
    // this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
    }
});
}

  onSaveTicketServiceDesk() {
    this.TicketInfo.ticketInfo.serviceDeskTicket = this.serviceDeskInput.toString();
    const URL = `${environment.WebApiUrl + PostMethods.POST_ADD_SERVICE_DESK_TICKET }`;
    this._apiService.apiPostMethod(URL, this.TicketInfo.ticketInfo).subscribe({
      next: (result: ResponseData) => {
        if (result.success){
          this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
        }else{
          this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
      },
      error: (err: any) => {
        console.log(err);
        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
      }
    });
  }

  onSaveTicketChanges() {
    this.TicketInfo.ticketInfo.idType = 5;
    this.TicketInfo.ticketInfo.userInCharge = this.userLogin.idUser;
    this.TicketInfo.subject = this.f.subjectAssigned.value;
    this.TicketInfo.ticketInfo.idStatus = this.f.statusAssigned.value;
    this.TicketInfo.ticketInfo.relevance = this.f.ticketRelevance.value;
    if(this.f.ticketIdSiteType.value != this.TicketInfo.ticketInfo.idSiteType){
      this.reloadSite = true;
    }
    this.TicketInfo.ticketInfo.idSiteType = this.f.ticketIdSiteType.value;
    const URL = `${environment.WebApiUrl + PutMethods.UPDATE_TICKET_INFORMATION }`;
    this._apiService.apiPostMethod(URL, this.TicketInfo.ticketInfo).subscribe({
        next: (result: ResponseData) => {
            if(result.success){
                this.TicketInfo.relevance = this.relevanceList.find((x:any) => x.idRelevance === this.f.ticketRelevance.value)?.description
                this.TicketInfo.status = this.statusList.find((x:any) => x.idStatusList === this.f.statusAssigned.value)?.description
                if(this.validUserChange){
                    this.onSaveAssignedUser();
                    this.validUserChange = false;
                }
                this._notificationService.success('GENERICS.UPDATED_SUCCESS', 'bg-success', 'animate__backInUp', 6000);
                if(this.reloadSite){
                    removeTicketObjectWithId(this.payloadData, this.TicketInfo.ticketInfo.idTicket );
                    //this.onBackBtnEvent.emit(false);
                }

                this._counterService.setNotificationsServiceSub(true);
            }else{
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        },
        error: (err: any) => {
            console.log(err);
            this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
    });
  }

  changeDepartment(event:any){
    this.departmentSelected = event.value;
  }

  onChangeDepartmentAssigned(){
    const data = {
      departmentName : this.TicketInfo.ticketInfo.department[0].departmentName,
      idUser : this.userLogin.idUser,
      idTicket : this.TicketInfo.idTicket,
      ticketNumber : this.TicketInfo.ticketInfo.ticketNumber,
      idDepartment: this.departmentList.find((x:any) => x.idDepartment === this.departmentSelected)?.idDepartment,
      newDepartmentName: this.departmentList.find((x:any) => x.idDepartment === this.departmentSelected)?.name,
    }

    const URL = `${environment.WebApiUrl + PutMethods.UPDATE_DEPARTMENT_ASSIGNED }`;
    this._apiService.apiPostMethod(URL, data).subscribe({
      next: (result: any) => {
        if(result != null){
          this.TicketInfo.ticketInfo.department[0].departmentName = data.newDepartmentName;
          this.TicketInfo.ticketInfo.assignedUser = 0;
          this.onSaveAssignedUser();
          this.getUser();
          this._notificationService.success('GENERICS.UPDATED_SUCCESS', 'bg-success', 'animate__backInUp', 6000);
        }else{
          this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
      },
      error: (err: any) => {
        console.log(err);
        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
      }
    });
  }

  onCallFormMethod(event:any){
    if(event == 5 || event == 6 || event == 7){
      this.getAllMessagesByTicket();
      this.closeModal();
    }
  }


  openReplyOrNewModal (isNew: number, info:any){
    this.isReplyToClientOrNewMessage = isNew;
    this.editItemSelected = info;
    if(isNew == 7){
      this.TicketInfo.idTicketMessage = info.idTicketMessage;
    }

    this.showTicketForm = false;
    setTimeout(() => {
      this.showTicketForm = true;
      this.formModal.show()
    }, 0);

  }

  closeModal(){
    this.isReplyToClientOrNewMessage = 0;
    this.formModal.hide();
    const reopenButton = document.getElementById('reopenButton');
    if (reopenButton) {
        reopenButton.style.display = 'none';
    }
  }

  minimizeModal() {
    const modalElement = document.getElementById('replyToClientOrNewMessage');
    const reopenButton = document.getElementById('reopenButton');
    if (modalElement) {
        this.formModal.hide();
    }
    if (reopenButton) {
        reopenButton.style.display = 'block';
    }
}

  reopenModal() {
    const modalElement = document.getElementById('replyToClientOrNewMessage');
    const reopenButton = document.getElementById('reopenButton');

    if (modalElement) {
        this.formModal.show();
    }
    if (reopenButton) {
        reopenButton.style.display = 'none';
    }
}



  getStatusType(status:string){
    return getStatusType(status, this.isDarkTheme);
  }

  transform(v:string):SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }

  TranslateText(text: string) {
    return this._translateService.instant(text);
  }

  setMessage(html: string){

    const div = document.createElement('div');
    div.innerHTML = html;
    // Encuentra todos los elementos <p>
    const paragraphs = div.querySelectorAll('p');
    paragraphs.forEach(p => {
    // Si el contenido del <p> está vacío (o solo contiene espacios en blanco)
    if (p.innerHTML.trim() === '') {
      // Reemplaza el contenido vacío con <br>
      p.innerHTML = '<br>';
    }
  });

    return div.innerHTML
  }
}
