import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/services/theme.service';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MediaResponse, MediaService } from "src/app/services/media.service";
import { Spinkit } from 'ng-http-loader';
import { Filters } from '@models/models';
import { ThemesOptions } from '@methods/constants';
import { CounterService } from '@services/counter.service';
import packageJson from '../../../../../package.json';
import { LoadingService } from '@services/loading.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnDestroy {
  public version: string = packageJson.version;
  Media!: MediaResponse;
  private mediaSubscription: Subscription;
  title: string = '';
  sidebarExpanded: boolean = false;
  routeState: string = '';
  user!: [];
  showLoading: BehaviorSubject<boolean>;
  themeList: any = Filters;
  selectTheme = new FormControl('theme-default-light');
  public spinkit = Spinkit;

  opacity: string = '0.9';

  constructor(private router: Router,private _loadingService: LoadingService, private _themeService: ThemeService, private _media: MediaService, private _counterService: CounterService) {
    this.mediaSubscription = this._media.subscribeMedia().subscribe(media => {
      this.Media = media;
      this.sidebarExpanded = media.IsMobile ? false : true;
    });

    this._themeService._ActualTheme.subscribe(data => {
      this.themeList = ThemesOptions.find( theme => theme.value == data );
    });

    let themeSelected = this._themeService.getThemeSelected();
    if (themeSelected) {
      this._themeService.setTheme(themeSelected);
      this.selectTheme = new FormControl(themeSelected);
    } else {
      this._themeService.setTheme('theme-default-light');
    }
    this.selectTheme.valueChanges.subscribe((value) => {
      this._themeService.setTheme(value);
    });
    // this._counterService.IsExpandedSideNav(this.sidebarExpanded);
  }
  ngOnInit(): void {
    this.showLoading = this._loadingService.getShowLoading();
  }
  ngOnDestroy() {
    if (this.mediaSubscription) {
      this.mediaSubscription.unsubscribe();
    }
  }
}
