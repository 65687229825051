import { Component, OnInit } from '@angular/core';
import { GetMethods, Methods } from '@methods/methods';
import { User } from '@models/auth-model';
import { BrandSetup, ResponseData } from '@models/models';
import { ApiService } from '@services/api.service';
import { LoadingService } from '@services/loading.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shortcut-center',
  templateUrl: './shortcut-center.component.html'
})
export class ShortcutCenterComponent implements OnInit{
  tabPanelData: any;
  columsData: any;
  payloadData: any;
  loading: boolean = true;
  userLogin: User;
  statusList: any;
  showHardReloadBtn: boolean = false;
  userList: any;
  departmentList: any;
  _BrandSetup: BrandSetup = new BrandSetup();
  _ExistsBrandSetup: boolean = false;

  constructor(private _apiService: ApiService, private _loadingService: LoadingService){}

  ngOnInit(): void {
    this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
    this._BrandSetup = JSON.parse(sessionStorage.getItem('BrandSetup')!);
    this._BrandSetup != null ? this._ExistsBrandSetup = true : this._ExistsBrandSetup = false;
    this.tabPanelData = [
      {tabTitle: 'ShortcutManager', id: 0, showPanel: true},
      {tabTitle: 'NewShortcut', id: 1, showPanel: true}
    ]

    this.columsData = [
      { field: 'ID', header: 'ID' },
      { field: 'Name', header: 'Form.Name' },
      { field: 'Description', header: 'Shortcut.Description' },
      { field: 'Department', header: 'Shortcut.Department' },
      { field: 'Date created', header: 'Date' },
      { field: 'Created by user', header: 'Shortcut.Created by user' },
      { field: 'Options', header: 'User.Actions' }
    ]

    this.getAllShortCuts();
    this.getStatusList();
    this.getAllDepartmentList();
    this.getUserList();
  }

  getAllShortCuts(){
    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_SHORCUTS }`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
            result.data.forEach((value: any) => {
                value.value = value.description;
                value.label = value.userName;
                value.departmentName = value.departmentName
            });
            this.payloadData = result.data;
            if(this._BrandSetup != null)
                this.payloadData = this.payloadData.filter(x => x.idDepartment == this._BrandSetup.idDepartment);
            this.orderShortcutsForDepartment();
        }
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
        this.showHardReloadBtn = true;
        console.log(err);
      }
    });
  }


  orderShortcutsForDepartment() {
    this.payloadData.sort((a, b) => {
      if (a.departmentName < b.departmentName) {
        return -1;
      }
      if (a.departmentName > b.departmentName) {
        return 1;
        }
        return 0;
    });
}

  getStatusList(){
    this.statusList = []
    const URL = `${environment.WebApiUrl + GetMethods.GET_STATUS_LIST}`;

    this._apiService.apiGetMethod(URL).subscribe({
        next: (result: ResponseData) => {
            if(result.success){
                result.data.forEach((value: any) => {
                    value.value = value.description;
                    value.label = value.description;
                });
                this.statusList = result.data;
            }
        },
        error: (err: any) => {
            this.loading = false;
            console.log(err);
        }
    });
  }

  getUserList(){
    this.userList = []
    this._loadingService.toggleLoading();
    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_USERS}`;
    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          result.data.forEach((value: any) => {
            value.value = value.name;
            value.label = value.name;
          });
          this.userList = result.data;
          if(this._BrandSetup != null)
            this.userList = this.userList.filter(x => x.idDepartment == this._BrandSetup.idDepartment);
          this._loadingService.toggleLoading();
        }
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err);
      }
    });
  }

  getAllDepartmentList(){

    this.departmentList = [];
    const URL = `${environment.WebApiUrl + GetMethods.GET_DEPARTMENT_LIST}`;

    this._apiService.apiGetMethod(URL).subscribe({
        next: (result: ResponseData) => {
            if(result.success){
                result.data.forEach((value: any) => {
                    value.value = value.name;
                    value.label = value.name;
                });
                this.departmentList = result.data;
                if(this._BrandSetup != null)
                    this.departmentList = this.departmentList.filter(x => x.idDepartment == this._BrandSetup.idDepartment);
            }
        },
        error: (err: any) => {
            this.loading = false;
            console.log(err);
        }
    });
  }

  onCallGetInfo(event:any) {
    this.getAllShortCuts()
  }
}
