import { Component, OnInit } from '@angular/core';
import { FormBuilder} from '@angular/forms';
import { GetMethods, Methods } from '@methods/methods';
import { User } from '@models/auth-model';
import { ResponseData } from '@models/models';
import { ApiService } from '@services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-service-desk-log',
  templateUrl: './service-desk-log.component.html'
})
export class ServiceDeskLogComponent implements OnInit{
  tabPanelData: any;
  columsData: any;
  payloadData: any;
  loading: boolean = true;
  userLogin: User;
  statusList: any;
  showHardReloadBtn: boolean = false;
  userList: any; 
  departmentList: any;
  constructor(private _apiService: ApiService, private _formBuilder: FormBuilder){}

  ngOnInit(): void {
    this.userLogin = JSON.parse(localStorage.getItem('UserAccessCTS')!);
    this.tabPanelData = [
      {tabTitle: 'SDK.Log', id: 0, showPanel: true},
      {tabTitle: 'SDK.NewEntry', id: 1, showPanel: true}
    ]

    this.columsData = [
      { field: 'id', header: 'ID' },
      { field: 'Date', header: 'Date' },
      { field: 'Email Subject', header: 'SDK.EmailSubject' },
      { field: 'Ticket', header: 'logs.Ticket' },
      { field: 'Sender', header: 'SDK.Sender' },
      { field: 'Status', header: 'Status' },
      { field: 'Options', header: 'User.Actions' }
    ]
    this.getAllLogs();
    this.getStatusList();
    this.getUserList();
    this.getAllDepartmentList();
  }

  getAllLogs(){
    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_TASK_NOTES + this.userLogin.idDepartment}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        result.data.forEach((value: any) => {
          value.value = value.description;
          value.label = value.userInChargeName;
          value.idTicket = value.idNote;
        });
        this.payloadData = result.data;
        this.loading = false;
      },
      error: (err: any) => {
        this.loading = false;
        this.showHardReloadBtn = true;
        console.log(err);
      }
    });
  }

  getStatusList(){
    this.statusList = []
    const URL = `${environment.WebApiUrl + GetMethods.GET_STATUS_LIST}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
      result.data.forEach((value: any) => {
        value.value = value.description;
        value.label = value.description;
      });
      this.statusList = result.data;
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err);
      }
    });
  }

  getUserList(){
    this.userList = []

    const URL = `${environment.WebApiUrl + GetMethods.GET_USER_NAME_BY_DEPARTMENT + this.userLogin.idDepartment}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
      result.data.forEach((value: any) => {
        value.value = value.name;
        value.label = value.name;
      });
        this.userList = result.data;
      },
      error: (err: any) => {
        this.loading = false;
        console.log(err);
      }
    });
  }

  getAllDepartmentList(){
    this.departmentList = [];
    const URL = `${environment.WebApiUrl + GetMethods.GET_DEPARTMENT_LIST}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: any) => {
      result.data.forEach((value: any) => {
        value.value = value.name;
        value.label = value.name;
      });
      this.departmentList = result.data.filter((x:any) => x.isActive == true);
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  onCallGetInfo(event:any) {
    this.getAllLogs();
  }
}
