<div class="container text-center w-100" [ngClass]="{'card card-custom p-4': actualRouter == '/dashboard/twofactor'}">
    <h6 class="fw-bold" *ngIf="twoFactorData.isTFA">{{ 'USER.TWO_FACTOR.TWO_FACTOR_ENABLED' | translate }}</h6>
    <h4 class="fw-bold" *ngIf="!twoFactorData.isTFA">{{ 'USER.TWO_FACTOR.TWO_FACTOR_SCAN' | translate }}</h4>
    <div class="w-100 justify-content-center text-center">
        <img height="150" width="150" *ngIf="!twoFactorData.isTFA" src="{{twoFactorSettings.qrCodeSetupImageUrl}}" alt="Qr code">
    </div>
    <div *ngIf="!twoFactorData.isTFA" class="my-2 text-center">
        <label>{{'USER.TWO_FACTOR.TWO_FACTOR_SETUP' | translate}}: <span class="fw-bolder">{{twoFactorSettings.manualEntryKey}}</span></label>
    </div>
    <div class="m-auto text-center mt-5" [ngClass]="{'w-50': !Media.IsMobile}">
        <form [formGroup]="twoFactorForm" (ngSubmit)="onSubmit()">
            <div class="forms-inputs mb-4">
                <span class="span-login" [ngClass]="{ 'text-danger': submitted && f.inputCode.errors }">{{'USER.TWO_FACTOR.TWO_FACTOR_INPUT_CODE' | translate}}</span>
                <input type="inputCode" formControlName="inputCode"
                    [ngClass]="{ 'is-invalid': submitted && f.inputCode.errors }" class="form-control">
                <div *ngIf="submitted && f.inputCode.errors" class="invalid-feedback">
                    <div *ngIf="f.inputCode.errors.required"> {{ 'USER.TWO_FACTOR.TWO_FACTOR_IS_REQUIRED' | translate }}
                    </div>
                </div>
            </div>
            <button class="btn w-100" type="submit" [ngClass]="{'btn-danger': twoFactorData.isTFA, 'btn-primary': !twoFactorData.isTFA}">
                <span *ngIf="twoFactorData.isTFA">{{ 'USER.TWO_FACTOR.TWO_FACTOR_DISABLE_TEXT' | translate }}</span>
                <span *ngIf="!twoFactorData.isTFA">{{ 'USER.TWO_FACTOR.TWO_FACTOR_ENABLE_TEXT' | translate }}</span>
            </button>
        </form>

        <div *ngIf="messageResult != ''" class="alert alert-danger mt-3" role="alert">
            {{messageResult | translate }}
        </div>
    </div>
</div>
