<app-shortcut-desk-filter
    [identity]="1"
    [title]="'Service Desk Log'"
    [tabPanelData]="tabPanelData"
    [columsData]="columsData"
    [payloadData]="payloadData"
    [statusList]="statusList"
    [showHardReloadBtn]="showHardReloadBtn"
    [loading]="loading"
    [userList]="userList"
    [deletePostEvent]="3"
    [formType]="8"
    [departmentList]="departmentList"
    (onCallGetInfo)="onCallGetInfo($event)">
</app-shortcut-desk-filter>