import { Component, OnInit } from '@angular/core';
import { Filters } from '@methods/constants';
import { GetMethods, Methods } from '@methods/methods';
import { ResponseData } from '@models/models';
import { ApiService } from '@services/api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-relevance-level',
  templateUrl: './relevance-level.component.html'
})
export class RelevanceLevelComponent implements OnInit {
  payloadData: Filters[];
  isErrorActivated: boolean = false;
  identity: number = 1;
  checked: boolean = false;

  constructor(private _apiService: ApiService){}

  ngOnInit(): void {
      this.getRelevanceList();
  }

  getRelevanceList(){
    this.payloadData = [];
    this.isErrorActivated = false;
    const URL = `${environment.WebApiUrl + GetMethods.GET_RELEVANCE_LIST}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        result.data.forEach((value: any) => {
          value.idvalue = value.idRelevance;
          value.label = value.description;
        });
        var allChecked = result.data.find(x => x.isActive == false);
        this.checked = ( allChecked == null  || allChecked == undefined) ? true : false;
        this.payloadData = result.data;
      },
      error: (err: any) => {
        this.isErrorActivated = true;
        console.log(err);
      }
    });
  }

  refreshData($event:any){
    this.getRelevanceList();
  }

}
