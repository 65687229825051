/**
Internal Modules
*/
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/**
External Modules
*/

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { JwtModule } from "@auth0/angular-jwt";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { OnlineStatusModule } from 'ngx-online-status';
import { AppRoutingModule } from './app-routing.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxEditorModule } from "ngx-editor";

/**
  PRIME MODULES
*/ 

import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { SliderModule } from 'primeng/slider';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ContextMenuModule } from 'primeng/contextmenu';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { DropdownModule } from 'primeng/dropdown';
import { CardModule } from 'primeng/card';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { CheckboxModule } from 'primeng/checkbox';
import { ScrollTopModule } from 'primeng/scrolltop';
import { BadgeModule } from 'primeng/badge';


/**
Services
*/
import { ThemeService } from '@services/theme.service';
import { NotificationService } from '@services/notification.service';
import { TranslationService } from '@services/translate.service';
import { ApiService } from '@services/api.service';
import { TokenInterceptor } from '@services/token.interceptor';
import { CounterService } from '@services/counter.service';
import { CookieService } from 'ngx-cookie-service';

/**
Components
*/

import { AppComponent } from './app.component';
import { NotificationsComponent } from './core/components/notifications/notifications.component';
import { LoginComponent } from './core/components/login/login.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { NavbarComponent } from './core/components/navbar/navbar.component';
import { SideNavComponent } from './core/components/sidenav/sidenav.component';
import { MainLayoutComponent } from './core/layouts/main-layout/main-layout.component';
import { HeaderLayoutComponent } from './core/layouts/header-layout/header-layout.component';
import { DashboardComponent } from './core/components/dashboard/dashboard.component';
import { ConfigurationComponent } from './core/components/configuration/configuration.component';

/**
Views
*/

import { TickerComponent } from './core/views/ticker/ticker.component';
import { HelpTicketCenterComponent } from './core/views/help-ticket-center/help-ticket-center.component';
import { InternalTicketCenterComponent } from './core/views/internal-ticket-center/internal-ticket-center.component';
import { PartnersTicketCenterComponent } from './core/views/partners-ticket-center/partners-ticket-center.component';
import { PersonalWorklogComponent } from './core/views/personal-worklog/personal-worklog.component';
import { ServiceDeskLogComponent } from './core/views/service-desk-log/service-desk-log.component';
import { ShortcutCenterComponent } from './core/views/shortcut-center/shortcut-center.component';
import { SubjectsComponent } from './core/views/subjects/subjects.component';
import { RelevanceLevelComponent } from './core/views/relevance-level/relevance-level.component';
import { StatusListComponent } from './core/views/status-list/status-list.component';
import { DepartmentComponent } from './core/views/department/department.component';
import { WebsiteSetupComponent } from './core/views/website-setup/website-setup.component';
import { UserComponent } from './core/views/user/user.component';
import { ProfileComponent } from './core/views/profile/profile.component';
import { TwoFactorComponent } from './core/components/two-factor/two-factor.component';
import { DirectTicketCenterComponent } from './core/views/direct-ticket-center/direct-ticket-center.component';
import { BrandSetUpComponent } from './core/views/brand-set-up/brand-set-up.component';
import { LogsComponent } from './core/views/logs/logs.component';
import { NotificationComponent } from './core/views/notification/notification.component';
import { ExportAsComponent } from './core/components/export-as/export-as.component';
import { TicketFormComponent } from './core/components/ticket-form/ticket-form.component';
import { TableGenericComponent } from './core/components/table-generic/table-generic.component';
import { TicketMessageInfoComponent } from './core/components/ticket-message-info/ticket-message-info.component';
import { TicketGeneralComponent } from './core/components/ticket-general/ticket-general.component';
import { FileUploadModule } from 'ng2-file-upload';
import { UserFormComponent } from './core/components/user-form/user-form.component';
import { CheckboxEditorComponent } from './core/components/checkbox-editor/checkbox-editor.component';
import { BrandSubjectFilterComponent } from './core/components/brand-subject-filter/brand-subject-filter.component';
import { ShortcutDeskFilterComponent } from './core/components/shortcut-desk-filter/shortcut-desk-filter.component';
import { EditProfileComponent } from './core/components/edit-profile/edit-profile.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function getLang(){
  const lang = localStorage.getItem('lang')!
  return lang != null ? lang : 'en';
}

export function tokenGetter() {
  return sessionStorage.getItem("token");
}
@NgModule({
  declarations: [
    AppComponent,
    NotificationsComponent,
    LoginComponent,
    NotFoundComponent,
    NavbarComponent,
    SideNavComponent,
    MainLayoutComponent,
    HeaderLayoutComponent,
    DashboardComponent,
    TickerComponent,
    HelpTicketCenterComponent,
    InternalTicketCenterComponent,
    PartnersTicketCenterComponent,
    PersonalWorklogComponent,
    ServiceDeskLogComponent,
    ShortcutCenterComponent,
    SubjectsComponent,
    RelevanceLevelComponent,
    StatusListComponent,
    DepartmentComponent,
    WebsiteSetupComponent,
    UserComponent,
    ProfileComponent,
    ConfigurationComponent,
    TwoFactorComponent,
    DirectTicketCenterComponent,
    BrandSetUpComponent,
    LogsComponent,
    NotificationComponent,
    ExportAsComponent,
    TicketFormComponent,
    TableGenericComponent,
    TicketMessageInfoComponent,
    TicketGeneralComponent,
    UserFormComponent,
    CheckboxEditorComponent,
    BrandSubjectFilterComponent,
    ShortcutDeskFilterComponent,
    EditProfileComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    OnlineStatusModule,
    HttpClientModule,
    ScrollTopModule,
    BadgeModule,
    TableModule,
    CalendarModule,
    SliderModule,
    DialogModule,
    MultiSelectModule,
    ContextMenuModule,
    DropdownModule,
    ButtonModule,
    CardModule,
    InputTextModule,
    InputSwitchModule,
    ProgressBarModule,
    TabViewModule,
    CheckboxModule,
    DragDropModule,
    FontAwesomeModule,
    TagModule,
    BrowserAnimationsModule,
    FileUploadModule,
    NgxEditorModule.forRoot({
      locals: {
        // menu
        bold: 'Bold',
        italic: 'Italic',
        code: 'Code',
        underline: 'Underline',
        strike: 'Strike',
        blockquote: 'Blockquote',
        bullet_list: 'Bullet List',
        ordered_list: 'Ordered List',
        heading: 'Heading',
        h1: 'Header 1',
        h2: 'Header 2',
        h3: 'Header 3',
        h4: 'Header 4',
        h5: 'Header 5',
        h6: 'Header 6',
        align_left: 'Left Align',
        align_center: 'Center Align',
        align_right: 'Right Align',
        align_justify: 'Justify',
        text_color: 'Text Color',
        background_color: 'Background Color',
        insertLink: 'Insert Link',
        removeLink: 'Remove Link',
        insertImage: 'Insert Image',
    
        // pupups, forms, others...
        url: 'URL',
        text: 'Text',
        openInNewTab: 'Open in new tab',
        insert: 'Insert',
        altText: 'Alt Text',
        title: 'Title',
        remove: 'Remove',
      },
    }),
    NgHttpLoaderModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: getLang(),
      //defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:3001", "foo.com", "bar.com"],
        disallowedRoutes: ["http://example.com/examplebadroute/"],
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    ThemeService,
    NotificationService,
    CounterService,
    TranslationService,
    ApiService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
