<p-card class="card" header="{{ title | translate}}" subheader="" [style]="{ width: 'auto', padding: '0' }">
    <div *ngIf="!isErrorActivated">
        <div class="mt-2 p-3 d-flex align-items-center">
            <div class="col-10 col-sm-10 col-md-10 d-flex align-items-center">
                <label class="container-custom">
                    <input type="checkbox" [checked]="checked" (change)="UpdateAllItemCheckbox($event.target)">
                    <span class="checkmark"></span>
                </label>
                <span class="sub-desc">{{'SelectALL' | translate}}</span> 
            </div>
        </div>

        <div class="row g-0 border-top d-flex align-items-center" [ngClass]="{'p-3': !sortView, 'p-0': sortView}" *ngFor="let item of payloadData; index as i">
            <ng-container *ngIf="!sortView" >
                <div class="col-10 col-sm-10 col-md-10 d-flex align-items-center">
                    <label class="container-custom">
                        <input type="checkbox" [checked]="item.isActive" (change)="updateItemByCheckbox($event, item)">
                        <span class="checkmark"></span>
                    </label>
                    <span class="sub-desc">{{item.label | translate}} <br> 
                        <span *ngIf="identity === 3">{{item.email}} </span> 
                    </span>
                </div>
                <div class="col-2 col-sm-2 col-md-2 d-flex justify-content-end">
                    <button class="btn btn-icon" (click)="editOrNewItem(item, false)" type="button">
                        <i class="feather icon-edit"></i>
                    </button>
                    <button class="btn btn-icon" (click)="deleteItem(item)"  type="button">
                        <i class="feather icon-trash-2"></i>
                    </button>
                </div>
            </ng-container>
        </div>
        
        <div class="w-100 text-center">
            <p-button pTooltip="{{'AddNew' | translate}}" (onClick)="editOrNewItem([], true)" styleClass="p-button-primary mx-1" icon="feather icon-plus-circle" label="{{'AddNew' | translate}}"></p-button>
        </div>
    
        <div class="modal fade" id="editOrNewItemModal" tabindex="-1" aria-labelledby="editOrNewItemModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header px-3 py-2">
                        <h5 class="modal-title fw-bold w-100 text-center" id="serviceDeskLabel">{{titleModal}}</h5>
                        <a type="button" class="modal-title px-0" (click)="closeModal()" aria-label="Close"> <i class="fas fa-times"></i> </a>
                    </div>
                    <form [formGroup]="userForm">
                        <div class="modal-body pt-4">
                            <div class="form-group row g-3">
                                <div class="col-sm-12">
                                    <dt for="inputTextSelected" class="col-sm-3">{{'GlobalForm.Name' | translate}}</dt>
                                    <input type="text" pInputText class="w-100" formControlName="inputTextSelected" [ngClass]="{ 'is-invalid invalid': submitted && f.inputTextSelected.errors }" />
                                    <div *ngIf="submitted && f.inputTextSelected.errors" class="invalid-feedback">
                                        <div *ngIf="f.inputTextSelected.errors.required">{{TranslateText('GlobalForm.Name')  + ' ' + TranslateText('GlobalForm.IsRequired') }}</div>
                                    </div>
                                </div>

                                <div *ngIf="identity === 3" class="col-sm-12">
                                    <dt for="email" class="col-sm-3">{{'GlobalForm.Email' | translate}}</dt>
                                    <input type="text" pInputText class="w-100" formControlName="email" [ngClass]="{ 'is-invalid invalid': submitted && f.email.errors }" />
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">{{ TranslateText('GlobalForm.Email') + ' ' + TranslateText('GlobalForm.IsRequired') }}</div>
                                    </div>
                                </div>

                                <div *ngIf="identity === 3" class="col-sm-12">
                                    <dt for="inputEmail3" class="col-sm-12">{{'GlobalForm.TypeDepartment' | translate}}</dt>
                                    <div class="col-sm-12" >
                                        <p-dropdown  
                                            styleClass="d-flex w-100" 
                                            [options]="internalList" 
                                            [autoDisplayFirst]="false"
                                            optionLabel="label"
                                            formControlName="isInternal"
                                            [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.isInternal.errors }"
                                            optionValue="boleanValue">
                                        </p-dropdown>
                                        <div *ngIf="submitted && f.isInternal.errors" class="invalid-feedback">
                                            <div *ngIf="f.isInternal.errors.required">{{ TranslateText('GlobalForm.TypeDepartment') + ' ' +   TranslateText('GlobalForm.IsRequired') }}</div>
                                        </div>
                                    </div>
                                </div>
    
                                <div *ngIf="isEditOrNewItem" class="mt-5">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <div class="d-flex align-items-center">
                                                <label class="pe-3 fw-bold">Status: </label> 
                                                <span class="sub-desc pe-3">{{ 'Active' | translate }} <br> </span>
                                                <label class="container-custom">
                                                    <input type="checkbox" formControlName="isActive" [checked]="isNewCheckboxActive">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer form-group text-center">
                            <button type="button" class="btn btn-secondary mr-2" (click)="closeModal()" >{{ 'Close' | translate }}</button>
                            <button type="button" class="btn btn-primary" (click)="createAndUpdateByPayloadData();"><i class="feather icon-save"></i> {{'Save'| translate}}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isErrorActivated" class="row d-flex align-items-center">
        <div class="col-12 text-center">
            <img src="assets/images/empty-tracker-icon.png" alt="something happened">
            <span class="d-block my-3"> {{'GENERICS.UPDATED_ERROR' | translate}} 
            </span>
            <a class="btn btn-danger" (click)="hardRefresh()"> 
                <i class="feather icon-refresh-cw"></i> {{ 'Refresh' | translate }}
            </a> 
        </div>
    </div>
</p-card>