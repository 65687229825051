<div class="login">
    <div class="auth-content text-center">
        <div class="card card-login mx-3">
            <div class="card-header">
                <img src="assets/images/cts-logo.png" alt="Image Logo CTS Admin" class="img-fluid">
            </div>
            <div class="card-body">
                <h4 class="mb-3 f-w-400">{{'Customer Ticket Service' | translate}}</h4>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                    <div class="forms-inputs mb-4">
                        <span class="span-login" [ngClass]="{ 'text-danger': submitted && f.email.errors }">{{ 'UserName' | translate }}</span>
                        <input type="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">{{'User is required' | translate}}</div>
                        </div>
                    </div>
                    <div class="forms-inputs mb-4">
                        <span class="span-login" [ngClass]="{ 'text-danger': submitted && f.password.errors }">{{ 'Password' | translate }}</span>
                        <input type="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" class="form-control" (keyup.enter)="onSubmit();">
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">{{'Password is required' | translate}}</div>
                        </div>
                    </div>
                </form>

                <form *ngIf="isTwoFactorAuth == true" [formGroup]="verifyTFAuthForm" (ngSubmit)="verifyTwoFactorAuthenticationToken()">
                    <div class="forms-inputs mb-4">
                        <span class="span-login" [ngClass]="{ 'text-danger': submittedTokenTFA && j.tokenTFA.errors }"> <img src="assets/images/2falogo-v2.png" height="20px" width="20px" class="img-radius img-fluid" alt="2fa-Image">  {{'Two-Factor Authentication Code' | translate}}</span>
                        <input pInputText type="text" class="form-control" formControlName="tokenTFA" [ngClass]="{ 'is-invalid': submittedTokenTFA && j.tokenTFA.errors }">
                        <div *ngIf="submittedTokenTFA && j.tokenTFA.errors" class="invalid-feedback">
                            <div *ngIf="j.tokenTFA.errors.required">{{ 'Token is required' | translate}}</div>
                        </div>
                    </div>
                </form>

                <div *ngIf="isEmailToken == true">
                    <p class="text-center small">* {{ 'The token was sent to your associated email' | translate}} </p>
                </div>

                <form *ngIf="isEmailToken == true" [formGroup]="verifyForm" (ngSubmit)="onSubmitEmailToken()">
                    <div class="forms-inputs mb-4">
                        <span class="span-login" [ngClass]="{ 'text-danger': submittedToken && g.token.errors }">Token</span>
                        <input pInputText type="text" class="form-control" formControlName="token" [ngClass]="{ 'is-invalid': submittedToken && g.token.errors }" >
                        <div *ngIf="submittedToken && g.token.errors" class="invalid-feedback">
                            <div *ngIf="g.token.errors.required">{{ 'Token is required' | translate}}</div>
                        </div>
                    </div>
                </form>

                <button [disabled]="loading" *ngIf="isTwoFactorAuth == false && isEmailToken == false" type="submit" (click)="onSubmit();" class="btn btn-block btn-login w-100 ">
                    <div *ngIf="loading" class="me-3 spinner-border spinner-border-sm text-light" role="status">
                        <span class="sr-only">Loading...</span>
                    </div> {{'Login' | translate}}
                </button>

                <button [disabled]="loading" *ngIf="isTwoFactorAuth == true" type="submit" (click)="verifyTwoFactorAuthenticationToken();"class="btn btn-block btn-login w-100 ">
                    <div *ngIf="loading" class="me-3 spinner-border spinner-border-sm text-light" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    {{ 'Verify Two Factor Token' | translate }}
                </button>
                <button [disabled]="loading" *ngIf="isEmailToken == true" type="submit" (click)="onSubmitEmailToken();" class="btn btn-block btn-login w-100 ">
                    <div *ngIf="loading" class="me-3 spinner-border spinner-border-sm text-light" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    {{ 'Verify Token '| translate}}
                </button>

                <div *ngIf="messageResult != ''" class="alert alert-danger mt-3" role="alert">
                    {{messageResult | translate }}
                </div>
                <!-- <div *ngIf="isTwoFactorAuth == true" class="mt-3">
                    <a (click)="ChangeMethod();" class="cursor text-decoration-none text-center small">{{'Click here to continue with email' | translate}}</a>
                </div> -->
                <span class="text-small">version: {{version}}</span>
            </div>
        </div>
    </div>
</div>
