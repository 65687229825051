import { Component, OnInit } from '@angular/core';
import { GetMethods, Methods } from '@methods/methods';
import { Filters, ResponseData, SubjectTickets } from '@models/models';
import { ApiService } from '@services/api.service';
import { NotificationService } from '@services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-subjects',
  templateUrl: './subjects.component.html'
})
export class SubjectsComponent implements OnInit {
  payloadData: Filters[];
  payloadSecondData: any;
  isErrorActivated: boolean = false;
  identity: number = 3;
  identity2: number = 4
  checked: boolean = false;
  checked2: boolean = false;
  cardHeaderTitle1: any;
  cardHeaderTitle2: any;
  formValidateInputs: any;
  formValidateInputs2: any;
  departmentList: any;
  allSiteTags: any;

  constructor(private _apiService: ApiService, private _notificationService: NotificationService){}

  ngOnInit(): void {
    this.cardHeaderTitle1 = []
    this.cardHeaderTitle2 = []
    this.formValidateInputs = [
      { control: 'inputTextSelected', show: true },
      { control: 'siteSelected', show: false },
      { control: 'departmentSelected', show: true },
      { control: 'inputTextDescription', show: true },
      { control: 'inputTextSpanish', show: true },
      { control: 'inputTextPortuguese', show: true },
      { control: 'formType', show: false },
      { control: 'isHelp', show: true },
      { control: 'isInternal', show: true },
      { control: 'isPartner', show: true },
      { control: 'isDirect', show: true },
      { control: 'isActive', show: true },
    ]

    this.formValidateInputs2 = [
      { control: 'inputTextSelected', show: true },
      { control: 'siteSelected', show: false },
      { control: 'departmentSelected', show: false },
      { control: 'inputTextDescription', show: false },
      { control: 'inputTextSpanish', show: true },
      { control: 'inputTextPortuguese', show: true },
      { control: 'formType', show: true },
      { control: 'isHelp', show: false },
      { control: 'isInternal', show: false },
      { control: 'isPartner', show: false },
      { control: 'isDirect', show: false },
      { control: 'isActive', show: true },
    ]
    this.getAllSujectList();
    this.getAllWebsiteList();
    this.getAllDepartmentList();
  }

  getAllSujectList(){
    // this.payloadData = [];
    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_SUBJECTS}`;

    this._apiService.apiGetMethod(URL).subscribe({
        next: (result: ResponseData) => {
            if(result.success){
                result.data.forEach((value: any) => {
                    value.idvalue = value.idSubject;
                    value.label = value.subjectName;
                    value.showCheckbox = true;
                    value.showEdit = true;
                });
                var allChecked = result.data.find(x => x.isActive == false);
                this.checked = ( allChecked == null  || allChecked == undefined) ? true : false;
                this.payloadData = result.data;
                this.getSubjectFieldById(result.data[0].idSubject )
            }
        },
        error: (err: any) => {
            this.isErrorActivated = true;
            console.log(err);
        }
    });
  }

  getSubjectFieldById(idSite: any) {
    this.payloadSecondData = [];
    const URL = `${environment.WebApiUrl + GetMethods.GET_SUBJECT_FIELD_BY_ID + idSite}`;
    this._apiService.apiGetMethod(URL).subscribe({
        next: (result: ResponseData) => {
            if(result.success){
                result.data.forEach((value: any) => {
                    value.idvalue = value.idSubjectField;
                    value.label = value.name;
                    value.showCheckbox = true;
                    value.showEdit = false;
                });
                var allChecked = result.data.find(x => x.isActive == false);
                this.checked2 = ( allChecked == null  || allChecked == undefined) ? true : false;
                this.payloadSecondData = result.data;
            }
        },
        error: (err: any) => {
            this.isErrorActivated = true;
            console.log(err);
        }
    });
  }

  getAllWebsiteList() {
    const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_SITE_TAGS}`;

    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
          this.allSiteTags = result.data;
        }else{
            this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  getAllDepartmentList(){
    this.departmentList = [];
    const URL = `${environment.WebApiUrl + GetMethods.GET_DEPARTMENT_LIST}`;

    this._apiService.apiGetMethod(URL).subscribe({
        next: (result: ResponseData) => {
            if(result.success){
                result.data.forEach((value: any) => {
                    value.value = value.name;
                    value.label = value.name;
                });
                this.departmentList = result.data.filter((x:any) => x.isActive == true);
            }
        },
        error: (err: any) => {
            console.log(err);
        }
    });
  }

  refreshData($event:any){
    this.getAllSujectList();
  }

  onFilterSiteById(event:any) {
    this.getSubjectFieldById(event);
  }

  hardRefresh(){
    location.reload();
  }
}