<p-card *ngIf="!isErrorActivated" class="card" header="{{ title | translate}}" subheader="" [style]="{ width: 'auto', padding: '0' }">
    <button *ngIf="identity === 3" ngbTooltip="{{ 'Sort the subjects' | translate }}" type="button" (click)="changeViewSites();" class="btn btn-icon custom-card-buttom">
        <i class="feather icon-shuffle "></i>
    </button>
    <div *ngIf="filterData" class="row my-3">
        <div class="col-12">
            <p-dropdown
                styleClass="d-flex w-100"
                [options]="filterData"
                optionLabel="label"
                (onChange)="onFindSiteData($event)"
                [filter]="true" filterBy="label" [showClear]="false"
                [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.siteSelected.errors }"
                optionValue="idvalue">
            </p-dropdown>
        </div>
    </div>

    <div *ngIf="identity === 3 || identity === 4" class="mt-2 py-3 d-flex align-items-center">
        <div class="col-10 col-sm-10 col-md-10 d-flex align-items-center">
            <label class="container-custom me-5">
                <input type="checkbox" [checked]="checked" (change)="UpdateAllItemCheckbox($event.target)">
                <span class="checkmark"></span>
            </label>
            <span class="sub-desc" [ngClass]="{'ms-4': !Media.IsMobile}">{{'SelectALL' | translate}}</span>
        </div>
    </div>

    <div class="row g-0 mt-2 py-3 d-flex align-items-center" *ngFor="let item of headerTitle">
        <div class="col-3 col-md-2 d-flex align-items-center">
            <span class="fw-bold">{{item.title1 | translate}}</span>
        </div>
        <div class="col-7 col-md-8 d-flex align-items-center">
            <span class="fw-bold">{{item.title2 | translate}}</span>
        </div>
        <div class="col-1 col-md-2 d-flex align-items-center justify-content-center">
            <span class="fw-bold" [ngClass]="{'ms-4': Media.IsMobile}">{{item.title3 | translate}}</span>
        </div>
    </div>

    <ng-container *ngIf="!sortView">
        <div class="row g-0 border-top d-flex align-items-center" [ngClass]="{'py-3': !sortView, 'p-0': sortView}" *ngFor="let item of payloadData; index as i">
            <ng-container *ngIf="!sortView" >
                <div *ngIf="item.showCheckbox" class="col-3 col-sm-2 col-md-2 d-flex align-items-center">
                    <label class="container-custom">
                        <input type="checkbox" [checked]="item.isActive" (change)="updateItemByCheckbox($event, item)">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="col-7" [ngClass]="{'col-10': !item.showCheckbox , 'col-md-8': item.showCheckbox}">
                    <span class="sub-desc">{{item.label | translate}}
                        <span *ngIf="identity === 3">{{item.description | translate}} </span>
                    </span>
                </div>
                <div class="col-2 col-sm-2 col-md-2 d-flex justify-content-center">
                    <button class="btn btn-icon" *ngIf="item.showCheckbox" [ngClass]="{'p-1': Media.IsMobile}" (click)="editOrNewItem(item, false)" type="button">
                        <i class="feather icon-edit"></i>
                    </button>
                    <button class="btn btn-icon" [ngClass]="{'p-1': Media.IsMobile}" (click)="deleteItem(item)"  type="button">
                        <i class="feather icon-trash-2"></i>
                    </button>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="sortView">
        <div *ngIf="identity === 3" cdkDropList class="movie-list row g-0 d-flex align-items-center example-list w-100" (cdkDropListDropped)="dropSubjects($event)">
            <div class="col-12 p-3 example-box" *ngFor="let item of payloadData" cdkDrag>
              {{item.label | translate}}
            </div>
        </div>
    </ng-container>

    <div class="w-100 text-center">
        <p-button pTooltip="{{ btnNewText | translate}}" (onClick)="editOrNewItem([], true)" styleClass="p-button-primary mx-1" icon="feather icon-plus-circle" label="{{ btnNewText | translate}}"></p-button>
    </div>

    <div class="modal fade" id="editOrNewItemModal-{{identity}}" tabindex="-1" aria-labelledby="editOrNewItemModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header px-3 py-2">
                    <h5 class="modal-title fw-bold w-100 text-center" id="editOrNewItemModal">{{titleModal | translate}}</h5>
                    <a type="button" class="modal-title px-0" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close"> <i class="fas fa-times"></i> </a>
                </div>
                <form [formGroup]="userForm">
                    <div class="modal-body pt-4">
                        <div class="form-group row g-3">
                            <div *ngIf="identity === 1 || identity === 3 || identity == 4" class="col-sm-12">
                                <dt for="inputTextSelected" class="col-sm-3">{{ TranslateText(identity === 1 ? 'GlobalForm.BrandName' : 'GlobalForm.Name') }}</dt>
                                <input type="text" pInputText class="w-100" formControlName="inputTextSelected" [ngClass]="{ 'is-invalid invalid': submitted && f.inputTextSelected.errors }" />
                                <div *ngIf="submitted && f.inputTextSelected.errors" class="invalid-feedback">
                                    <div *ngIf="f.inputTextSelected.errors.required">{{TranslateText( identity === 1 ? 'GlobalForm.BrandName' : 'GlobalForm.Name' )  + ' ' + TranslateText('GlobalForm.IsRequired') }}</div>
                                </div>
                            </div>

                            <div *ngIf="identity === 1 || identity === 2" class="col-sm-12">
                                <dt for="inputTextSelected" class="col-sm-3">{{ 'GlobalForm.MainSite' | translate }}</dt>
                                <p-dropdown
                                    styleClass="d-flex w-100"
                                    [options]="allSiteTags"
                                    placeholder="{{'Select site' | translate}}"
                                    optionLabel="description"
                                    formControlName="siteSelected"
                                    [filter]="true" filterBy="description" [showClear]="true"
                                    [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.siteSelected.errors }"
                                    optionValue="idSiteTag">
                                </p-dropdown>
                                <div *ngIf="submitted && f.siteSelected.errors" class="invalid-feedback">
                                    <div *ngIf="f.siteSelected.errors.required">{{TranslateText( 'GlobalForm.MainSite' )  + ' ' + TranslateText('GlobalForm.IsRequired') }}</div>
                                </div>
                            </div>

                            <div *ngIf="identity === 1 || identity === 3 || identity == 4" class="col-sm-12">
                                <dt for="inputTextSelected" class="col-sm-12">{{ 'GlobalForm.Department in charge' | translate }}</dt>
                                    <p-dropdown
                                        styleClass="d-flex w-100"
                                        [options]="departmentList"
                                        optionLabel="name"
                                        [filter]="true" filterBy="name" [showClear]="true"
                                        [autoDisplayFirst]="false"
                                        formControlName="department"
                                        [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.department.errors }"
                                        optionValue="idDepartment">
                                        <ng-template let-option pTemplate="item">
                                            <span [class]="'customer-badge status-' + option.idDepartment">{{option.name | translate}}</span>
                                        </ng-template>
                                    </p-dropdown>
                                    <div *ngIf="submitted && f.department.errors" class="invalid-feedback">
                                        <div *ngIf="f.department.errors.required">{{'GlobalForm.Department' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
                                    </div>
                                </div>


                            <div *ngIf="identity === 3 || identity === 4 " class="mt-3">

                                <div *ngIf="identity === 3" class="col-sm-12">
                                    <div class="form-group">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-4">
                                                <label class="pe-3 fw-bold">Ticket Center: </label>
                                            </div>
                                            <div class="col-8">
                                                <div class="d-flex align-items-center ps-5 my-1">
                                                    <label class="container-custom">
                                                        <input type="checkbox" formControlName="isHelp">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <span class="sub-desc pe-3">{{ 'Help' | translate }} </span>
                                                </div>
                                                <div class="d-flex align-items-center ps-5 my-1">
                                                    <label class="container-custom">
                                                        <input type="checkbox" formControlName="isInternal">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <span class="sub-desc pe-3">{{ 'Internal' | translate }} </span>
                                                </div>
                                                <div class="d-flex align-items-center ps-5 my-1">
                                                    <label class="container-custom">
                                                        <input type="checkbox" formControlName="isPartner">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <span class="sub-desc pe-3">{{ 'Partners' | translate }} </span>
                                                </div>
                                                <div class="d-flex align-items-center ps-5 my-1">
                                                    <label class="container-custom">
                                                        <input type="checkbox" formControlName="isDirect">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                    <span class="sub-desc pe-3">{{ 'Direct' | translate }} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="identity === 3" class="col-sm-12 my-3">
                                    <dt for="inputTextDescription" class="col-12">{{ TranslateText('GlobalForm.Description') }}</dt>
                                    <input type="text" pInputText class="w-100" formControlName="inputTextDescription"/>
                                </div>

                                <div *ngIf="identity == 4" class="col-sm-12">
                                    <dt for="inputTextSelected" class="col-sm-12">{{ 'GlobalForm.FormType' | translate }}</dt>
                                    <p-dropdown
                                        styleClass="d-flex w-100"
                                        [options]="listFormType"
                                        placeholder="Select Form Type"
                                        optionLabel="formName"
                                        formControlName="formType"
                                        [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.formType.errors }"
                                        optionValue="idFormType">
                                    </p-dropdown>
                                    <div *ngIf="submitted && f.formType.errors" class="invalid-feedback">
                                        <div *ngIf="f.formType.errors.required">{{TranslateText( 'GlobalForm.FormType' )  + ' ' + TranslateText('GlobalForm.IsRequired') }}</div>
                                    </div>
                                </div>

                                <div class="col-sm-12 my-3">
                                    <dt for="inputTextSpanish" class="col-12">{{ TranslateText('GlobalForm.SpanishTranslation') }}</dt>
                                    <input type="text" pInputText class="w-100" formControlName="inputTextSpanish" [ngClass]="{ 'is-invalid invalid': submitted && f.inputTextSpanish.errors }" />
                                    <div *ngIf="submitted && f.inputTextSpanish.errors" class="invalid-feedback">
                                        <div *ngIf="f.inputTextSpanish.errors.required">{{TranslateText( 'GlobalForm.SpanishTranslation')  + ' ' + TranslateText('GlobalForm.IsRequired') }}</div>
                                    </div>
                                </div>

                                <div class="col-sm-12 my-3">
                                    <dt for="inputTextPortuguese" class="col-12">{{ TranslateText('GlobalForm.PortugueseTranslation') }}</dt>
                                    <input type="text" pInputText class="w-100" formControlName="inputTextPortuguese" [ngClass]="{ 'is-invalid invalid': submitted && f.inputTextPortuguese.errors }" />
                                    <div *ngIf="submitted && f.inputTextPortuguese.errors" class="invalid-feedback">
                                        <div *ngIf="f.inputTextPortuguese.errors.required">{{TranslateText( 'GlobalForm.PortugueseTranslation')  + ' ' + TranslateText('GlobalForm.IsRequired') }}</div>
                                    </div>
                                </div>

                                <div *ngIf="identity === 3 || identity == 4" class="col-sm-12">
                                    <div class="form-group">
                                        <div class="d-flex align-items-center">
                                            <label class="pe-3 fw-bold">Status: </label>
                                            <label class="container-custom">
                                                <input type="checkbox" formControlName="isActive" >
                                                <span class="checkmark"></span>
                                            </label>
                                            <span class="sub-desc pe-3">{{ 'Active' | translate }} <br> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer form-group text-center">
                        <button type="button" class="btn btn-secondary mr-2" data-bs-dismiss="modal" (click)="closeModal()" >{{ 'Close' | translate }}</button>
                        <button type="button" class="btn btn-primary" (click)="createAndUpdateByPayloadData();"><i class="feather icon-save"></i> {{'Save'| translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</p-card>
