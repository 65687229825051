<p-card *ngIf="!showUserInfo" class="card" header="{{'Users' | translate}}" subheader="" [style]="{ width: 'auto', padding: '0' }">
    <p-tabView [scrollable]="true" (onChange)="tabSelected($event.index)" id="user-setup">
        <p-tabPanel header="{{'User.User Manager' | translate}}">
            <p-table #dt
                [value]="payloadData"
                [columns]="cols"
                [scrollable]="true"
                scrollHeight="700px"
                dataKey="id"
                styleClass="p-datatable-striped"
                [rowHover]="true"
                [rows]="20"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[20,40,60]"
                [loading]="loading"
                [paginator]="true"
                responsiveLayout="stack"
                [tableStyle]="{'min-width': '80rem'}"
                currentPageReportTemplate="{{'Showing {first} to {last} of {totalRecords} entries' | translate}}"
                [filterDelay]="0"
                [globalFilterFields]="['name','email','profileName','userTypeName', 'departmentName', 'isActive']">

                <ng-template pTemplate="caption">
                    <div class="row g-3">
                        <div class="col-12">
                            <span class="p-input-icon-left w-100">
                                <i class="feather icon-search"></i>
                                <input class="w-100" pInputText type="text" (input)="dt.filterGlobal($any($event.target) .value, 'contains')" placeholder="{{ 'Search' | translate }}" />
                            </span>
                        </div>
                        <div class="col-12 d-flex align-items-center">
                            <p-columnFilter type="boolean" field="isActive"></p-columnFilter> <span>{{'User.Active Users' | translate}}</span>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>

                    <tr>
                        <th *ngFor="let col of columns" class="text-uppercase">
                            {{ col.header | translate }}
                        </th>
                    </tr>

                </ng-template>

                <ng-template pTemplate="body" let-payloadData>
                    <tr class="p-selectable-row">
                        <td>
                            <span class="p-column-title">Name</span>
                            <span> {{payloadData.name}} </span>
                        </td>
                        <td>
                            <span class="p-column-title">Email</span>
                            <span>{{payloadData.email}}</span>
                        </td>
                        <td>
                            <span class="p-column-title">Department</span>
                            <span *ngIf="payloadData.countDepartment == 1 || payloadData.countDepartment == 0">{{payloadData.departmentName | translate}}</span>
                            <span *ngIf="payloadData.countDepartment != 1 && payloadData.countDepartment != 0" class="text-info" (click)="getDepartmentByUser(payloadData.idUser)"> Multidept </span>
                        </td>
                        <td>
                            <span class="p-column-title">Profile</span>
                            <span>{{payloadData.profileName}}</span>
                        </td>

                        <td>
                            <span class="p-column-title">User Type</span>
                            <span>{{payloadData.userTypeName | translate }}</span>
                        </td>

                        <td>
                            <i class="fas" [ngClass]="{ 'fa-check-circle text-success': payloadData.isActive, 'fa-times-circle text-danger': !payloadData.isActive }"></i> <span> {{(payloadData.isActive) ? 'Active': 'Inactive'}}  </span>
                        </td>

                        <td>
                            <span class="p-column-title">Actions</span>
                            <button *ngIf="userLogin.idUserType == 1 || userLogin.idUser == payloadData.idUser"
                                (click)="editUser(payloadData);" type="button" class="btn btn-icon">
                                <i class="feather icon-edit "></i>
                            </button>

                            <button *ngIf="userLogin.idUserType == 1" (click)="deleteUser(payloadData)"
                                type="button" class="btn btn-icon">
                                <i class="feather icon-trash-2 text-c-red"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td style="padding: 200px; text-align: center;" colspan="12">
                            <span *ngIf="!showHardReloadBtn"> {{ 'GENERICS.NO_DATA_FOUND' | translate }} </span>
                            <span *ngIf="showHardReloadBtn"> {{'GENERICS.UPDATED_ERROR' | translate}} <a class="btn btn-danger" (click)="hardRefresh()"> <i class="feather icon-refresh-cw"></i> {{ 'Refresh' | translate }}</a> </span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

            <div class="modal fade" *ngIf="departmentListByUser" id="modalUserDeparmentList" tabindex="-1" aria-labelledby="modalUserDeparmentListLabel" aria-hidden="true">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalUserDeparmentListLabel">Departments</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <div class="row g-3">
                                <span *ngFor="let item of departmentListByUser" class="col-12"> {{item.name}} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>

        <!-- NEW USER PANEL -->
        <p-tabPanel header="{{'User.New User' | translate}}">
            <app-user-form
                *ngIf="tabOptionSelected == 1"
                [userSelected]="userSelected"
                [profileList]="profileList"
                [UserTypeList]="UserTypeList"
                [departmentList]="departmentList"
                [tabSelected]="tabOptionSelected"
                (onLoadPayloadUsers)="onLoadPayloadUsers($event)"
            ></app-user-form>
        </p-tabPanel>
    </p-tabView>
</p-card>

<div *ngIf="showUserInfo" class="stickty-button">
    <p-button (onClick)="onStepChange(false);" pTooltip="Back to Tickets"  styleClass="p-button-primary ms-2" icon="feather icon-arrow-left" label="Back"></p-button>
</div>

<p-card *ngIf="showUserInfo" class="card custom-top-margin" header="{{'Users' | translate}}" subheader="" [style]="{ width: 'auto', padding: '0' }">
    <app-user-form
        *ngIf="tabOptionSelected == 0"
        [userSelected]="userSelected"
        [profileList]="profileList"
        [UserTypeList]="UserTypeList"
        [departmentList]="departmentList"
        [tabSelected]="tabOptionSelected"
        (onLoadPayloadUsers)="onLoadPayloadUsers($event)"
    ></app-user-form>

    <div class="text-center">
        <p-button (onClick)="getDepartmentListByUser();" pTooltip="{{'User.Departments' | translate}}"  styleClass="p-button-secondary my-2" icon="" label="{{'User.Departments' | translate}}"></p-button>
    </div>

    <div class="row my-4 g-3 justify-content-center" [ngClass]="{'gap-1': Media.IsMobile, 'gap-5': !Media.IsMobile}">
        <div *ngIf="userLogin.idUserType == 1 || userLogin.idUserType == 3" class="col-5 col-sm-2 d-flex align-items-center card card-custom py-4">
            <p-inputSwitch (onChange)="UpdateTableInfo($event, 3)" [(ngModel)]="userSelected.isActive"></p-inputSwitch>
            <span class="ms-2">{{'User.Active User' | translate}}</span>
        </div>

        <div class="col-5 col-sm-2 d-flex align-items-center card card-custom py-4">
            <p-inputSwitch (onChange)="UpdateTableInfo($event, 4)" [(ngModel)]="userSelected.viewOnly"></p-inputSwitch>
            <span class="ms-2">{{'View Only' | translate}}</span>
        </div>

        <div class="col-5 col-sm-2 d-flex align-items-center card card-custom py-4">
            <p-inputSwitch (onChange)="UpdateTableInfo($event, 5)" [(ngModel)]="userSelected.releaseTicket"></p-inputSwitch>
            <span class="ms-2">{{'Release Ticket' | translate}}</span>
        </div>

        <div class="col-5 col-sm-2 d-flex align-items-center card card-custom py-4" *ngIf="userLogin.idUserType == 1">
            <p-inputSwitch (onChange)="UpdateTableInfo($event, 6)" [(ngModel)]="userSelected.isTFA"></p-inputSwitch>
            <span class="ms-2">TFA</span>
        </div>
    </div>

    <div class="row g-2 gap-5 justify-content-center">
        <div class="col-12 col-sm-5 card card-custom p-3">
            <h5>{{'User.User Rights' | translate}}</h5>
            <div class="row d-flex align-items-center p-3">
                <div class="col-2 col-sm-1">
                    <label class="container-custom">
                        <input type="checkbox" [checked]="allChecked" (click)="updateAllRights($event)">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="col-8 col-sm-9">
                    <span class="sub-desc">{{'SelectALL' | translate}}</span>
                </div>
            </div>
            <div class="row d-flex align-items-center p-3" *ngFor="let item of listsUsersMenu">
                <div class="col-2 col-sm-1">
                    <label class="container-custom">
                        <input type="checkbox" [checked]="item.isActive" (click)="updateStatus($event, item)">
                        <span class="checkmark"></span>
                    </label>
                  </div>
                  <div class="col-8 col-sm-9">
                    <span class="sub-desc">{{item.name | translate }}</span>
                  </div>
            </div>
        </div>
        <div class="col-12 col-sm-5 card card-custom p-3">
            <h5 class="mb-2">{{'Profile.Sites By User' | translate}}</h5>
            <div class="row d-flex justify-content-between align-items-center border-bottom py-3">
                <div class="col-6">
                    <div class="d-flex align-items-center" *ngIf="userLogin.idUserType == 1 || userLogin.idUserType == 3">
                        <p-inputSwitch (onChange)="UpdateTableInfo($event, 1)" [(ngModel)]="userSelected.accessAllSites"></p-inputSwitch>
                        <span class="ms-2">{{'User.Active for all sites' | translate}}</span>
                    </div>
                    <div class="text-start pt-3" *ngIf="userSelected.accessAllSites">
                        <h6>{{'User.All sites are active' | translate}}</h6>
                    </div>
                </div>
                <div class="col-6">
                    <div *ngIf="!userSelected.accessAllSites && userLogin.idUserType == 1 || userLogin.idUserType == 3" class="text-end">
                        <button data-bs-toggle="modal" data-bs-target="#modalId" class="btn btn-secondary btn-icon"  type="button">
                            <i class="feather icon-plus "></i> {{'User.Add new site' | translate}}
                        </button>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="listsUserSites">
                <div *ngIf="!userSelected.accessAllSites">
                    <div class="row d-flex align-items-center p-2" *ngFor="let item of listsUserSites">
                        <div class="col-8 col-sm-9">
                            <span class="sub-desc">{{item.name | translate }}</span>
                        </div>
                        <div class="col-2 col-sm-3 text-center">
                            <button *ngIf="userLogin.idUserType == 1 || userLogin.idUserType == 3" (click)="deleteSiteForUser(item)" type="button" class="btn btn-icon">
                                <i class="feather icon-trash-2 text-c-red"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

      <!-- Modal -->
      <div class="modal fade" id="modalId" tabindex="-1" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalTitleId">{{'User.Site by user' | translate}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p-dropdown
                        styleClass="d-flex w-100"
                        [options]="allSiteTags"
                        placeholder="{{'Select site' | translate}}"
                        optionLabel="description"
                        [(ngModel)]="siteTagSelected"
                        [filter]="true" filterBy="description" [showClear]="true"
                        optionValue="idSiteTag">
                    </p-dropdown>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ 'Close' | translate }}</button>
                    <button type="button" class="btn btn-primary" (click)="UpdateTableInfo(null, 7)">Save</button>
                </div>
            </div>
        </div>
      </div>

        <!-- Modal -->
        <div class="modal fade" id="listInternalDepartmentModal" tabindex="-1" role="dialog" aria-labelledby="listInternalDepartmentModal" aria-hidden="true">
            <div class="modal-dialog modal-dialog-scrollable" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="listInternalDepartmentModal">{{'User.Site by user' | translate}}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row d-flex align-items-center p-3" *ngFor="let item of listInternalDepartment">
                            <div class="col-2 col-sm-1">
                                <label class="container-custom">
                                    <input type="checkbox" [checked]="item.isActive" (click)="updateDepartmentChecked($event, item)">
                                    <span class="checkmark"></span>
                                </label>
                              </div>
                              <div class="col-8 col-sm-9">
                                <span class="sub-desc">{{item.name | translate }}</span>
                              </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ 'Close' | translate }}</button>
                    </div>
                </div>
            </div>
          </div>
</p-card>
