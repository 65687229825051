<ng-container *ngIf="itemSelected">
    <div class="row my-4 justify-content-center" [ngClass]="{'gap-0': Media.IsMobile, 'gap-5': !Media.IsMobile}">
        <div class="col-12 col-sm-12 col-md-12 col-lg-2 d-flex align-items-center card card-custom py-4">
            <dt for="inputProfileName" class="col-12 pb-3">{{ 'Profile.Profile name' | translate }}</dt>
            <input pInputText type="text" [(ngModel)]="itemSelected.profileName" placeholder="Name" class="p-column-filter w-100">
        </div>

        <div *ngIf="tabSelected == 0" class="col-12 col-sm-12 col-md-12 col-lg-2 d-flex align-items-center card card-custom py-4 text-center">
            <label class="container-custom">
                <input type="checkbox" [(ngModel)]="itemSelected.applyAllUsers" [checked]="itemSelected.applyAllUsers">
                <span class="checkmark"></span>
            </label>
            <span class="pt-3">{{'Profile.Apply all changes to all users with this profile' | translate}}</span>
        </div>

        <div *ngIf="tabSelected == 0 || tabSelected == 1 || tabSelected == 2" class="col-5 col-sm-5 col-md-5 col-lg-2 d-flex align-items-center card card-custom py-4" [ngClass]="{'mx-2': Media.IsMobile}">
            <p-inputSwitch [(ngModel)]="itemSelected.isActive"></p-inputSwitch>
            <span class="ms-2">{{'Profile.Active Profile' | translate}}</span>
        </div>

        <div *ngIf="tabSelected == 0 || tabSelected == 1" class="col-5 col-sm-5 col-md-5 col-lg-2 d-flex align-items-center card card-custom py-4" [ngClass]="{'mx-2': Media.IsMobile}">
            <p-inputSwitch [(ngModel)]="itemSelected.accessAllSites"></p-inputSwitch>
            <span class="ms-2">{{'Profile.Active for all sites' | translate}}</span>
        </div>
    </div>
    <div class="row g-2 gap-5 justify-content-center">
        <div class="col-12 col-sm-5 card card-custom p-3">
            <h5 *ngIf="tabSelected != 3" class="mb-2">{{'User.User Rights' | translate}}</h5>
            <h5 *ngIf="tabSelected == 3" class="mb-2">{{'Profile.Subjects' | translate}}</h5>
            <button *ngIf="tabSelected === 2 || tabSelected === 3" ngbTooltip="{{ 'Sort the subjects' | translate }}" type="button" (click)="changeViewSites();" class="btn btn-icon custom-card-buttom">
                <i class="feather icon-shuffle "></i>
            </button>
            <ng-container *ngIf="!sortView">
                <div class="row d-flex align-items-center p-3">
                    <div class="col-2 col-sm-1">
                        <label class="container-custom">
                            <input type="checkbox" (change)="selectAllCheckbox($event.target, 1)" [checked]="allChecked">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-8 col-sm-9">
                        <span class="sub-desc">{{'SelectALL' | translate}}</span>
                    </div>
                </div>
                <div class="row d-flex align-items-center p-3 border-top" *ngFor="let item of itemSelected.payload">
                    <div class="col-2 col-sm-1">
                        <label class="container-custom">
                            <input type="checkbox" [(ngModel)]="item.active" [checked]="item.active" (click)="updateCheckbox($event.target, item, 1)">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-8 col-sm-9">
                        <span class="sub-desc">{{item.title | translate}}</span>
                    </div>
                    <div class="col-2 col-sm-2" *ngIf="tabSelected == 3">
                        <a class="btn btn-icon p-0" (click)="getSubjectFieldById(item);" type="button">
                            <i class="feather icon-plus "></i>
                        </a>
                    </div>
                    <div class="col-2 col-sm-2" *ngIf="tabSelected == 2 ">
                        <a class="btn btn-icon p-0" (click)="getSubjectFieldByIdWithoutCall(item);" type="button">
                            <i class="feather icon-plus "></i>
                        </a>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="sortView">
                <div cdkDropList class="movie-list row g-0 d-flex align-items-center example-list w-100" (cdkDropListDropped)="drop($event)">
                    <div class="col-12 p-3 example-box" *ngFor="let item of itemSelected.payload" cdkDrag>
                      {{item.title | translate}}
                    </div>
                </div>   
            </ng-container>
        </div>

        <!-- Second CARD -->

        <div class="col-12 col-sm-5 card card-custom p-3">
            <h5 *ngIf="tabSelected != 3 && tabSelected != 2" class="mb-2">{{'Profile.Sites By User' | translate}}</h5>
            <h5 *ngIf="tabSelected == 2" class="mb-2">{{'Profile.Fields by Subject' | translate}}</h5>
            <h5 *ngIf="tabSelected == 3" class="mb-2">{{'Profile.Subject Fields' | translate}}</h5>
            <div *ngIf="showSubjectCheckbox" class="row d-flex align-items-center p-3">
                <div class="col-2 col-sm-1">
                    <label class="container-custom">
                        <input type="checkbox" (change)="selectAllCheckbox($event.target, 2)" [checked]="allChecked2">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="col-8 col-sm-9">
                    <span class="sub-desc">{{'SelectALL' | translate}}</span>
                </div>
            </div>
            <ng-container *ngIf="!itemSelected.accessAllSites">
                <div class="row d-flex align-items-center border-top p-3" *ngFor="let item of itemSelected.accessSite">
                    <div *ngIf="showSubjectCheckbox" class="col-2 col-sm-1">
                        <label class="container-custom">
                            <input type="checkbox" [(ngModel)]="item.active" [checked]="item.active" (click)="updateCheckbox($event.target, item, 2)">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                    <div class="col-8 col-sm-9">
                        <span class="sub-desc">{{item.description | translate}}</span>
                    </div>
                    <div *ngIf="!showSubjectCheckbox" class="col-2 col-sm-2 text-center">
                        <button (click)="deleteSiteForUser(item)" type="button" class="btn btn-icon">
                            <i class="feather icon-trash-2 text-c-red"></i>
                        </button>
                    </div>
                </div>
            </ng-container>
            <ng-container  *ngIf="!itemSelected.accessAllSites">
                <div *ngIf="tabSelected == 0 || tabSelected == 1">
                    <button data-bs-toggle="modal" data-bs-target="#modalId" class="btn btn-secondary btn-icon"  type="button">
                        <i class="feather icon-plus "></i> {{'User.Add new site' | translate}}
                    </button> 
                </div>
            </ng-container>
            <ng-container *ngIf="itemSelected.accessAllSites">
                <div class="text-start pt-3">
                    <h6>{{'User.All sites are active' | translate}}</h6>
                </div> 
            </ng-container>
            <ng-container *ngIf="tabSelected == 2 || tabSelected == 3">
                <div *ngIf="!showSubjectCheckbox">
                    <div class="alert alert-primary"><i class="feather icon-arrow-left"></i> {{'Select the subject you want to view' | translate}}</div>
                </div>  
            </ng-container>
        </div>
        <div *ngIf="tabSelected == 1 || tabSelected == 3" class="text-center">
            <p-button (onClick)="OnAddNewInfo(tabSelected);" styleClass="p-button-secondary" icon="feather icon-save"  pTooltip="{{'Save Changes' | translate}}" label="{{'Save Changes' | translate}}"></p-button>
        </div>
    </div>    
</ng-container>

<div class="modal fade" id="modalId" tabindex="-1" role="dialog" aria-labelledby="modalTitleId" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="modalTitleId">{{'Profile.Site by user' | translate}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <p-dropdown  
                    styleClass="d-flex w-100" 
                    [options]="allSiteTags" 
                    placeholder="{{'Select site' | translate}}"
                    optionLabel="description"
                    [(ngModel)]="siteTagSelected"
                    [filter]="true" filterBy="description" [showClear]="true"
                    optionValue="idSiteTag">
                </p-dropdown>
                <div *ngIf="messageResult != ''" class="alert alert-danger mt-3" role="alert">
                    {{messageResult | translate }}
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ 'Close' | translate }}</button>
                <button type="button" class="btn btn-primary" (click)="addSite(tabSelected)">Save</button>
            </div>
        </div>
    </div>
  </div>