<app-checkbox-editor *ngIf="identity === 3"
    [title]="'Department'"
    [identity]="identity"
    [payloadData]="payloadData"
    [isErrorActivated]="isErrorActivated"
    [checked]="checked"
    [modalTitleNewText]="'Dept.NewDepartment'"
    [modalTitleEditText]="'Dept.EditDepartment'"
    [newPostEvent]="9"
    [editPostEvent]="10"
    [deletePostEvent]="11"
    [selectAllCheckbox]="12"
    (refreshData)="refreshData($event)"
>
</app-checkbox-editor>