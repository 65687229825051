import { Component, OnInit } from '@angular/core';
import { GetMethods, Methods, PutMethods, PostMethods } from '@methods/methods';
import { User } from '@models/auth-model';
import { ConfigByDomain, ConfigSite, OpenTicketDTO, ResponseData, SiteProfile, SubjectFields, SubjectTickets, UserBySite, WebsiteFieldsSetup, WebsiteSetup, WebsiteSetup_SiteProfile, siteTag, siteTagTEMP } from '@models/models';
import { ApiService } from '@services/api.service';
import { MediaResponse, MediaService } from '@services/media.service';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CdkDragDrop, moveItemInArray, CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import { AllSiteList, ClientTypeList, Filters } from '@methods/constants';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
declare const bootstrap: any;

@Component({
    selector: 'app-website-setup',
    templateUrl: './website-setup.component.html'
})
export class WebsiteSetupComponent implements OnInit {
    public load: boolean = false;
    mediaSubscription: Subscription;
    Media: MediaResponse;
    userLogin: User;
    //  -- WEBSITE AND AGENT SITE TAB MODAL --
    websiteTabModal: any;
    viewConfigProfile: boolean = false;

    ctsAdminModal: any;
    eposModal: any;
    eposAdminModal: any;
    allSitesModal: any;

    AllSiteList: Filters[];

    _configData: ConfigSite;
    messageSuccess: boolean = false;
    messageError: boolean = false;

    _model: any = {};
    getFieldsAllSites: boolean = false;
    selectPlayerAgent: boolean = false;
    SubSelected: string = "";
    listFields: SubjectFields[];

    _userInfoCTS: User;
    _AllSitesToUpdate: number = 1;

    _listSubjects: SubjectTickets[];
    _WebSetupItemUpdate: WebsiteSetup;

    _listWebSites: any;
    _listDomains: ConfigByDomain[];
    _listAgentWebSites: siteTag[];
    _listCTSAdminWebSites: siteTag[];
    _listEposWebSites: siteTag[];
    _listEposAdminWebSites: siteTag[];


    _listsUserSites: UserBySite[];
    _listsUserAgentSites: UserBySite[];
    _listsUserCTSAdminSites: UserBySite[];
    _listsUserEposSites: UserBySite[];
    _listsUserEposAdminSites: UserBySite[];

    _listWebSitesTEMP: siteTagTEMP[] = [];
    _listWebSitesSetup: WebsiteSetup[] = [];

    _listAgentWebSitesTEMP: siteTagTEMP[] = [];
    _listAgentWebSitesSetup: WebsiteSetup[] = [];

    _listCTSAdminWebSitesTEMP: siteTagTEMP[] = [];
    _listCTSAdminWebSitesSetup: WebsiteSetup[] = [];

    _listEposWebSitesTEMP: siteTagTEMP[] = [];
    _listEposWebSitesSetup: WebsiteSetup[] = [];

    _listEposAdminWebSitesTEMP: siteTagTEMP[] = [];
    _listEposAdminWebSitesSetup: WebsiteSetup[] = [];

    _listAllSitesToUpdate: siteTagTEMP[] = [];

    _checked: boolean = true;
    _checkedAgentSite: boolean = true;
    _checkedCTSAdmin: boolean = true;
    _checkedEpos: boolean = true;
    _checkedEposAdmin: boolean = true;
    _checkedAll: boolean = false;

    _NewSubject: SubjectTickets = new SubjectTickets();
    _NewSTEMP: siteTagTEMP;

    idSub: any = 1;
    idSiteSelected: any = 1;
    idAgentSiteSelected: any = 1;
    idCtsAdminSiteSelected: any = 1;
    idEposSiteSelected: any = 1;
    idEposAdminSiteSelected: any = 1;

    description2: any;
    description: any = "Select Site";

    /* Ticket Center Website Setup */
    idWebsiteSelected: any = 0;
    idDomainSelected: any = 0;
    ticketCenterSelected: any = 0;
    languageSelected: any = '';
    spanishSelected: boolean;
    englishSelected: boolean;
    newDomainInfo: ConfigByDomain = new ConfigByDomain;

    descriptionAgents: any;
    descriptionA: any = "Select Site";

    descriptionCTSAdmin: any;
    descriptionCTSAdmin2: any = "Select Site";

    descriptionEpos: any;
    descriptionEpos2: any = "Select Site";

    descriptionEposAdmin: any;
    descriptionEposAdmin2: any = "Select Site";

    viewSortAgents: boolean = false;
    viewSortPlayers: boolean = false;
    viewSortCTSAdmin: boolean = false;
    viewSortEpos: boolean = false;
    viewSortEposAdmin: boolean = false;


    _listSubjectFields: WebsiteFieldsSetup[] = [];

    UseProfile: WebsiteSetup_SiteProfile = new WebsiteSetup_SiteProfile();
    listAllWebsiteProfiles: SiteProfile[] = [];

    clicks: number = 0;
    type: any = 'Select option';

    constructor(private _apiService: ApiService, private _mediaService: MediaService, private _notificationService: NotificationService, private _translateService: TranslateService) {
        this.mediaSubscription = this._mediaService.subscribeMedia().subscribe(media => {
            this.Media = media;
        });

        AllSiteList.forEach(e => {
            e.label = this.TranslateText(e.label)
        })

        this.AllSiteList = AllSiteList;
    }

    ngOnInit() {

        this._userInfoCTS = JSON.parse(localStorage.getItem('UserAccessCTS')!);

        this.GetSubjects();
        // // verificar que tenga acceso a todos los sitios o solo algunos!!!
        this.SelectSites();
        this.UpdateOpenTicketStatus();
        this.GetAllWebsitesProfiles();
        this.UseProfile.siteList = [];
        this.getWebsiteDomainInfo(1);
        this.InitModals();
    }

    SuccessProcess() {
        this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
    }

    tabSelected(event: any) {

    }

    ErrorProcess() {
        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
    }

    UpdateOpenTicketStatus() {

        let obj: OpenTicketDTO = new OpenTicketDTO();
        obj.IdTicket = 0;
        obj.openStatus = false;
        obj.User = this._userInfoCTS.idUser;

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_TICKET_STATUS}`;

        this._apiService.apiPostMethod(URL, obj).subscribe({
            next: (result: ResponseData) => {},
            error: (err: any) => {
                console.log(err);
                this.onClick();
            }
        });
    }

    SelectSites() {
        if (this._userInfoCTS.accessAllSites) {
            this.GetAllSites();
        }
        else {
            this.GetSitesByUser();
        }
    }

    GetSubjects() {

        const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_SUBJECTS}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this._listSubjects = result.data;
                    this.CreateListUpdateAllSites();
                }
            },
            error: (err: any) => {
                console.log(err);
                this.onClick();
                // this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    GetAllSites() {

        const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_SITE_TAGS}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this._listWebSites = this._listAgentWebSites = this._listCTSAdminWebSites = this._listEposWebSites = this._listEposAdminWebSites = result.data;
                    this.description = this.descriptionAgents = this.descriptionCTSAdmin = this.descriptionEpos = this.descriptionEposAdmin = this._listWebSites[0].description;
                    this.onChange("", 1);
                    this.onChangeAgentWebsite("", 1);
                    this.onChangeCTSAdmin("", 1);
                    this.onChangeEpos("", 1);
                    this.onChangeEposAdmin("", 1);
                } else {
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
                console.log(err);
                this.onClick();
                // this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    GetSitesByUser() {

        const URL = `${environment.WebApiUrl + GetMethods.GET_SITE_BY_USER + this._userInfoCTS.idUser}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                this._listsUserSites = this._listsUserAgentSites = this._listsUserCTSAdminSites = this._listsUserEposSites = this._listsUserEposAdminSites = result.data;

                this.description = this.descriptionAgents = this.descriptionCTSAdmin = this.descriptionEpos = this.descriptionEposAdmin = this._listsUserSites[0].name;
                this.onChange("", result.data[0].idSite);
                this.onChangeAgentWebsite("", result.data[0].idSite);
                this.onChangeCTSAdmin("", result.data[0].idSite);
                this.onChangeEpos("", result.data[0].idSite);
                this.onChangeEposAdmin("", result.data[0].idSite);
            },
            error: (err: any) => {
                console.log(err);
                this.onClick();
                // this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    CreateListUpdateAllSites() {
        this._listSubjects.forEach(element => {
            let newItem: siteTagTEMP = {
                idSiteTag: this._userInfoCTS.idUser, // enviar en este espacio el idUser para verificar si tiene acceso a todos los sitios
                description: element.subjectName,
                idSubject: element.idSubject,
                subjectName: element.subjectName,
                isActive: false,
                position: 500,
                descriptionSubject: element.description
            };

            this._listAllSitesToUpdate.push(newItem);

        });
    }

    onChange(event: any, idSite: number) {

        if (this._userInfoCTS.accessAllSites) {
            this.idSiteSelected = (idSite != 0) ? idSite : this._listWebSites.find((x: { idSiteTag: number; }) => x.idSiteTag == event.value).idSiteTag;
        } else {
            this.idSiteSelected = (idSite != 0) ? idSite : this._listsUserSites.find(x => x.name == event.value)?.idSite;
        }

        const URL = `${environment.WebApiUrl + GetMethods.GET_WEBSITE_SETUP_BY_SITE + this.idSiteSelected}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    if (result.data.length > 0) {
                        this._listWebSitesSetup = result.data;
                        this.test(event, this.idSiteSelected);
                    }
                }
            },
            error: (err: any) => {
                console.log(err);
                this.onClick();
                // this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });

    }

    onChangeAgentWebsite(event: any, idSite: number) {
        if (this._userInfoCTS.accessAllSites) {
            this.idAgentSiteSelected = (idSite != 0) ? idSite : this._listAgentWebSites.find((x: { idSiteTag: number; }) => x.idSiteTag == event.value)?.idSiteTag;
        } else {
            this.idAgentSiteSelected = (idSite != 0) ? idSite : this._listsUserAgentSites.find(x => x.name == event)?.idSite;
        }

        const URL = `${environment.WebApiUrl + GetMethods.GET_AGENTS_WEBSITE_SETUP_BY_SITE + this.idAgentSiteSelected}`;
        //this._apiService.apiPostMethod(URL, this.newDomainInfo).subscribe({
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    if (result.data.length > 0) {
                        this._listAgentWebSitesSetup = result.data;
                        this.testAgent(event, this.idAgentSiteSelected);
                    }
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }


    onChangeCTSAdmin(event: any, idSite: number) {
        if (this._userInfoCTS.accessAllSites) {
            this.idCtsAdminSiteSelected = (idSite != 0) ? idSite : this._listCTSAdminWebSites.find((x: { idSiteTag: number; }) => x.idSiteTag == event.value)?.idSiteTag;
        } else {
            this.idCtsAdminSiteSelected = (idSite != 0) ? idSite : this._listsUserCTSAdminSites.find(x => x.name == event)?.idSite;
        }

        const URL = `${environment.WebApiUrl + GetMethods.GET_CTS_ADMINWEBSITE_SETUP_BY_ID_SITE + this.idCtsAdminSiteSelected}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    if (result.data.length > 0) {
                        this._listCTSAdminWebSitesSetup = result.data;
                        this.CreateListCTSAdmin(event, this.idCtsAdminSiteSelected);
                    }
                } else {
                    this.ErrorProcess();
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });

    }

    onChangeEpos(event: any, idSite: number) {
        if (this._userInfoCTS.accessAllSites) {
            this.idEposSiteSelected = (idSite != 0) ? idSite : this._listEposWebSites.find((x: { idSiteTag: number; }) => x.idSiteTag == event.value)?.idSiteTag;
        } else {
            this.idEposSiteSelected = (idSite != 0) ? idSite : this._listsUserEposSites.find(x => x.name == event)?.idSite;
        }

        const URL = `${environment.WebApiUrl + GetMethods.GET_EPOS_SETUP_BY_ID_SITE + this.idEposSiteSelected}`;
        //this._apiService.apiPostMethod(URL, this.UseProfile).subscribe({
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this._listEposWebSitesSetup = result.data;
                    this.CreateListEpos(event, this.idEposSiteSelected);
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    onChangeEposAdmin(event, idSite: number) {
        if (this._userInfoCTS.accessAllSites) {
            this.idEposAdminSiteSelected = (idSite != 0) ? idSite : this._listEposAdminWebSites.find((x: { idSiteTag: number; }) => x.idSiteTag == event.value)?.idSiteTag;
        } else {
            this.idEposAdminSiteSelected = (idSite != 0) ? idSite : this._listsUserEposAdminSites.find(x => x.name == event)?.idSite;
        }
        const URL = `${environment.WebApiUrl + GetMethods.GET_EPOS_ADMIN_SETUP_BY_ID_SITE + this.idEposAdminSiteSelected}`;
        //this._apiService.apiPostMethod(URL, this._listEposWebSitesTEMP).subscribe({
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this._listEposAdminWebSitesSetup = result.data;
                    this.CreateListEposAdmin(event, this.idEposSiteSelected);
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });

    }


    /* Ticket Center */
    getWebsiteDomainInfo(idSite: number) {
        this.idWebsiteSelected = idSite;

        const URL = `${environment.WebApiUrl + GetMethods.GET_DOMAIN_BY_ID_SITE + this.idWebsiteSelected}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this._listDomains = result.data;
                    if (this._listDomains.length != 0) {
                        this._listDomains = result.data;
                        this.idDomainSelected = this._listDomains[0].idConfigByDomain;
                        this.getDomainInfo();
                    } else {
                        this.getWebsiteDomainInfo(1);
                        this.ErrorProcess();
                    }
                }
            },
            error: (err: any) => {
                console.log(err);
                this.onClick();
                // this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    getDomainInfo() {
        this.ticketCenterSelected = this._listDomains.find(x => x.idConfigByDomain == this.idDomainSelected)?.idSiteType;
        this.languageSelected = this._listDomains.find(x => x.idConfigByDomain == this.idDomainSelected)?.language;

        if (this.languageSelected == "en") {
            this.spanishSelected = false;
            this.englishSelected = true;
        } else {
            this.spanishSelected = true;
            this.englishSelected = false;
        }
    }

    onChangeDomain() {
        this.getDomainInfo();
    }

    onChangeWebsiteTicket(event: any) {
        if (this._userInfoCTS.accessAllSites) {
            this.idWebsiteSelected = event.value;
            this.getWebsiteDomainInfo(this.idWebsiteSelected);
        }
    }

    onHelpTicketChange(event: any) {
        if (event.target.checked) {
            this.ticketCenterSelected = 1;
        }
        this.UpdateDomainByIdSite();
    }

    onPartnersTicketChange(event: any) {
        if (event.target.checked) {
            this.ticketCenterSelected = 2;
        }
        this.UpdateDomainByIdSite();
    }

    onInternalTicketChange(event: any) {
        if (event.target.checked) {
            this.ticketCenterSelected = 3;
        }
        this.UpdateDomainByIdSite();
    }


    onChangeSpanishSelected(event: any) {
        if (event.target.checked) {
            this.spanishSelected = true;
            this.englishSelected = false;
            this.languageSelected = "es"
        } else if (!event.target.checked) {
            this.spanishSelected = false;
            this.englishSelected = true;
            this.languageSelected = "en"
        }
        this.UpdateDomainByIdSite();
    }

    onChangeEnglishSelected(event: any) {
        if (event.target.checked) {
            this.englishSelected = true;
            this.spanishSelected = false;
            this.languageSelected = "en"
        } else if (!event.target.checked) {
            this.englishSelected = false;
            this.spanishSelected = true;
            this.languageSelected = "es"
        }
        this.UpdateDomainByIdSite();
    }

    UpdateDomainByIdSite() {
        this.newDomainInfo.idConfigByDomain = this.idDomainSelected;
        this.newDomainInfo.language = this.languageSelected;
        this.newDomainInfo.idSiteType = this.ticketCenterSelected;

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_DOMAIN_BY_ID_SITE}`;
        this._apiService.apiPostMethod(URL, this.newDomainInfo).subscribe({

            next: (result: ResponseData) => {
                if (result.success) {
                    this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                } else {
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    /* ********************* */



    test(description: any, idSite: number) {
        this._listWebSitesTEMP = [];

        if (this._listSubjects.length > 0) {
            this._checked = true;

            this._listSubjects.forEach(element => {

                var temp = this._listWebSitesSetup.find(x => x.idSubject == element.idSubject);

                if (temp != undefined && temp != null) {
                    if (temp.isActive == false) {
                        this._checked = false;
                    }
                    this._NewSTEMP = {
                        idSiteTag: temp.idSite, description: description,
                        idSubject: element.idSubject, subjectName: element.subjectName, isActive: temp.isActive,
                        position: temp.position, descriptionSubject: element.description
                    }
                }
                else {
                    this._checked = false;
                    this._NewSTEMP = {
                        idSiteTag: 1, description: description, idSubject: element.idSubject,
                        subjectName: element.subjectName, isActive: false, position: 500, descriptionSubject: element.description
                    }
                }
                this._listWebSitesTEMP.push(this._NewSTEMP);



            });
            this._listWebSitesTEMP.sort((a, b) => a.position - b.position); // Ascending sort

        }
        this.load = true;
    }


    testAgent(description: any, idSite: number) {
        this._listAgentWebSitesTEMP = [];

        if (this._listSubjects.length > 0) {
            this._checkedAgentSite = true;

            this._listSubjects.forEach(element => {

                var temp = this._listAgentWebSitesSetup.find(x => x.idSubject == element.idSubject);

                if (temp != undefined && temp != null) {
                    if (temp.isActive == false) {
                        this._checkedAgentSite = false;
                    }
                    this._NewSTEMP = {
                        idSiteTag: temp.idSite, description: description,
                        idSubject: element.idSubject, subjectName: element.subjectName, isActive: temp.isActive,
                        position: temp.position, descriptionSubject: element.description
                    }
                }
                else {
                    this._checkedAgentSite = false;
                    this._NewSTEMP = {
                        idSiteTag: 1, description: description, idSubject: element.idSubject,
                        subjectName: element.subjectName, isActive: false, position: 500, descriptionSubject: element.description
                    }
                }
                this._listAgentWebSitesTEMP.push(this._NewSTEMP);

            });

            this._listAgentWebSitesTEMP.sort((a, b) => a.position - b.position); // Ascending sort

        }
        this.load = true;
    }



    CreateListCTSAdmin(description: any, idSite: number) {
        this._listCTSAdminWebSitesTEMP = [];

        if (this._listSubjects.length > 0) {
            this._checkedCTSAdmin = true;

            this._listSubjects.forEach(element => {

                var temp = this._listCTSAdminWebSitesSetup.find(x => x.idSubject == element.idSubject);

                if (temp != undefined && temp != null) {
                    if (temp.isActive == false) {
                        this._checkedCTSAdmin = false;
                    }
                    this._NewSTEMP = {
                        idSiteTag: temp.idSite, description: description,
                        idSubject: element.idSubject, subjectName: element.subjectName, isActive: temp.isActive,
                        position: temp.position, descriptionSubject: element.description
                    }
                }
                else {
                    this._checkedCTSAdmin = false;
                    this._NewSTEMP = {
                        idSiteTag: 1, description: description, idSubject: element.idSubject,
                        subjectName: element.subjectName, isActive: false, position: 500, descriptionSubject: element.description
                    }
                }
                this._listCTSAdminWebSitesTEMP.push(this._NewSTEMP);
            });
            this._listCTSAdminWebSitesTEMP.sort((a, b) => a.position - b.position); // Ascending sort
        }
        this.load = true;
    }


    // Editar para cada uno de los sitios

    UpdateItemWebsiteSetup(event: any, idSite: number, idSubject: number) {

        this._WebSetupItemUpdate = { idWebsiteSetup: 0, idSite: this.idSiteSelected, idSubject: idSubject, isActive: event.checked, position: 0 }

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_WEBSITE_SETUP}`;
        //
        this._apiService.apiPostMethod(URL, this._WebSetupItemUpdate).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.SuccessProcess();
                    this.onChange("", this.idSiteSelected)
                } else {
                    this.ErrorProcess()
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    UpdateAllItemWebsiteSetup(event: any) {

        this._listWebSitesTEMP.forEach(element => {
            element.isActive = event.checked;
            element.idSiteTag = this.idSiteSelected;
        });

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ALL_ITEM_WEBSITE_SETUP}`;

        this._apiService.apiPostMethod(URL, this._listWebSitesTEMP).subscribe({
            next: (result: ResponseData) => {
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });

    }

    UpdateAllItemCtsAdminWebsiteSetup(event: any) {
        this._listCTSAdminWebSitesTEMP.forEach(element => {
            element.isActive = event.checked;
            element.idSiteTag = this.idSiteSelected;
        });

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ALL_ITEM_CTS_ADMIN_WEBSITES_SETUP}`;
        this._apiService.apiPostMethod(URL, this._listCTSAdminWebSitesTEMP).subscribe({
            next: (result: ResponseData) => {
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    UpdateItemCtsAdminWebsiteSetup(event: any, idSite: number, idSubject: number) {
        this._WebSetupItemUpdate = { idWebsiteSetup: 0, idSite: this.idCtsAdminSiteSelected, idSubject: idSubject, isActive: event.checked, position: 0 }
        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ITEM_CTS_ADMIN_WEBSITE_SETUP}`;

        this._apiService.apiPostMethod(URL, this._WebSetupItemUpdate).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.SuccessProcess();
                    this.onChangeCTSAdmin("", this.idCtsAdminSiteSelected)
                } else {
                    this.ErrorProcess()
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }


    UpdateItemAgentWebsiteSetup(event: any, idSite: number, idSubject: number) {
        this._WebSetupItemUpdate = { idWebsiteSetup: 0, idSite: this.idAgentSiteSelected, idSubject: idSubject, isActive: event.checked, position: 0 }

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ITEM_WEBSITE_SETUP}`;

        this._apiService.apiPostMethod(URL, this._WebSetupItemUpdate).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.SuccessProcess();
                    this.onChangeAgentWebsite("", this.idAgentSiteSelected)
                } else {
                    this.ErrorProcess()
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    UpdateAllItemAgentWebsiteSetup(event: any) {
        this._listAgentWebSitesTEMP.forEach(element => {
            element.isActive = event.checked;
            element.idSiteTag = this.idAgentSiteSelected;
        });

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ALL_ITEM_AGENT_WEBSITE_SETUP}`;

        this._apiService.apiPostMethod(URL, this._WebSetupItemUpdate).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.SuccessProcess();
                } else {
                    this.ErrorProcess()
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });

    }
    // Termina el editar para cada uno de los sitios

    // Editar todoos los sitios de una vez

    SelectAllSitesConfiguration(event: any) {
        this._listAllSitesToUpdate.forEach(element => {
            element.isActive = event.checked;
        });
    }

    SelectItemConfigurationForAllSites(event: any, idSubject: number) {
        this._listAllSitesToUpdate.forEach(element => {
            if (element.idSubject == idSubject) {
                element.isActive = event.checked;
            }
        });
    }

    SaveChangeToAllSites() { // El id verifica si se editan todos los sites o agent site FALTA EN EL NUEVO API.. // no esta en swagger

        Swal.fire({
            text: "This action will update all sites, are you sure?",
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {

                const URLPARAM = (this._AllSitesToUpdate == 1) ? Methods.POST_SAVE_UPDATE_FOR_ALL_SITES : Methods.POST_SAVE_UPDATE_FOR_ALL_AGENT_SITES;
                const URL = `${environment.WebApiUrl + URLPARAM}`;

                this._apiService.apiPostMethod(URL, this._listAllSitesToUpdate).subscribe({
                    next: (result: any) => {
                        if (result == "1") {
                            this.SuccessProcess();
                        } else {
                            this.ErrorProcess()
                        }
                    },
                    error: (err: any) => {
                        console.log(err);
                        this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                    }
                });

            }
        })

    }

    onChangeUpdateAllSites(event: any) {
        this.type = this.AllSiteList.find(e => e.value == event.value)?.label;

        this._AllSitesToUpdate = event.value;
    }

    SelectAllAgentSitesConfiguration(event: boolean) {
        this._listAllSitesToUpdate.forEach(element => {
            element.isActive = event;
        });
    }

    SelectItemConfigurationForAllAgentSites(event: boolean, idSubject: number) {
        this._listAllSitesToUpdate.forEach(element => {
            if (element.idSubject == idSubject) {
                element.isActive = event;
            }
        });
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this._listAgentWebSitesTEMP, event.previousIndex, event.currentIndex);
    }

    dropWebsite(event: CdkDragDrop<string[]>) {
        moveItemInArray(this._listWebSitesTEMP, event.previousIndex, event.currentIndex);
    }

    dropWebsiteCTSAdmin(event: CdkDragDrop<string[]>) {
        moveItemInArray(this._listCTSAdminWebSitesTEMP, event.previousIndex, event.currentIndex);
    }

    dropFields(event: CdkDragDrop<string[]>) {
        moveItemInArray(this._listSubjectFields, event.previousIndex, event.currentIndex);
    }

    dropFieldAllSite(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.listFields, event.previousIndex, event.currentIndex);
    }

    dropWebsiteEpos(event: CdkDragDrop<string[]>) {
        moveItemInArray(this._listEposWebSitesTEMP, event.previousIndex, event.currentIndex);
    }

    dropWebsiteEposAdmin(event: CdkDragDrop<string[]>) {
        moveItemInArray(this._listEposAdminWebSitesTEMP, event.previousIndex, event.currentIndex);
    }

    changeViewAgents() {
        this.viewSortAgents = !this.viewSortAgents;
        if (this.viewSortAgents == false) {
            const URL = `${environment.WebApiUrl + PutMethods.UPDATE_SORT_AGENT_WEBSITE}`;
            this._apiService.apiPostMethod(URL, this._listAgentWebSitesTEMP).subscribe({
                next: (result: ResponseData) => {
                    if (result.success) {
                        this.SuccessProcess();
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }
    }

    changeViewSites() {
        this.viewSortPlayers = !this.viewSortPlayers;

        if (this.viewSortPlayers == false) {
            const URL = `${environment.WebApiUrl + PutMethods.UPDATE_SORT_WEBSITE_SETUP}`;
            this._apiService.apiPostMethod(URL, this._listWebSitesTEMP).subscribe({
                next: (result: ResponseData) => {
                    if (result.success) {
                        this.SuccessProcess();
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }

    }

    changeViewCTSSites() {
        this.viewSortPlayers = !this.viewSortPlayers;

        if (this.viewSortPlayers == false) {
            const URL = `${environment.WebApiUrl + Methods.UPDATE_SORT_WEBSITE_SETUP}`;

            this._apiService.apiPostMethod(URL, this._listWebSitesTEMP).subscribe({
                next: (result: ResponseData) => {
                    if (result.success) {
                        this.SuccessProcess();
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }
    }

    //Manipular fields por website

    CloseTableFieldsAnsSaveOrder() {
        this.websiteTabModal.hide();
        if (this.selectPlayerAgent == true) { // true == player
            const URL = `${environment.WebApiUrl + PutMethods.UPDATE_SORT_WEBSITE_FIELD_SETUP}`;
            this._apiService.apiPostMethod(URL, this._listSubjectFields).subscribe({
                next: (result: ResponseData) => {
                    if (result.success) {
                        this.SuccessProcess();
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }

        if (this.selectPlayerAgent == false) { // false == agent
            const URL = `${environment.WebApiUrl + PutMethods.UPDATE_SORT_AGENT_WEBSITE_FIELD_SETUP}`;
            this._apiService.apiPostMethod(URL, this._listSubjectFields).subscribe({
                next: (result: ResponseData) => {
                    if (result.success) {
                        this.SuccessProcess();
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }
    }

    GetFieldsBySite(item: any, selector: boolean) {
        this.selectPlayerAgent = selector;
        this.SubSelected = item.subjectName;

        const URL_PARAM = (selector) ? GetMethods.GET_SUBJECT_FIELD_BY_SITE : GetMethods.GET_SUBJECT_FIELD_BY_AGENT_SITE;
        const URL = `${environment.WebApiUrl + URL_PARAM + this.idSiteSelected + '&IdSubject=' + item.idSubject}`;
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    if (Array.isArray(result.data)) {
                        this._listSubjectFields = result.data;
                        this.websiteTabModal.show();
                    }else{
                        this._listSubjectFields = [];
                        this.websiteTabModal.show();
                    }
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }


    GetFieldsByCTSAdmin(item: any) { //IdSubject
        this.SubSelected = item.subjectName;
        const URL = `${environment.WebApiUrl + GetMethods.GET_SUBJECT_FIELD_BY_CTS_ADMIN_SITE + this.idCtsAdminSiteSelected + '&IdSubject=' + item.idSubject}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {

                    this.ctsAdminModal.show();
                    this._listSubjectFields = result.data;
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });

    }

    CloseCTSAdminFieldsAnsSaveOrder() {
        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_SORT_CTS_ADMIN_WEBSITE_FIELD_SETUP}`;
        this._apiService.apiPostMethod(URL, this._listSubjectFields).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.ctsAdminModal.hide();
                    this.SuccessProcess();
                } else {
                    this.ErrorProcess();
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });

    }

    UpdateItemWebsiteFieldSetup(event: any, config: WebsiteFieldsSetup) {
        config.isActive = event.checked;

        const URL_PARAM = (this.selectPlayerAgent) ? PutMethods.UPDATE_ITEM_WEBSITE_FIELD_SETUP : PutMethods.UPDATE_ITEM_AGENT_WEBSITE_FIELD_SETUP;
        const URL = `${environment.WebApiUrl + URL_PARAM}`;

        this._apiService.apiPostMethod(URL, config).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    if (this.selectPlayerAgent) {
                        this.onChange("", this.idSiteSelected)
                    } else {
                        this.onChangeAgentWebsite("", this.idAgentSiteSelected);
                    }
                    this.SuccessProcess();
                } else {
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    UpdateAllItemWebsiteFieldSetup(event: any) {
        if (this.selectPlayerAgent == true) {  //true = player
            this._listSubjectFields.forEach(element => {
                element.isActive = event.checked;
                element.idSite = this.idSiteSelected;
            });

            const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ALL_ITEM_WEBSITE_FIELD_SETUP}`;

            this._apiService.apiPostMethod(URL, this._listSubjectFields).subscribe({
                next: (result: ResponseData) => {
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });

        }

        if (this.selectPlayerAgent == false) {  //false = agent
            this._listSubjectFields.forEach(element => {
                element.isActive = event;
                element.idSite = this.idAgentSiteSelected;
            });
            const URL = `${environment.WebApiUrl + Methods.UPDATE_ALL_ITEM_AGENT_WEBSITE_FIELD_SETUP}`;

            this._apiService.apiPostMethod(URL, this._listSubjectFields).subscribe({
                next: (result: any) => {
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });

        }

    }

    SelectAllItemFieldsForAllSites(event: any) {
        this.listFields.forEach(element => {
            element.isActive = event.checked;
        });

    }

    SelectItemFieldsForAllSites(event: any, idSubjectField: number) {
        this.listFields.forEach(element => {
            if (element.idSubjectField == idSubjectField) {
                element.isActive = event.checked;
            }
        });
    }

    closeWindow() {
        // this.getFieldsAllSites = false;
        this.allSitesModal.hide();
    }

    SaveChangeFieldsToAllSites() { // El id verifica si se editan todos los sites o agent site

        // this.getFieldsAllSites = false;

        this._listSubjectFields = [];
        this.listFields.forEach(element => {
            let newItem: WebsiteFieldsSetup = {
                idWebsiteFieldsSetup: 0,
                idSite: this._userInfoCTS.idUser, // enviar en este espacio el idUser para verificar si tiene acceso a todos los sitios
                idSubjectField: element.idSubjectField,
                idSubject: element.idSubject,
                isActive: element.isActive,
                position: 500,
                name: "",
                formType: element.formType,
                spanishTranslation: element.spanishTranslation,
                portugueseTranslation: element.portugueseTranslation
            };
            this._listSubjectFields.push(newItem);
        });

        if (this._AllSitesToUpdate == 1) {  // Edita los sites

            //FALTA FAVOR DE REVISAR // no esta en swagger
            const URL = `${environment.WebApiUrl + Methods.POST_SAVE_UPDATE_FIELDS_FOR_ALL_SITES}`;

            this._apiService.apiPostMethod(URL, this._listSubjectFields).subscribe({
                next: (result: any) => {
                    if (result == "1") {
                        this.SuccessProcess();
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }

        if (this._AllSitesToUpdate == 2) {  // Edita los agent sites
            //FALTA FAVOR DE REVISAR // no esta en swagger
            const URL = `${environment.WebApiUrl + Methods.POST_SAVE_UPDATE_FIELDS_FOR_ALL_AGENT_SITES}`;

            this._apiService.apiPostMethod(URL, this._listSubjectFields).subscribe({
                next: (result: any) => {
                    if (result == "1") {
                        this.SuccessProcess();
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }

    }


    EditAllFieldsAllSites(item: any, selector: boolean) { //IdSubject
        this.SubSelected = item.subjectName;
        //ConfigWebsiteFields
        // this.getFieldsAllSites = true;

        const URL = `${environment.WebApiUrl + GetMethods.GET_SUBJECT_FIELD_BY_ID + item.idSubject}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.allSitesModal.show();
                    this.listFields = result.data;
                    this.listFields.forEach(element => {
                        element.isActive = false;
                    });
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    UpdateItemCTSAdminWebsiteFieldSetup(event: any, config: WebsiteFieldsSetup) {
        config.isActive = event.checked;
        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ITEM_CTS_ADMIN_WEBSITE_FIELD_SETUP}`;

        this._apiService.apiPostMethod(URL, config).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.onChangeCTSAdmin("", this.idSiteSelected)
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }


    UpdateAllItemCtsAdminFieldSetup(event: any) {
        this._listSubjectFields.forEach(element => {
            element.isActive = event.checked;
            element.idSite = this.idCtsAdminSiteSelected;
        });

        const URL = `${environment.WebApiUrl + Methods.UPDATE_ALL_ITEM_CTS_ADMIN_FIELD_SETUP}`;

        this._apiService.apiPostMethod(URL, this._listSubjectFields).subscribe({
            next: (result: ResponseData) => {

            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });

    }

    OrderCTSAdmin() {
        this.viewSortCTSAdmin = !this.viewSortCTSAdmin;
        if (this.viewSortCTSAdmin == false) {
            const URL = `${environment.WebApiUrl + PutMethods.UPDATE_SORT_CTS_ADMIN_WEBSITE_SETUP}`;
            this._apiService.apiPostMethod(URL, this._listCTSAdminWebSitesTEMP).subscribe({
                next: (result: ResponseData) => {
                    if (result.success) {
                        this.SuccessProcess();
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }

    }
    // PROFILES

    GetAllWebsitesProfiles() {
        const URL = `${environment.WebApiUrl + GetMethods.GET_ALL_WEBSITES_PROFILES}`;
        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: any) => {
                this.listAllWebsiteProfiles = result.data;
            },
            error: (err: any) => {
                console.log(err);
                this.onClick();
                //this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    ConfigProfile(websiteType: number) {
        this.viewConfigProfile = !this.viewConfigProfile;
        this.UseProfile.siteList = [];
        this.UseProfile.idWebsiteType = websiteType;
        switch (websiteType) {
            case 1: {
                this._model.profile = "Website";
                break;
            }
            case 2: {
                this._model.profile = "Agent Site";
                break;
            }
            case 3: {
                this._model.profile = "CTS Admin";
                break;
            }
            case 4: {
                this._model.profile = "Epos";
                break;
            }
            case 5: {
                this._model.profile = "Epos Admin";
                break;
            }
            default: {
                this._model.profile = "";
                this._model.site = null;
                break;
            }
        }
    }


    GetIdSiteToProfile() {
        if (this._model.site != null) {
            this.UseProfile.siteList.push(this._model.site)
        }
    }

    DeleteSiteToProfile(site: any) {
        if (site != null) {
            const index: number = this.UseProfile.siteList.indexOf(site);
            this.UseProfile.siteList.splice(index, 1);
        }
    }

    SelectProfile() { }

    SaveProfileConfiguration() { // NO EXISTE FAVOR DE REVISAR // no esta en swagger
        if (this.UseProfile != null) {
            this.load = false;
            const URL = `${environment.WebApiUrl + PostMethods.POST_INSERT_WEBSITE_CONFIG_BY_PROFILE}`;
            this._apiService.apiPostMethod(URL, this.UseProfile).subscribe({
                next: (result: any) => {
                    if (result.success) {
                        this.SuccessProcess();
                        this.ConfigProfile(0);
                        this.SelectSites();
                        this.load = true;
                        this.UseProfile.idSiteProfile = 0;
                    }
                    else {
                        this.ErrorProcess();
                        this.ConfigProfile(0);
                        this.load = true;
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }
    }

    CreateListEpos(description: any, idSite: number) {
        this._listEposWebSitesTEMP = [];

        if (this._listSubjects.length > 0) {
            this._checkedEpos = true;

            this._listSubjects.forEach(element => {

                var temp = this._listEposWebSitesSetup.find(x => x.idSubject == element.idSubject);

                if (temp != undefined && temp != null) {
                    if (temp.isActive == false) {
                        this._checkedEpos = false;
                    }
                    this._NewSTEMP = {
                        idSiteTag: temp.idSite, description: description,
                        idSubject: element.idSubject, subjectName: element.subjectName, isActive: temp.isActive,
                        position: temp.position, descriptionSubject: element.description
                    }
                }
                else {
                    this._checkedEpos = false;
                    this._NewSTEMP = {
                        idSiteTag: 1, description: description, idSubject: element.idSubject,
                        subjectName: element.subjectName, isActive: false, position: 500, descriptionSubject: element.description
                    }
                }
                this._listEposWebSitesTEMP.push(this._NewSTEMP);



            });
            this._listEposWebSitesTEMP.sort((a, b) => a.position - b.position); // Ascending sort

        }
        this.load = true;
    }


    UpdateAllItemEposWebsiteSetup(event: any) { //NO EXISTE FAVOR DE REVISAR // no esta en swagger

        this._listEposWebSitesTEMP.forEach(element => {
            element.isActive = event.checked;
            element.idSiteTag = this.idSiteSelected;
        });

        const URL = `${environment.WebApiUrl + Methods.POST_UPDATE_ALL_ITEM_EPOS_SETUP}`;

        this._apiService.apiPostMethod(URL, this._listEposWebSitesTEMP).subscribe({
            next: (result: any) => {

                if (result == "1") {
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });

    }

    UpdateItemEposWebsiteSetup(event: any, idSite: number, idSubject: number) {

        this._WebSetupItemUpdate = { idWebsiteSetup: 0, idSite: this.idEposSiteSelected, idSubject: idSubject, isActive: event.checked, position: 0 }

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ITEM_EPOS_SETUP}`;

        this._apiService.apiPostMethod(URL, this._WebSetupItemUpdate).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.onChangeEpos("", this.idEposSiteSelected)
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    GetFieldsByEpos(item: any) { //IdSubject
        //ConfigWebsiteFields
        // this._model.getFieldsEpos = true;
        this.SubSelected = item.subjectName;
        const URL = `${environment.WebApiUrl + GetMethods.GET_SUBJECT_FIELD_BY_EPOS_SITE + this.idEposSiteSelected + '&IdSubject=' + item.idSubject}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this._listSubjectFields = result.data;
                    this.eposModal.show();
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    CloseEposFieldsAnsSaveOrder() {
        // this._model.getFieldsEpos = false;

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_SORT_EPOS_FIELD_SETUP}`;

        this._apiService.apiPostMethod(URL, this._listSubjectFields).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.SuccessProcess();
                    this.eposModal.hide();
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    UpdateItemEposWebsiteFieldSetup(event: any, config: WebsiteFieldsSetup) {
        config.isActive = event.checked;
        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ITEM_EPOS_FIELD_SETUP}`;
        this._apiService.apiPostMethod(URL, config).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.onChangeEpos("", this.idSiteSelected)
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }


    UpdateAllItemEposFieldSetup(event) {
        this._listSubjectFields.forEach(element => {
            element.isActive = event.checked;
            element.idSite = this.idEposSiteSelected;
        });

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ALL_ITEM_EPOS_FIELD_SETUP}`;

        this._apiService.apiPostMethod(URL, this._listSubjectFields).subscribe({
            next: (result: ResponseData) => {
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });

    }

    OrderEpos() {
        this.viewSortEpos = !this.viewSortEpos;
        if (this.viewSortEpos == false) {
            const URL = `${environment.WebApiUrl + PutMethods.UPDATE_SORT_EPOS_SETUP}`;
            this._apiService.apiPostMethod(URL, this._listEposWebSitesTEMP).subscribe({
                next: (result: ResponseData) => {
                    if (result.success) {
                        this.SuccessProcess();
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }
    }
    CreateListEposAdmin(description: any, idSite: number) {
        this._listEposAdminWebSitesTEMP = [];

        if (this._listSubjects.length > 0) {
            this._checkedEposAdmin = true;

            this._listSubjects.forEach(element => {

                var temp = this._listEposAdminWebSitesSetup.find(x => x.idSubject == element.idSubject);

                if (temp != undefined && temp != null) {
                    if (temp.isActive == false) {
                        this._checkedEposAdmin = false;
                    }
                    this._NewSTEMP = {
                        idSiteTag: temp.idSite, description: description,
                        idSubject: element.idSubject, subjectName: element.subjectName, isActive: temp.isActive,
                        position: temp.position, descriptionSubject: element.description
                    }
                }
                else {
                    this._checkedEposAdmin = false;
                    this._NewSTEMP = {
                        idSiteTag: 1, description: description, idSubject: element.idSubject,
                        subjectName: element.subjectName, isActive: false, position: 500, descriptionSubject: element.description
                    }
                }
                this._listEposAdminWebSitesTEMP.push(this._NewSTEMP);
            });
            this._listEposAdminWebSitesTEMP.sort((a, b) => a.position - b.position); // Ascending sort

        }
        this.load = true;
    }

    UpdateAllItemEposAdminWebsiteSetup(event: any) {

        this._listEposAdminWebSitesTEMP.forEach(element => {
            element.isActive = event.checked;
            element.idSiteTag = this.idSiteSelected;
        });

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ALL_ITEM_EPOS_ADMIN_SETUP}`;
        this._apiService.apiPostMethod(URL, this._listEposAdminWebSitesTEMP).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.SuccessProcess();
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    UpdateItemEposAdminWebsiteSetup(event: any, idSite: number, idSubject: number) {
        this._WebSetupItemUpdate = { idWebsiteSetup: 0, idSite: this.idEposAdminSiteSelected, idSubject: idSubject, isActive: event.checked, position: 0 }
        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ITEM_EPOS_ADMIN_SETUP}`;

        this._apiService.apiPostMethod(URL, this._WebSetupItemUpdate).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.onChangeEposAdmin("", this.idEposAdminSiteSelected);
                    this.SuccessProcess();
                } else {
                    this.ErrorProcess();
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    GetFieldsByEposAdmin(item: any) { //IdSubject
        //ConfigWebsiteFields
        // this._model.getFieldsEposAdmin = true;
        this.SubSelected = item.subjectName;

        const URL = `${environment.WebApiUrl + Methods.GET_SUBJET_FIELD_BY_EPOS_ADMIN_SITE + this.idEposAdminSiteSelected + '&IdSubject=' + item.idSubject}`;

        this._apiService.apiGetMethod(URL).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.eposAdminModal.show();
                    this._listSubjectFields = result.data;
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });

    }

    CloseEposAdminFieldsAnsSaveOrder() {
        // this._model.getFieldsEposAdmin = false;

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_SORT_EPOS_ADMIN_FIELD_SETUP}`;

        this._apiService.apiPostMethod(URL, this._listSubjectFields).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.eposAdminModal.hide();
                    this.SuccessProcess();
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    UpdateItemEposAdminWebsiteFieldSetup(event: any, config: WebsiteFieldsSetup) {
        config.isActive = event.checked;

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ITEM_EPOS_ADMIN_FIELD_SETUP}`;
        this._apiService.apiPostMethod(URL, config).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.onChangeEposAdmin("", this.idSiteSelected)
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });
    }

    UpdateAllItemEposAdminFieldSetup(event: any) {
        this._listSubjectFields.forEach(element => {
            element.isActive = event.checked;
            element.idSite = this.idEposAdminSiteSelected;
        });

        const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ALL_ITEM_EPOS_ADMIN_FIELD_SETUP}`;

        this._apiService.apiPostMethod(URL, this._listSubjectFields).subscribe({
            next: (result: ResponseData) => {
                if (result.success) {
                    this.SuccessProcess();
                } else {
                    this.ErrorProcess();
                }
            },
            error: (err: any) => {
                console.log(err);
                this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
            }
        });

    }

    OrderEposAdmin() {
        this.viewSortEposAdmin = !this.viewSortEposAdmin;
        if (this.viewSortEposAdmin == false) {
            const URL = `${environment.WebApiUrl + PutMethods.UPDATE_SORT_EPOS_ADMIN_SETUP}`;

            this._apiService.apiPostMethod(URL, this._listEposAdminWebSitesTEMP).subscribe({
                next: (result: ResponseData) => {
                    if (result.success) {
                        this.SuccessProcess();
                    }
                },
                error: (err: any) => {
                    console.log(err);
                    this._notificationService.warning('GENERICS.UPDATED_ERROR', 'bg-dark', 'animate__backInUp', 6000);
                }
            });
        }

    }

    TranslateText(text: string) {
        return this._translateService.instant(text);
    }


    onClick() {
        this.clicks += 1;
        if (this.clicks >= 5) {
            this.ErrorProcess();
        }
    };

    hardRefresh() {
        location.reload();
    }

    InitModals() {
        this.websiteTabModal = new bootstrap.Modal(document.getElementById('websiteTabModal'), {
            keyboard: false
        })

        this.ctsAdminModal = new bootstrap.Modal(document.getElementById('ctsAdminModal'), {
            keyboard: false
        })

        this.eposModal = new bootstrap.Modal(document.getElementById('eposModal'), {
            keyboard: false
        })

        this.eposAdminModal = new bootstrap.Modal(document.getElementById('eposAdminModal'), {
            keyboard: false
        })

        this.allSitesModal = new bootstrap.Modal(document.getElementById('allSitesModal'), {
            keyboard: false
        })
    }

}
