<p-card *ngIf="!showMessageInfo" class="card" header="{{'USER.TICKER.TITLE' | translate}}" subheader="" [style]="{ width: 'auto' }">
    <div class="text-center w-100">
        <p-table #dt
            [value]="payloadData"
            [columns]="cols"
            [scrollable]="true"
            scrollHeight="700px"
            dataKey="id"
            styleClass="p-datatable-striped custom-table-td-fix"
            [rowHover]="true"
            [rows]="20"
            [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[20,40,60]"
            [loading]="loading"
            [paginator]="true"
            responsiveLayout="stack"
            [tableStyle]="{'min-width': '80rem'}"
            currentPageReportTemplate="{{'Showing {first} to {last} of {totalRecords} entries' | translate}}"
            [filterDelay]="0"
            [globalFilterFields]="['ticketInfo.ticketNumber','ticketInfo.assignedUser','ticketInfo.idPlayer','representative.name','status', 'site', 'ticketInfo.dateTransaction','relevance', 'subject', 'ticketInfo.department']">

            <ng-template pTemplate="caption">
                <div class="row g-3">
                    <div class="col-md-9">
                        <span class="p-input-icon-left w-100">
                            <i class="feather icon-search"></i>
                            <input class="w-100" pInputText type="text" (input)="dt.filterGlobal($any($event.target) .value, 'contains')"
                                placeholder="{{'Search' | translate}}" />
                        </span>
                    </div>
                    <div class="col-md-3 d-flex">
                        <button (click)="getGlobalInfo(0)" class="btn btn-primary d-block w-100 p-2">
                            <i class="feather icon-refresh-cw"></i>
                            {{'Refresh' | translate}}
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>

                <tr>
                    <th *ngFor="let col of columns" class="text-uppercase">
                        {{ col.header | translate }}
                    </th>
                </tr>

            </ng-template>

            <ng-template pTemplate="body" let-payloadData>
                <tr class="p-selectable-row">
                    <td>
                        <span class="p-column-title">Ticket</span>
                        <span [ngClass]="getStatusType(payloadData.status)"> {{payloadData.ticketInfo.ticketNumber}} </span>
                    </td>
                    <td>
                        <span class="p-column-title">Account</span>
                        <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.ticketInfo.idPlayer | translate}}</span>
                    </td>
                    <td>
                        <span class="p-column-title">Subject</span>
                        <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.subject | translate}}</span>
                    </td>
                    <td>
                        <span class="p-column-title">Website</span>
                        <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.site | translate}}</span>
                    </td>

                    <td>
                        <span class="p-column-title">Date</span>
                        <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.ticketInfo.dateTransaction | date:'short' }}</span>
                    </td>

                    <td>
                        <span class="p-column-title">Priority</span>
                        <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.relevance | translate}}</span>
                    </td>

                    <td>
                        <span class="p-column-title">Assigned To</span>
                        <span *ngIf="payloadData.ticketInfo.department" [ngClass]="getStatusType(payloadData.status)"> {{payloadData.ticketInfo.department[0].departmentName}} </span>
                    </td>

                    <td>
                        <span class="p-column-title">Status</span>
                        <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.status | translate}}</span>
                    </td>

                    <td>
                        <span class="p-column-title">Assigned</span>
                        <span [ngClass]="getStatusType(payloadData.status)">{{payloadData.timer}}</span>
                    </td>

                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td style="padding: 200px; text-align: center;" colspan="12">
                        <span *ngIf="!showHardReloadBtn"> {{ 'GENERICS.NO_TICKET_FOUND' | translate }} </span>
                        <span *ngIf="showHardReloadBtn"> {{'GENERICS.UPDATED_ERROR' | translate}} <a class="btn btn-danger" (click)="hardRefresh()"> <i class="feather icon-refresh-cw"></i> {{ 'Refresh' | translate }}</a> </span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-card>

<div *ngIf="showMessageInfo" >
    <ng-container>
        <div>
            <div *ngIf="showMessageInfo" class="stickty-button">
                <p-button (onClick)="onStepChange(false);" pTooltip="Back to Tickets"  styleClass="p-button-primary mx-2 ms-2" icon="feather icon-arrow-left" label="Back"></p-button>
            </div>
            <div class="custom-top-margin">
                <app-ticket-message-info
                    [TicketInfo]="ticketSelected"
                    [IdSiteType]="idSiteType"
                    [subjectList]="subjectList"
                    [relevanceList]="relevanceList"
                    [statusList]="statusList"
                    [payloadData]="payloadData"
                    [departmentList]="departmentList"
                    [formType]="1"
                    [shortCutByDepartment]="1">
                </app-ticket-message-info>
            </div>

        </div>
    </ng-container>
</div>
