<form [formGroup]="formTicket" (ngSubmit)="submitForm()" class="form-group row g-3 px-3 align-items-start">

    <ng-container *ngIf="showForm.showUsers">
        <dt for="inputEmail3" class="col-sm-3">{{'Users' | translate}}</dt>

        <div class="col-sm-9" >
            <p-dropdown
                styleClass="d-flex w-100"
                [options]="allUserList"
                [autoDisplayFirst]="false"
                optionLabel="name"
                [filter]="true" filterBy="name" [showClear]="true"
                formControlName="userSelected"
                [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.userSelected.errors }"
                optionValue="idUser">
            </p-dropdown>
            <div *ngIf="submitted && f.userSelected.errors" class="invalid-feedback">
                <div *ngIf="f.userSelected.errors.required">{{'Users' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showForm.showInputTextName">
        <dt for="inputTextName" class="col-sm-3">{{ 'GlobalForm.Name' | translate }}</dt>
        <div class="col-sm-9">
            <input type="text" pInputText class="w-100" formControlName="inputTextName" [ngClass]="{ 'is-invalid invalid': submitted && f.inputTextName.errors }" />
            <div *ngIf="submitted && f.inputTextName.errors" class="invalid-feedback">
                <div *ngIf="f.inputTextName.errors.required">{{ 'GlobalForm.Name' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showForm.showDescription">
        <dt for="description" class="col-sm-3">{{ 'GlobalForm.Description' | translate }}</dt>
        <div class="col-sm-9">
            <input type="text" pInputText class="w-100" formControlName="description" [ngClass]="{ 'is-invalid invalid': submitted && f.description.errors }" />
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                <div *ngIf="f.description.errors.required">{{ 'GlobalForm.Description' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="showForm.showAccount">
         <dt for="inputEmail3" class="col-sm-3">{{'GlobalForm.Account' | translate}}</dt>
        <div class="col-sm-9" >
            <input type="text" pInputText class="w-100" formControlName="account" [ngClass]="{ 'is-invalid invalid': submitted && f.account.errors }" />
            <div *ngIf="submitted && f.account.errors" class="invalid-feedback">
                <div *ngIf="f.account.errors.required">{{'GlobalForm.Account' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
                <div *ngIf="f.account.errors.maxlength">You have reached the maximum characters allowed (30).</div>
            </div>
        </div>
    </ng-container>


    <ng-container *ngIf="showForm.showAgentPlayer">
        <dt for="inputEmail3" class="col-sm-3">{{'GlobalForm.AgentPlayer' | translate}}</dt>
        <div class="col-sm-9" >
            <p-dropdown
                styleClass="d-flex w-100"
                [options]="clientTypeList"
                [autoDisplayFirst]="false"
                optionLabel="label"
                formControlName="agentPlayer"
                [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.agentPlayer.errors }"
                optionValue="value">
                <ng-template let-option pTemplate="item">
                    <span [class]="'customer-badge status-' + option.value">{{ option.label | translate }}</span>
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem">
                    {{selectedItem.label | translate}}
                </ng-template>
            </p-dropdown>
            <div *ngIf="submitted && f.agentPlayer.errors" class="invalid-feedback">
                <div *ngIf="f.agentPlayer.errors.required">{{'GlobalForm.AgentPlayer' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showForm.showSubject">
        <dt for="inputEmail3" class="col-sm-3">{{'GlobalForm.Subject' | translate}}</dt>
        <div class="col-sm-9" >
            <p-dropdown
                styleClass="d-flex w-100"
                [options]="subjectList"
                optionLabel="label"
                [autoDisplayFirst]="false"
                formControlName="subject"
                [filter]="true" filterBy="label" [showClear]="true"
                (onChange)="onChangeSubjectItemSelected($event)"
                [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.subject.errors }"
                optionValue="idSubject">
                <ng-template let-option pTemplate="item">
                    <span [class]="'customer-badge status-' + option.value">{{option.label | translate}} <span *ngIf="option.description != null">[{{option.description}}]</span> </span>
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem">
                    {{selectedItem.label | translate}}
                </ng-template>
            </p-dropdown>
            <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                <div *ngIf="f.subject.errors.required">{{'GlobalForm.Subject' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showForm.showSubjetField">
        <dt for="inputEmail3" class="col-sm-3">{{'GlobalForm.SubjectField' | translate}}</dt>
        <div class="col-sm-9" >
            <p-dropdown
                styleClass="d-flex w-100"
                [options]="SubjectFieldsData"
                optionLabel="name"
                [autoDisplayFirst]="false"
                formControlName="subjectField"
                [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.subjectField.errors }"
                optionValue="idSubjectField">
                <ng-template let-option pTemplate="item">
                    <span [class]="'customer-badge status-' + option.value">{{option.name | translate}} </span>
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem">
                    {{selectedItem.name | translate}}
                </ng-template>
            </p-dropdown>
            <div *ngIf="submitted && f.subjectField.errors" class="invalid-feedback">
                <div *ngIf="f.subjectField.errors.required">{{'GlobalForm.SubjectField' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showForm.showWebsite">
        <dt for="inputEmail3" class="col-sm-3">{{'GlobalForm.Website' | translate}}</dt>
        <div class="col-sm-9" >
            <p-dropdown
                styleClass="d-flex w-100"
                [options]="allSiteTags"
                optionLabel="label"
                [autoDisplayFirst]="false"
                [filter]="true" filterBy="label" [showClear]="true"
                formControlName="website"
                [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.website.errors }"
                optionValue="idSiteTag">
                <ng-template let-option pTemplate="item">
                    <span [class]="'customer-badge status-' + option.idSiteTag">{{option.label}}</span>
                </ng-template>
            </p-dropdown>
            <div *ngIf="submitted && f.website.errors" class="invalid-feedback">
                <div *ngIf="f.website.errors.required">{{'GlobalForm.Website' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showForm.showRelevance">
        <dt for="inputEmail3" class="col-sm-3">{{'GlobalForm.Relevance' | translate}}</dt>
        <div class="col-sm-9" >
            <p-dropdown
                styleClass="d-flex w-100"
                [options]="relevanceList"
                optionLabel="label"
                [autoDisplayFirst]="false"
                formControlName="relevance"
                [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.relevance.errors }"
                optionValue="idRelevance">
                <ng-template let-option pTemplate="item">
                    <span [class]="'customer-badge status-' + option.value">{{ option.label | translate }}</span>
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem">
                    {{selectedItem.label | translate}}
                </ng-template>
            </p-dropdown>
            <div *ngIf="submitted && f.relevance.errors" class="invalid-feedback">
                <div *ngIf="f.relevance.errors.required">{{'GlobalForm.Relevance' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showForm.showDepartment">
        <dt for="inputEmail3" class="col-sm-3">{{'GlobalForm.Department' | translate}}</dt>
        <div class="col-sm-9" >
            <p-dropdown
                styleClass="d-flex w-100"
                [options]="departmentList"
                optionLabel="name"
                [filter]="true" filterBy="name" [showClear]="true"
                [autoDisplayFirst]="false"
                formControlName="department"
                [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.department.errors }"
                optionValue="idDepartment">
                <ng-template let-option pTemplate="item">
                    <span [class]="'customer-badge status-' + option.idDepartment">{{option.name | translate}}</span>
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem">
                    {{selectedItem.name | translate}}
                </ng-template>
            </p-dropdown>
            <div *ngIf="submitted && f.department.errors" class="invalid-feedback">
                <div *ngIf="f.department.errors.required">{{'GlobalForm.Department' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showForm.showEmailSubject">
        <dt for="emailSubject" class="col-sm-3">{{'GlobalForm.EmailSubject' | translate}}</dt>
        <div class="col-sm-9" >
            <input type="text" pInputText class="w-100" formControlName="emailSubject" [ngClass]="{ 'is-invalid invalid': submitted && f.emailSubject.errors }" />
            <div *ngIf="submitted && f.emailSubject.errors" class="invalid-feedback">
                <div *ngIf="f.emailSubject.errors.required">{{'GlobalForm.EmailSubject' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showForm.showTicket">
        <dt for="ticketLink" class="col-sm-3">{{'GlobalForm.Ticket' | translate}}</dt>
        <div class="col-sm-9" >
            <input type="text" pInputText class="w-100" formControlName="ticketLink" [ngClass]="{ 'is-invalid invalid': submitted && f.ticketLink.errors }" />
            <div *ngIf="submitted && f.ticketLink.errors" class="invalid-feedback">
                <div *ngIf="f.ticketLink.errors.required">{{'GlobalForm.Ticket' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showForm.showStatus">
        <dt for="inputEmail3" class="col-sm-3">{{'GlobalForm.Status' | translate}}</dt>
        <div class="col-sm-9" >
            <!-- <input type="text" pInputText class="w-100" id="inputEmail3" disabled="true" placeholder="{{'Open' | translate}}"/> -->
            <p-dropdown
                styleClass="d-flex w-100 status-ticket"
                [options]="statusList"
                optionLabel="description"
                [autoDisplayFirst]="false"
                formControlName="status"
                [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.status.errors }"
                optionValue="idStatusList">
                <ng-template let-option pTemplate="item">
                    <span [class]="'customer-badge status- text-in-' + option.description.toLowerCase()">{{option.description | translate}}</span>
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem" class="text-danger">
                    <span [class]="'customer-badge status- text-in-' + selectedItem.description.toLowerCase()">{{selectedItem.description | translate}}</span>
                </ng-template>
            </p-dropdown>
            <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                <div *ngIf="f.status.errors.required">{{'GlobalForm.Status' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showForm.showReplyToEmail">
        <dt for="inputEmail3" class="col-sm-3">{{'GlobalForm.ReplyToEmail' | translate}}</dt>
        <div class="col-sm-9" >
            <input type="text" pInputText class="w-100" id="inputEmail3" formControlName="replyToEmail" />
        </div>
    </ng-container>

    <ng-container *ngIf="showForm.showShortCuts">
        <dt for="inputEmail3" class="col-sm-3">{{'GlobalForm.Shortcuts' | translate}}</dt>
        <div class="col-sm-9" >
            <p-dropdown
                styleClass="d-flex w-100"
                [options]="shortCutByDepartment"
                (onChange)="onChangeShurtcutMesage($event.value)"
                optionLabel="name"
                [filter]="true" filterBy="name" [showClear]="true"
                [autoDisplayFirst]="false"
                formControlName="shortcuts"
                [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.shortcuts.errors }"
                optionValue="message">
                <ng-template let-option pTemplate="item">
                    <span [class]="'customer-badge status-' + option.idDepartment">{{option.name | translate}}</span>
                </ng-template>
                <ng-template let-selectedItem pTemplate="selectedItem">
                    {{selectedItem.name | translate}}
                </ng-template>
            </p-dropdown>
            <div *ngIf="submitted && f.shortcuts.errors" class="invalid-feedback">
                <div *ngIf="f.shortcuts.errors.required">{{'GlobalForm.Shortcuts' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="showForm.showMessage">
        <dt for="inputEmail3" class="col-sm-3">{{'GlobalForm.Message' | translate}}</dt>
        <div class="col-sm-9" >
            <div class="editor" [ngClass]="{ 'is-invalid dropdown-invalid': submitted && f.message.errors }">
				<ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
				<ngx-editor multiple [editor]="editor" formControlName="message" placeholder="{{'Type here...' | translate}}" ng2FileSelect [uploader]="uploader" (paste)="onPaste()" (ngModelChange)="onChange($event)"></ngx-editor>
			</div>
            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                <div *ngIf="f.message.errors.required">{{'GlobalForm.Message' | translate}} {{'GlobalForm.IsRequired' | translate}}</div>
            </div>
        </div>
    </ng-container>

   <ng-container *ngIf="showForm.showAttachment">
        <dt for="inputEmail3" class="col-sm-3">{{'GlobalForm.UploadAttachment' | translate}}</dt>
        <div class="col-sm-9" >
            <input type="file" (change)="onChangeInput($event)" ng2FileSelect [uploader]="uploader" class="w-100" placeholder="{{'Form.ChooseFile' | translate}}" id="inputEmail3" multiple />

            <div *ngIf="uploader?.queue?.length > 0" class="form-group row">
                <div class="col-12">
                <table class="table mt-3">
                    <thead class="table-info">
                    <tr>
                        <th width="50%">{{'Name' | translate}}</th>

                        <th>{{'Progress' | translate}}</th>

                        <th class="text-center">{{'Result' | translate}}</th>

                        <th class="text-center">{{'Action' | translate}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of uploader.queue">
                        <td><strong>{{ item?.file?.name }}</strong></td>
                        <td *ngIf="uploader.options.isHTML5">
                        <div class="progress mt-2" style="margin-bottom: 0;">
                            <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }" style="font-size: 10px;">{{item.progress}}%</div>
                        </div>
                        </td>
                        <td class="text-center">
                            <div class="btn btn-icon pe-none">
                                <span> <i [ngClass]="{'far fa-thumbs-up': item.isSuccess, 'far fa-thumbs-down': item.isError }" ></i> </span>
                            </div>
                        </td>

                        <td class="text-center">
                        <button (click)="item.remove()" type="button" class="btn btn-icon">
                            <i class="feather icon-trash-2 text-danger"></i>
                        </button>
                        </td>

                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </div>
   </ng-container>

    <div class="w-100 text-center">
        <p-button pTooltip="{{'Save' | translate}}" (onClick)="submitForm()"  styleClass="p-button-primary mx-1" icon="feather icon-save" label="{{'Save' | translate}}"></p-button>
    </div>
</form>
