<app-checkbox-editor
    *ngIf="identity === 1"
    [title]="'Relevance Level'"
    [identity]="identity"
    [payloadData]="payloadData"
    [isErrorActivated]="isErrorActivated"
    [checked]="checked"
    [modalTitleNewText]="'RelevanceLevel.NewRelevance'"
    [modalTitleEditText]="'RelevanceLevel.EditRelevance'"
    [newPostEvent]="1"
    [editPostEvent]="2"
    [deletePostEvent]="3"
    [selectAllCheckbox]="4"
    (refreshData)="refreshData($event)"
>
</app-checkbox-editor>