<p-card *ngIf="!showMessageInfo" class="card" header="{{ title | translate}}" subheader="" [style]="{ width: 'auto', padding: '0' }">
    <p-tabView [scrollable]="Media.IsMobile" (onChange)="getHelpTicketList($event.index)" id="closableTabView">
        <p-tabPanel headerStyleClass="text-uppercase" *ngIf="firstTab != null" header="{{firstTab | translate}}" [selected]="tabSelected == 0">
            <app-table-generic
                [tabSelected]="0"
                [ticketCenterType]="ticketCenterType"
                [payloadData]="payloadData"
                [columsData]="columsData"
                [loading]="loading"
                [allSiteTags]="allSiteTags"
                [subjectList]="subjectList"
                [departmentList]="departmentList"
                [relevanceList]="relevanceList"
                [statusList]="statusList"
                [showHardReloadBtn]="showHardReloadBtn"
                [existsBrandSetup]="_ExistsBrandSetup"
                (refreshData)="refreshData($event)"
                (onTicketSelected)="onTicketSelected($event)">
            </app-table-generic>
        </p-tabPanel>
        <p-tabPanel headerStyleClass="text-uppercase" *ngIf="secondTab != null" header="{{secondTab | translate}}" [closable]="false" [selected]="tabSelected == 1">
            <app-table-generic
                [tabSelected]="1"
                [ticketCenterType]="ticketCenterType"
                [payloadData]="payloadData"
                [columsData]="columsData"
                [loading]="loading"
                [allSiteTags]="allSiteTags"
                [subjectList]="subjectList"
                [departmentList]="departmentList"
                [relevanceList]="relevanceList"
                [statusList]="statusList"
                [showHardReloadBtn]="showHardReloadBtn"
                [existsBrandSetup]="_ExistsBrandSetup"
                (refreshData)="refreshData($event)"
                (onTicketSelected)="onTicketSelected($event)">
            </app-table-generic>
        </p-tabPanel>
        <p-tabPanel headerStyleClass="text-uppercase" *ngIf="thirdTab != null"  header="{{thirdTab | translate}}" [closable]="false" [selected]="tabSelected == 2">
            <app-ticket-form
                [ticketCenterType]="ticketCenterType"
                [formType]="formType"
                [statusList]="statusList"
                [subjectList]="subjectList"
                [allSiteTags]="allSiteTags"
                [relevanceList]="relevanceList"
                [departmentList]="departmentList"
                [allUserList]="allUserList"
                [shortCutByDepartment]="shortCutByDepartment"
            ></app-ticket-form>
        </p-tabPanel>
    </p-tabView>
</p-card>

<div *ngIf="showMessageInfo" >
    <ng-container>
        <div>
            <div *ngIf="showMessageInfo" class="stickty-button">
                <p-button (onClick)="onStepChange(false);" pTooltip="Back to Tickets"  styleClass="p-button-primary mx-2 ms-2" icon="feather icon-arrow-left" label="Back"></p-button>
            </div>
            <div class="custom-top-margin">
                <app-ticket-message-info
                    [TicketInfo]="ticketSelected"
                    [IdSiteType]="ticketCenterType"
                    [subjectList]="subjectList"
                    [relevanceList]="relevanceList"
                    [statusList]="statusList"
                    [payloadData]="payloadData"
                    [departmentList]="departmentList"
                    [formType]="formType"
                    [shortCutByDepartment]="shortCutByDepartment"
                    (onBackBtnEvent)="onBackBtnEvent($event)">
                </app-ticket-message-info>
            </div>

        </div>
    </ng-container>
</div>

<div class="modal fade" id="downloadAs" tabindex="-1" aria-labelledby="downloadAsLabel" aria-hidden="true">
    <div class="modal-dialog modal-md">
      <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="downloadAsLabel">{{ 'Download As' | translate }}</h5>
            <a type="button" class="modal-title px-0" data-bs-dismiss="modal" aria-label="Close"> <i class="fas fa-times"></i> </a>
        </div>
        <div class="modal-body">
            <app-export-as [payloadData]="payloadData" [exportType]="10" [title]="'HEADER_REPORT.TICKET_REPORT'" [exportColumns]="exportColumns" [pdfPayloadData]="infoData"></app-export-as>
        </div>
      </div>
    </div>
</div>
