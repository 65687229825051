import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GetMethods, Methods, PostMethods, PutMethods, responseError } from '@methods/methods';
import { TwoFactor, User } from '@models/auth-model';
import { ResponseData } from '@models/models';
import { ApiService } from '@services/api.service';
import { MediaResponse, MediaService } from '@services/media.service';
import { NotificationService } from '@services/notification.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html'
})
export class TwoFactorComponent implements OnInit {
  private mediaSubscription: Subscription;
  Media: MediaResponse;
  userInfoCTSData: User;
  twoFactorData: TwoFactor = new TwoFactor();
  twoFactorSettings: any = {};
  twoFactorForm: FormGroup;
  submitted: boolean = false;
  messageResult: string = '';
  actualRouter: string | null = '';


  constructor(private _apiService: ApiService, private _formBuilder: FormBuilder, private _notificationService: NotificationService, private _mediaService: MediaService) {
    this.mediaSubscription = this._mediaService.subscribeMedia().subscribe(media => {
      this.Media = media;
    });
  }

  get f() { return this.twoFactorForm.controls; }


  ngOnInit(): void {
    this.actualRouter = this._apiService.getRouterLink();
    this.userInfoCTSData = JSON.parse(localStorage.getItem('UserAccessCTS')!);

    this.twoFactorForm = this._formBuilder.group({
      inputCode: ['' , Validators.required]
    });

    if (this.userInfoCTSData)
      this.GetTwoFactorSettings();
  }

  GetTwoFactorSettings() {
    const URL = `${environment.WebApiUrl + GetMethods.GET_TWO_FACTOR_SETTINGS + '?IdUSer=' +this.userInfoCTSData.idUser + '&Email=' + this.userInfoCTSData.email }`;
    this._apiService.apiGetMethod(URL).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
            this.twoFactorSettings = result.data;
            this.twoFactorData.isTFA = this.twoFactorSettings.isTFA
        }else{
            this.getMessageInfo('USER.TWO_FACTOR.TWO_FACTOR_INVALID');
        }
      },
      error: (err: any) => {
        console.log(err);
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.twoFactorForm.invalid) {
      return;
    }

    const URL = `${environment.WebApiUrl + PutMethods.UPDATE_ENABLE_TWO_FACTOR}`;
    const data = {
      email: this.userInfoCTSData.email,
      idUser: this.userInfoCTSData.idUser,
      inputCode: this.f.inputCode.value,
      isTFA: this.twoFactorData.isTFA
    }
    this._apiService.apiPostMethod(URL, data).subscribe({
      next: (result: ResponseData) => {
        if(result.success){
            if(result.data){
                this.twoFactorForm.reset();
                this.GetTwoFactorSettings();
                this._notificationService.success('GENERICS.ALERT_SUCESS', 'bg-success', 'animate__backInUp', 6000);
                this.submitted = false;
            }else{
                this.getMessageInfo('USER.TWO_FACTOR.TWO_FACTOR_INVALID');
            }
        } else {
          this.getMessageInfo('USER.TWO_FACTOR.TWO_FACTOR_INVALID');
        }
      },
      error: (err: any) => {
        console.log(err);
        const messageTypeErrorText = responseError(err.status);
        this._notificationService.error(messageTypeErrorText, 'bg-dark', 'animate__backInUp', 6000);
      }
    });
  }

  getMessageInfo(message: string) {
    this.messageResult = message;
    setTimeout(() => {
      this.messageResult = '';
    }, 3000);
  }

  ngOnDestroy(): void {
    if (this.mediaSubscription) {
      this.mediaSubscription.unsubscribe();
    }
  }

}
