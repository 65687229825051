<app-shortcut-desk-filter
    [identity]="2"
    [title]="'ShortcutCenter'"
    [tabPanelData]="tabPanelData"
    [columsData]="columsData"
    [payloadData]="payloadData"
    [statusList]="statusList"
    [showHardReloadBtn]="showHardReloadBtn"
    [loading]="loading"
    [userList]="userList"
    [deletePostEvent]="11"
    [formType]="12"
    [departmentList]="departmentList"
    (onCallGetInfo)="onCallGetInfo($event)">
</app-shortcut-desk-filter>