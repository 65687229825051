export const environment = {
  production: true,
  environmentName: 'Prod',
  ws: 'http://localhost:22814/api/',
  SiteName: "Jazz Tickets",
  WebApiUrl: "https://api.ctsadmin.com",
  AdminUrl: "http://localhost:4200/auth/signin",
  DefaultHostName: "jazzsports",
  CSId: 4,
  uploadFilesApi: "https://images.betimages.com/api/uploadFileContent",
  previousDisplay: "https://images.betimages.com/CTS/"
};
