<p-card class="card" header="{{'Change Log' | translate}}" subheader="" [style]="{ width: 'auto' }">
    <div class="text-center w-100">
        <p-table #logs
            [value]="payloadData"
            [columns]="cols"
            [scrollable]="true"
            scrollHeight="700px"
            dataKey="id"
            styleClass="p-datatable-striped"
            [rowHover]="true"
            [rows]="20"
            [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[20,40,60]"
            [loading]="loading"
            [paginator]="true"
            responsiveLayout="stack"
            [tableStyle]="{'min-width': '80rem'}"
            currentPageReportTemplate="{{'Showing {first} to {last} of {totalRecords} entries' | translate}}"
            [filterDelay]="0"
            [globalFilterFields]="['actionName','userName','departmentName','statusName','relevanceName', 'subjectName', 'subjectEdited','user','idTicket','details','userInChargeName']">

            <ng-template pTemplate="caption">
                <div class="row g-3">
                    <div class="col-md-3 text-start">
                        <p-dropdown
                            [readonly]="domainInfo.idSite == 992"
                            styleClass="d-flex w-100"
                            [options]="FilterLogs"
                            optionLabel="label"
                            (onChange)="onFilterChange($event)"
                            optionValue="value">
                            <ng-template let-option pTemplate="item">
                                <span [class]="'customer-badge status-' + option.value">{{option.label | translate}}</span>
                            </ng-template>
                        </p-dropdown>
                    </div>
                    <div class="col-md-3">
                        <span class="p-input-icon-left w-100">
                            <i class="feather icon-search"></i>
                            <input class="w-100" pInputText type="text" (input)="logs.filterGlobal($any($event.target) .value, 'contains')"
                                placeholder="{{ 'Search' | translate }}" />
                        </span>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-2">
                        <p-calendar [showClear]="true" placeholder="{{'Start Date' | translate}}" [(ngModel)]="date1" class="datePickTC" (ngModelChange)="DateFilter()" dateFormat="d/m/yy" styleClass="w-100"></p-calendar>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-2">
                        <p-calendar [showClear]="true" placeholder="{{'End Date' | translate}}" [(ngModel)]="date2" [maxDate]="currentDay" class="datePickTC" (ngModelChange)="DateFilter()" dateFormat="d/m/yy" styleClass="w-100"></p-calendar>
                    </div>
                    <div class="col-md-2 d-flex">
                        <button (click)="getFilterTypeDa(FilterType)" class="btn btn-primary d-block w-100 p-2">
                            <i class="feather icon-refresh-cw"></i>
                            {{'Refresh' | translate}}
                        </button>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" class="text-uppercase">
                        {{ col.header | translate }}
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-payloadData>
                <tr class="p-selectable-row">
                    <td>
                        <span *ngIf="FilterType == 'TicketLog'"> {{ payloadData.idTicket }} </span>
                        <span *ngIf="FilterType == 'userLog'"> {{ payloadData.userName }} </span>
                        <span *ngIf="FilterType == 'SubjectLog'"> {{ payloadData.subjectEdited | translate }} </span>
                        <span *ngIf="FilterType == 'SubjectFieldsLog'"> {{ payloadData.subjectName }} </span>
                        <span *ngIf="FilterType == 'RelevanceLog'"> {{ payloadData.relevanceName }} </span>
                        <span *ngIf="FilterType == 'StatusLog'"> {{ payloadData.statusName }} </span>
                        <span *ngIf="FilterType == 'DepartmentLog'"> {{ payloadData.departmentName }} </span>
                    </td>
                    <td *ngIf="FilterType == 'SubjectFieldsLog'">
                        <span>{{ payloadData.fieldEdited | translate }}</span>
                    </td>
                    <td>
                        <span>{{ payloadData.actionName | translate}}</span>
                    </td>
                    <td>
                        <span>{{ payloadData.lastModification | date:'short' }}</span>
                    </td>
                    <td>
                        <span>{{ payloadData.userInChargeName }}</span>
                    </td>
                    <td>
                        <span>{{ payloadData.details }}</span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td style="padding: 200px; text-align: center;" colspan="12">
                        <span *ngIf="!showHardReloadBtn"> {{ 'GENERICS.NO_DATA_FOUND' | translate }} </span>
                        <span *ngIf="showHardReloadBtn"> {{'GENERICS.UPDATED_ERROR' | translate}} <a class="btn btn-danger" (click)="hardRefresh()"> <i class="feather icon-refresh-cw"></i> {{ 'Refresh' | translate }}</a> </span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-card>
