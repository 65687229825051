import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private showLoadingSubject = new BehaviorSubject<boolean>(true);
  showLoading$ = this.showLoadingSubject.asObservable();
  private _intervalId: any;

  constructor() { }

  toggleLoading() {
    const currentValue = this.showLoadingSubject.getValue();
    this.showLoadingSubject.next(!currentValue);
  }

  getShowLoading(){
    return this.showLoadingSubject;
  }

  startInterval(callback: () => void): void {
    clearInterval(this._intervalId);
    this._intervalId = setInterval(callback, 300000);//300000 //3min
  }

  stopInterval(): void {
    if (this._intervalId) {
      clearInterval(this._intervalId);
      this._intervalId = null;
    }
  }

  isIntervalRunning(): boolean {
    return !!this._intervalId;
  }
}
